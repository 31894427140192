import { useFetchProvider } from "../../providers/provider-hooks.jsx";
import { SLIDE_TYPES } from "../../pages/quizzes/quiz-creation.jsx";
import { toastOneWarn, updateFailureToast } from "../../toast.js";
import useTableParams from "./use-table-params.js";
import { useEffect, useRef, useState } from "react";
import { Slide, toast } from "react-toastify";
import { delay } from "../../utils.js";
import { APPLICATION_REPORTS, useTableExports } from "./use-table-exports.js";

export default function useQuizzesReport() {
    const [isApprovalDialogOpened, setApprovalDialogOpened] = useState(false);
    const [isQuizDialogOpened, setQuizDialogOpened] = useState(false);
    const [actionsDisabled, setActionsDisabled] = useState(false);
    const [elementInDialog, setElementInDialog] = useState(null);
    const [rejectReason, setRejectReason] = useState("");
    const [quizAnswers, setQuizAnswers] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [quizData, setQuizData] = useState({});

    const quizDialogRef = useRef(null);
    const dialogRef = useRef(null);
    const printRef = useRef(null);

    const { params, changeParams, setParams } = useTableParams(renderQuizzesReport, { page: 0 });
    const { exportTable } = useTableExports(APPLICATION_REPORTS.ACTIVITY, params, setActionsDisabled);
    const { getAnswersReport, patchLessonApproval, getLessonById, getAnswersForQuiz } = useFetchProvider();

    function closeQuizDialog() {
        setQuizDialogOpened(false);
        setQuizAnswers([]);
        setQuizData({});

        setApprovalDialogOpened(false);
        setElementInDialog(null);
        setRejectReason("");
    }

    async function openQuizDialog(lessonId, version, userId, rowId) {
        setActionsDisabled(true);
        let errorMessage = "Imaginile acestei activitati practice nu s-au putut incarca.";
        const toastId = toast.loading("Se incarca imaginile acestei activitati practice...", { transition: Slide });

        try {
            await delay(1000);
            const newQuizData = await getLessonById(lessonId);
            const newQuizAnswers = await getAnswersForQuiz(lessonId, version, userId);
            setQuizData(
                newQuizData.slides.filter((slide) => [SLIDE_TYPES.IMAGE_UPLOAD, SLIDE_TYPES.TEXT].includes(slide.type)),
            );
            setQuizAnswers(newQuizAnswers);
            setQuizDialogOpened(true);
            setElementInDialog(rowId);
            toast.dismiss(toastId);
        } catch (error) {
            console.error(error);
            updateFailureToast(error.message || errorMessage, toastId);
        } finally {
            setActionsDisabled(false);
        }
    }

    const changeRejectReason = (e) => setRejectReason(e.target.value);

    function closeRejectDialog() {
        setApprovalDialogOpened(false);
        setElementInDialog(null);
        setRejectReason("");

        setQuizDialogOpened(false);
        setQuizAnswers([]);
        setQuizData({});
    }

    function openRejectDialog(orderId) {
        setApprovalDialogOpened(true);
        setElementInDialog(orderId);
    }

    async function invalidateQuiz() {
        setActionsDisabled(true);
        if (!rejectReason) return toastOneWarn("Motivul respingerii acestei nu a fost mentionat.");
        try {
            await validateQuiz(elementInDialog, false, rejectReason);
            closeRejectDialog();
        } catch (error) {
            console.error(error);
        } finally {
            setActionsDisabled(false);
        }
    }

    async function validateQuiz(quizId, isApproved, rejectReason) {
        setActionsDisabled(true);
        let params = {};
        const errorMessage = "Aceasta completare nu s-a putut valida.";
        const toastId = toast.loading("Operatiunea se proceseaza...", { transition: Slide });

        try {
            await delay(1000);
            if (![undefined, null].includes(isApproved) && typeof isApproved === "boolean")
                params.isApproved = isApproved;
            if (![undefined, null, ""].includes(rejectReason) && typeof rejectReason === "string")
                params.rejectReason = rejectReason;
            await patchLessonApproval(quizId, params);
            closeRejectDialog();
            toast.dismiss(toastId);
        } catch (error) {
            console.error(error);
            updateFailureToast(error.message || errorMessage, toastId);
        } finally {
            await renderQuizzesReport();
            setActionsDisabled(false);
        }
    }

    async function renderQuizzesReport(freshParams) {
        setActionsDisabled(true);
        let errorMessage = "Tabelul nu s-a putut incarca.";
        const id = toast.loading("Tabelul se incarca...", { transition: Slide });

        try {
            await delay(1000);
            const data = await getAnswersReport(freshParams || params);
            setTableData(data.content);
            setPagination({
                totalPages: data.totalPages,
                totalElements: data.totalElements,
                ...data["pageable"],
                first: data.first,
                last: data.last,
            });
            toast.dismiss(id);
        } catch (error) {
            console.error(error);
            updateFailureToast(error.message || errorMessage, id);
        } finally {
            setActionsDisabled(false);
        }
    }

    async function resetFilters() {
        setParams({ page: params.page });
        await renderQuizzesReport({ page: params.page });
    }

    useEffect(() => {
        renderQuizzesReport().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        tableData,
        printRef,
        actionsDisabled,
        params,
        changeParams,
        renderQuizzesReport,
        resetFilters,
        changeRejectReason,
        quizData,
        rejectReason,
        invalidateQuiz,
        isApprovalDialogOpened,
        dialogRef,
        closeRejectDialog,
        validateQuiz,
        openRejectDialog,
        quizAnswers,
        pagination,
        isQuizDialogOpened,
        closeQuizDialog,
        quizDialogRef,
        openQuizDialog,
        elementInDialog,
        exportTable,
    };
}
