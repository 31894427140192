import FiltersResetButton from "../../components/reports/tables/filters-reset-button.jsx";
import OrderFormDialog from "../../components/reports/orders/order-form-dialog.jsx";
import LogisticsDialog from "../../components/reports/orders/logistics-dialog.jsx";
import ApprovalDialog from "../../components/reports/orders/aproval-dialog.jsx";
import RefreshButton from "../../components/reports/tables/refresh-button.jsx";
import ReportDetails from "../../components/reports/tables/report-details.jsx";
import TableHeaders from "../../components/reports/orders/table-headers.jsx";
import SizeSelector from "../../components/reports/tables/size-selector.jsx";
import ExportButton from "../../components/reports/tables/export-button.jsx";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import Pagination from "../../components/reports/tables/pagination.jsx";
import TableBody from "../../components/reports/orders/table-body.jsx";
import useOrdersReport from "../../hooks/reports/use-orders-report.js";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";

export default function Orders() {
    const {
        params,
        editions,
        printRef,
        counties,
        tableData,
        dialogRef,
        pagination,
        exportTable,
        removePhoto,
        changeParams,
        rejectReason,
        resetFilters,
        orderFormData,
        logisticsData,
        validateOrder,
        orderDialogRef,
        currentEdition,
        isDialogOpened,
        logisticsErrors,
        actionsDisabled,
        invalidateOrder,
        saveLogisticsData,
        openRejectDialog,
        closeRejectDialog,
        changeRejectReason,
        handleImageUpload,
        renderOrdersReport,
        logisticsDialogRef,
        changeLogisticsData,
        openLogisticsDialog,
        isOrderDialogOpened,
        openOrderDialogForm,
        saveOrderDialogForm,
        closeOrderDialogForm,
        closeLogisticsDialog,
        orderCorrectionErrors,
        isLogisticsDialogOpened,
        handleOrderAddressFieldChange,
        handleOrderStandardFieldChange,
    } = useOrdersReport();

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap customStyles="pr-0 rounded-none overflow-hidden">
                    <div className="flex w-full flex-col space-y-4 pb-4 lg:flex-row lg:items-end lg:justify-end lg:space-x-4 lg:space-y-0">
                        <ReportDetails pagination={pagination} />
                        <SizeSelector actionsDisabled={actionsDisabled} changeParams={changeParams} params={params} />
                        <FiltersResetButton handleRefresh={resetFilters} disabled={actionsDisabled} />
                        <RefreshButton handleRefresh={renderOrdersReport} disabled={actionsDisabled} />
                        <ExportButton handleExport={exportTable} disabled={actionsDisabled} />
                    </div>
                    <div className="relative flex-1 overflow-auto pr-4">
                        <div className={"absolute left-0 top-0 w-full"}>
                            <table ref={printRef} className="relative h-full w-full">
                                <TableHeaders
                                    params={params}
                                    changeParams={changeParams}
                                    actionsDisabled={actionsDisabled}
                                />
                                <TableBody
                                    editions={editions}
                                    tableData={tableData}
                                    validateOrder={validateOrder}
                                    currentEdition={currentEdition}
                                    actionsDisabled={actionsDisabled}
                                    openRejectDialog={openRejectDialog}
                                    openOrderDialogForm={openOrderDialogForm}
                                    openLogisticsDialog={openLogisticsDialog}
                                />
                            </table>
                        </div>
                    </div>
                    <OrderFormDialog
                        ref={orderDialogRef}
                        counties={counties}
                        orderFormData={orderFormData}
                        actionsDisabled={actionsDisabled}
                        saveOrderDialogForm={saveOrderDialogForm}
                        isOrderDialogOpened={isOrderDialogOpened}
                        closeOrderDialogForm={closeOrderDialogForm}
                        orderCorrectionErrors={orderCorrectionErrors}
                        handleOrderAddressFieldChange={handleOrderAddressFieldChange}
                        handleOrderStandardFieldChange={handleOrderStandardFieldChange}
                    />
                    <Pagination
                        tableData={tableData}
                        pagination={pagination}
                        changeParams={changeParams}
                        actionsDisabled={actionsDisabled}
                    />
                    <ApprovalDialog
                        ref={dialogRef}
                        rejectReason={rejectReason}
                        isDialogOpened={isDialogOpened}
                        invalidateOrder={invalidateOrder}
                        actionsDisabled={actionsDisabled}
                        closeRejectDialog={closeRejectDialog}
                        changeRejectReason={changeRejectReason}
                    />
                    <LogisticsDialog
                        ref={logisticsDialogRef}
                        removePhoto={removePhoto}
                        logisticsData={logisticsData}
                        actionsDisabled={actionsDisabled}
                        logisticsErrors={logisticsErrors}
                        handleImageUpload={handleImageUpload}
                        sendLogisticsData={saveLogisticsData}
                        changeLogisticsData={changeLogisticsData}
                        closeLogisticsDialog={closeLogisticsDialog}
                        isLogisticsDialogOpened={isLogisticsDialogOpened}
                    />
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    );
}
