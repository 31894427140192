import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import { cn } from "../../utils.js";
import { forwardRef } from "react";
import PropTypes from "prop-types";

const buttonVariants = cva(
    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-small font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50",
    {
        variants: {
            variant: {
                default: "bg-dark text-light shadow hover:bg-magenta/80",
                destructive: "bg-red-500 text-red-50 shadow-sm hover:text-red-100 hover:bg-red-600/80",
                outline: "border border-lime-700 bg-light shadow-sm hover:text-light hover:bg-magenta/80",
                secondary: "bg-gray-300 text-dark shadow-sm hover:bg-magenta/80 hover:text-light",
                ghost: "hover:bg-magenta/80 hover:text-light",
                link: "underline-offset-4 hover:underline",
            },
            size: {
                default: "h-9 px-4 py-2",
                sm: "h-8 rounded-md px-3 text-small",
                lg: "h-10 rounded-md px-8",
                icon: "h-9 w-9",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    },
);

const Button = forwardRef(({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
});
Button.displayName = "Button";
Button.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
    asChild: PropTypes.bool,
};

// eslint-disable-next-line react-refresh/only-export-components
export { Button, buttonVariants };
