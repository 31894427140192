import PropTypes from "prop-types";
import { cn } from "../../utils.js";

export default function ErrorSpan({ customStyles = "", text }) {
    return (
        <span
            className={cn(
                `animate-fade cursor-default justify-center px-2 pb-2 font-semibold text-red-500`,
                customStyles,
            )}>
            {text}
        </span>
    );
}
ErrorSpan.propTypes = {
    customStyles: PropTypes.string,
    text: PropTypes.string.isRequired,
};
