import { checkAnyErrorForPath, cn, retrieveErrorForPath } from "../../../utils.js";
import FieldWrap from "../wraps/field-wrap.jsx";
import InputWrap from "../wraps/input-wrap.jsx";
import NumberInput from "../raw-inputs/number-input.jsx";
import ErrorSpan from "../../layout/error-span.jsx";
import PropTypes from "prop-types";

export default function NumberInputBox({
    value,
    name,
    errors,
    handleChange,
    customErrorStyles,
    label,
    disabled,
    customInputStyles,
    customInputWrapStyles,
    customFieldWrapStyles,
}) {
    return (
        <FieldWrap customStyles={customFieldWrapStyles}>
            <InputWrap
                customStyles={cn(checkAnyErrorForPath(name, errors) ? "border-red-600" : "", customInputWrapStyles)}>
                <NumberInput
                    name={name}
                    label={label}
                    value={value}
                    handleChange={handleChange}
                    disabled={disabled}
                    customStyles={cn(value ? "font-black" : "sr-only", customInputStyles)}
                />
                <ErrorSpan
                    text={retrieveErrorForPath(name, errors)}
                    customStyles={cn(checkAnyErrorForPath(name, errors) ? "flex" : "hidden", customErrorStyles)}
                />
            </InputWrap>
        </FieldWrap>
    );
}
NumberInputBox.propTypes = {
    errors: PropTypes.object,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    customErrorStyles: PropTypes.string,
    customInputStyles: PropTypes.string,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    customInputWrapStyles: PropTypes.string,
    customFieldWrapStyles: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
