import { Dialog, DialogContent, DialogDescription, DialogTitle } from "../schadcn-ui/dialog.jsx";
import TextInputBox from "../forms/final-input-boxes/text-input-box.jsx";
import { Button } from "../schadcn-ui/button.jsx";
import PropTypes from "prop-types";

export default function PasswordChange({
    setIsModalOpen,
    isModalOpen,
    formData,
    handleFieldChange,
    updatePassword,
    errors,
}) {
    return (
        <Dialog onOpenChange={setIsModalOpen} open={isModalOpen}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogTitle className={"p-2 font-normal"}>Formular de schimbare a parolei</DialogTitle>
                <DialogDescription className={"sr-only"} />
                <div className={"-mt-4 flex flex-col"}>
                    <TextInputBox
                        name={"currentPassword"}
                        label={"Parola Curenta"}
                        value={formData["currentPassword"] || ""}
                        errors={errors}
                        handleChange={handleFieldChange}
                    />
                    <TextInputBox
                        name={"newPassword"}
                        label={"Parola Noua"}
                        value={formData["newPassword"] || ""}
                        errors={errors}
                        handleChange={handleFieldChange}
                    />
                    <div
                        className={
                            "flex w-full flex-col space-y-4 p-2 sm:flex-row sm:justify-end sm:space-x-4 sm:space-y-0"
                        }>
                        <Button size={"lg"} onClick={updatePassword}>
                            Salvare modificari
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
PasswordChange.propTypes = {
    handleFieldChange: PropTypes.func.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    updatePassword: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    formData: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};
