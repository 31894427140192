import { useState, useEffect } from "react";

export default function useIsDesktopViewPort() {
    const [isDesktopViewPort, setIsDesktopViewPort] = useState(window.innerWidth >= 780);

    useEffect(() => {
        const handleResize = () => setIsDesktopViewPort(window.innerWidth >= 780);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return { isDesktopViewPort };
}
