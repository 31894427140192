import PracticalActivityListing from "../pages/quizzes/practical-activity-listing.jsx";
import MonthlyChallengesListing from "../pages/quizzes/monthly-challenges-listing.jsx";
import NotificationsReceived from "../pages/communication/notifications-received.jsx";
import DailyChallengesListing from "../pages/quizzes/daily-challanges-listing.jsx";
import AccountAuthorization from "../pages/reports/account-authorization.jsx";
import NotificationsSent from "../pages/communication/notifications-sent.jsx";
import QuizCreation, { QUIZ_TYPES } from "../pages/quizzes/quiz-creation.jsx";
import SendNotification from "../pages/communication/send-notification.jsx";
import ForgottenPassword from "../pages/auth/forgotten-password.jsx";
import ParentalApproval from "../pages/forms/parental-approval.jsx";
import HomeworkListing from "../pages/quizzes/homework-listing.jsx";
import SchoolDetailsV2 from "../pages/forms/school-details-v2.jsx";
import LessonsListing from "../pages/quizzes/lessons-listing.jsx";
import QuizCompletion from "../pages/quizzes/quiz-completion.jsx";
import WasteCollection from "../pages/forms/waste-collection.jsx";
import PupilAccounts from "../pages/reports/pupil-accounts.jsx";
import AccountWaiting from "../pages/auth/account-waiting.jsx";
import Notifications from "../pages/reports/notifications.jsx";
import BigSchools from "../pages/reports/big-schools.jsx";
import Quizzes from "../pages/reports/quizzes.jsx";
import Schools from "../pages/reports/schools.jsx";
import Register from "../pages/auth/register.jsx";
import Orders from "../pages/reports/orders.jsx";
import Profile from "../pages/forms/profile.jsx";
import NewPass from "../pages/auth/new-pass.jsx";
import Final from "../pages/reports/final.jsx";
import LogIn from "../pages/auth/log-in.jsx";
import Home from "../pages/home.jsx";
import { createContext } from "react";
import PropTypes from "prop-types";

const RoutesProviderContext = createContext(null);
const { Provider } = RoutesProviderContext;

const RoutesProvider = ({ children }) => {
    const ROLES = Object.freeze({
        DBEST: "DBEST",
        ADMIN: "ADMIN",
        PROFESOR: "PROFESOR",
        ELEV: "ELEV",
        LOGISTIC: "LOGISTIC",
    });

    const ROLES_PRESENTATION_NAME = Object.freeze({
        [ROLES.DBEST]: "Programator",
        [ROLES.ADMIN]: "Administrator Ecotic",
        [ROLES.LOGISTIC]: "Staff Ecotic",
        [ROLES.PROFESOR]: "Profesor",
        [ROLES.ELEV]: "Elev",
    });

    const PAGES = Object.freeze({
        BIG_SCHOOLS_DATA_TABLE: "BIG_SCHOOLS_DATA_TABLE",
        HOME: "HOME",
        TEACHER_AUTH: "TEACHER_AUTH",
        PUPIL_AUTH: "PUPIL_AUTH",
        PUPIL_REGISTER: "PUPIL_REGISTER",
        FORGOTTEN_PASSWORD: "FORGOTTEN_PASSWORD",
        NEW_PASS_FOR_FORGOTTEN_PASSWORD: "NEW_PASS_FOR_FORGOTTEN_PASSWORD",

        LESSON_QUIZ_LISTING: "LESSON_QUIZ_LISTING",
        LESSON_QUIZ_CREATION: "LESSON_QUIZ_CREATION",
        LESSON_QUIZ_CREATION_REDIRECT: "LESSON_QUIZ_CREATION_REDIRECT",

        HOMEWORK_LISTING: "HOMEWORK_LISTING",
        HOMEWORK_CREATION: "HOMEWORK_CREATION",
        HOMEWORK_CREATION_REDIRECT: "HOMEWORK_CREATION_REDIRECT",

        PRACTICAL_ACTIVITY_LISTING: "PRACTICAL_ACTIVITY",
        PRACTICAL_ACTIVITY_CREATION: "PRACTICAL_ACTIVITY_CREATION",
        PRACTICAL_ACTIVITY_CREATION_REDIRECT: "PRACTICAL_ACTIVITY_CREATION_REDIRECT",

        MONTHLY_CHALLENGES_LISTING: "MONTHLY_CHALLENGES_LISTING",
        MONTHLY_CHALLENGES_CREATION: "MONTHLY_CHALLENGES_CREATION",
        MONTHLY_CHALLENGES_CREATION_REDIRECT: "MONTHLY_CHALLENGES_CREATION_REDIRECT",

        DAILY_CHALLENGES_LISTING: "DAILY_CHALLENGES_LISTING",
        DAILY_CHALLENGES_CREATION: "DAILY_CHALLENGES_CREATION",
        DAILY_CHALLENGES_CREATION_REDIRECT: "DAILY_CHALLENGES_CREATION_REDIRECT",

        WASTE_COLLECTION_FORM: "WASTE_COLLECTION_FORM",
        WASTE_COLLECTION_TABLE: "WASTE_COLLECTION_TABLE",
        OUTBOX_V2: "OUTBOX_V2",
        INBOX: "INBOX",
        SENT_NOTIFICATIONS: "SENT_NOTIFICATIONS",
        PROFILE: "PROFILE",
        ACCOUNTS_AUTHORIZATION: "ACCOUNTS_AUTHORIZATION",
        ACCOUNT_UNAUTHORIZED_PUPIL: "ACCOUNT_UNAUTHORIZED_PUPIL",
        ACCOUNT_UNAUTHORIZED_TEACHER: "ACCOUNT_UNAUTHORIZED_TEACHER",
        LESSON_COMPLETION: "LESSON_COMPLETION",
        LESSON_COMPLETION_PREVIEW: "LESSON_COMPLETION_PREVIEW",

        HOMEWORK_COMPLETION: "HOMEWORK_COMPLETION",
        HOMEWORK_COMPLETION_PREVIEW: "HOMEWORK_COMPLETION_PREVIEW",

        PRACTICAL_ACTIVITY_COMPLETION: "PRACTICAL_ACTIVITY_COMPLETION",
        PRACTICAL_ACTIVITY_COMPLETION_PREVIEW: "PRACTICAL_ACTIVITY_COMPLETION_PREVIEW",

        MONTHLY_CHALLENGES_COMPLETION: "MONTHLY_CHALLENGES_COMPLETION",
        MONTHLY_CHALLENGES_COMPLETION_PREVIEW: "MONTHLY_CHALLENGES_COMPLETION_PREVIEW",

        DAILY_CHALLENGES_COMPLETION: "DAILY_CHALLENGES_COMPLETION",
        DAILY_CHALLENGES_COMPLETION_PREVIEW: "DAILY_CHALLENGES_COMPLETION_PREVIEW",

        SCHOOLS_REPORT: "SCHOOLS_REPORT",
        COMPLETED_QUIZZES: "COMPLETED_QUIZZES",
        NOTIFICATIONS_REPORT: "NOTIFICATIONS_REPORT",
        SCHOOL_FORM_V2: "SCHOOL_FORM_V2",
        // SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V2: "SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V2",
        // SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3: "SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3",
        PARENTAL_APPROVAL: "PARENTAL_APPROVAL",
        FINAL_REPORT: "FINAL_REPORT",
        PUPILS_REPORT: "PUPILS_REPORT",
    });

    const PATHS = Object.freeze({
        [PAGES.BIG_SCHOOLS_DATA_TABLE]: "/raport-institutii-scolare-2024-2025",
        [PAGES.HOME]: "/informatii-despre-scoala-ecoterrienilor",
        [PAGES.TEACHER_AUTH]: "/profesor/autentificare",
        [PAGES.PUPIL_AUTH]: "/elev/autentificare",
        [PAGES.PUPIL_REGISTER]: "/elev/inregistrare",
        [PAGES.FORGOTTEN_PASSWORD]: "/am-uitat-parola",
        [PAGES.NEW_PASS_FOR_FORGOTTEN_PASSWORD]: "/confirmare-resetare/:token?",
        [PAGES.LESSON_QUIZ_LISTING]: "/lectii",
        [PAGES.LESSON_QUIZ_CREATION]: "/lectii/editare/:id?",
        [PAGES.LESSON_QUIZ_CREATION_REDIRECT]: "/lectii/editare",

        [PAGES.HOMEWORK_LISTING]: "/teme-de-casa",
        [PAGES.HOMEWORK_CREATION]: "/teme-de-casa/editare/:id?",
        [PAGES.HOMEWORK_CREATION_REDIRECT]: "/teme-de-casa/editare",

        [PAGES.PRACTICAL_ACTIVITY_LISTING]: "/activitati-practice",
        [PAGES.PRACTICAL_ACTIVITY_CREATION]: "/activitati-practice/editare/:id?",
        [PAGES.PRACTICAL_ACTIVITY_CREATION_REDIRECT]: "/activitati-practice/editare",

        [PAGES.MONTHLY_CHALLENGES_LISTING]: "/provocari-lunare",
        [PAGES.MONTHLY_CHALLENGES_CREATION]: "/provocari-lunare/editare/:id?",
        [PAGES.MONTHLY_CHALLENGES_CREATION_REDIRECT]: "/provocari-lunare/editare",

        [PAGES.DAILY_CHALLENGES_LISTING]: "/provocari-zilnice",
        [PAGES.DAILY_CHALLENGES_CREATION]: "/provocari-zilnice/editare/:id?",
        [PAGES.DAILY_CHALLENGES_CREATION_REDIRECT]: "/provocari-zilnice/editare",

        [PAGES.WASTE_COLLECTION_FORM]: "/plasare-comanda",
        [PAGES.WASTE_COLLECTION_TABLE]: "/rapoarte/comenzi",
        [PAGES.OUTBOX_V2]: "/notificari/creare",
        [PAGES.INBOX]: "/notificari/primite",
        [PAGES.SENT_NOTIFICATIONS]: "/notificari/trimise",
        [PAGES.PROFILE]: "/profil",
        [PAGES.ACCOUNTS_AUTHORIZATION]: "/rapoarte/profesori/autorizare-conturi",
        [PAGES.ACCOUNT_UNAUTHORIZED_PUPIL]: "/cont-in-asteptare/elev",
        [PAGES.ACCOUNT_UNAUTHORIZED_TEACHER]: "/cont-in-asteptare/profesor",

        [PAGES.LESSON_COMPLETION]: "/completare-lectie/id/:id",
        [PAGES.LESSON_COMPLETION_PREVIEW]: "/completare-lectie/id/:id/preview",

        [PAGES.HOMEWORK_COMPLETION]: "/completare-tema-pentru-acasa/id/:id",
        [PAGES.HOMEWORK_COMPLETION_PREVIEW]: "/completare-tema-pentru-acasa/id/:id/preview",

        [PAGES.PRACTICAL_ACTIVITY_COMPLETION]: "/completare-activitate-practica/id/:id",
        [PAGES.PRACTICAL_ACTIVITY_COMPLETION_PREVIEW]: "/completare-activitate-practica/id/:id/preview",

        [PAGES.MONTHLY_CHALLENGES_COMPLETION]: "/completare-provocare-lunara/id/:id",
        [PAGES.MONTHLY_CHALLENGES_COMPLETION_PREVIEW]: "/completare-provocare-lunara/id/:id/preview",

        [PAGES.DAILY_CHALLENGES_COMPLETION]: "/completare-provocare-zilnica/id/:id",
        [PAGES.DAILY_CHALLENGES_COMPLETION_PREVIEW]: "/completare-provocare-zilnica/id/:id/preview",

        [PAGES.SCHOOLS_REPORT]: "/rapoarte/scoli",
        [PAGES.COMPLETED_QUIZZES]: "/raportare/raspunsuri-quizuri",
        [PAGES.NOTIFICATIONS_REPORT]: "/raportare/notificari",
        [PAGES.SCHOOL_FORM_V2]: "/detalii-scoala-v2",
        // [PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V2]: "/detalii-scoala-cont-in-asteptare",
        // [PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3]: "/corectare-detalii-scoala-cont-in-asteptare",
        [PAGES.PARENTAL_APPROVAL]: "/acord-parental",
        [PAGES.FINAL_REPORT]: "/rapoarte/final-editie",
        [PAGES.PUPILS_REPORT]: "/rapoarte/elevi-inscrisi",
    });

    const NAV_LINKS_V2 = Object.freeze({
        [PAGES.BIG_SCHOOLS_DATA_TABLE]: {
            ALLOWED_ROLES: [ROLES.PROFESOR],
            PATH: PATHS[PAGES.BIG_SCHOOLS_DATA_TABLE],
            COMPONENT: <BigSchools />,
            TEXT: "Scoli conform Minister Educatie",
        },
        [PAGES.HOME]: {
            ALLOWED_ROLES: [],
            PATH: PATHS[PAGES.HOME],
            COMPONENT: <Home />,
            TEXT: "Despre Scoala Ecoterrienilor",
        },
        [PAGES.TEACHER_AUTH]: {
            ALLOWED_ROLES: [],
            PATH: PATHS[PAGES.TEACHER_AUTH],
            COMPONENT: <LogIn forRole={ROLES.PROFESOR} />,
            TEXT: "Autentificare",
        },
        [PAGES.PUPIL_AUTH]: {
            ALLOWED_ROLES: [],
            PATH: PATHS[PAGES.PUPIL_AUTH],
            COMPONENT: <LogIn forRole={ROLES.ELEV} />,
            TEXT: "Autentificare",
        },
        [PAGES.PUPIL_REGISTER]: {
            ALLOWED_ROLES: [],
            PATH: PATHS[PAGES.PUPIL_REGISTER],
            COMPONENT: <Register forRole={ROLES.ELEV} />,
            TEXT: "Inregistrare",
        },
        [PAGES.FORGOTTEN_PASSWORD]: {
            ALLOWED_ROLES: [],
            PATH: PATHS[PAGES.FORGOTTEN_PASSWORD],
            COMPONENT: <ForgottenPassword />,
            TEXT: "Am uitat parola",
        },
        [PAGES.NEW_PASS_FOR_FORGOTTEN_PASSWORD]: {
            ALLOWED_ROLES: [],
            PATH: PATHS[PAGES.NEW_PASS_FOR_FORGOTTEN_PASSWORD],
            COMPONENT: <NewPass />,
            TEXT: "Parola Noua",
        },
        [PAGES.LESSON_QUIZ_LISTING]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN, ROLES.PROFESOR],
            PATH: PATHS[PAGES.LESSON_QUIZ_LISTING],
            COMPONENT: <LessonsListing quizType={QUIZ_TYPES.LESSON} />,
            TEXT: "Lectii",
        },
        [PAGES.LESSON_QUIZ_CREATION]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN],
            PATH: PATHS[PAGES.LESSON_QUIZ_CREATION],
            COMPONENT: <QuizCreation quizType={QUIZ_TYPES.LESSON} />,
            TEXT: "Adaugare lectie",
        },
        [PAGES.LESSON_QUIZ_CREATION_REDIRECT]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN],
            PATH: PATHS[PAGES.LESSON_QUIZ_CREATION_REDIRECT],
            COMPONENT: <QuizCreation quizType={QUIZ_TYPES.LESSON} />,
            TEXT: "Adaugare lectie",
        },
        [PAGES.HOMEWORK_LISTING]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN, ROLES.ELEV],
            PATH: PATHS[PAGES.HOMEWORK_LISTING],
            COMPONENT: <HomeworkListing quizType={QUIZ_TYPES.HOMEWORK} />,
            TEXT: "Teme pentru acasa",
        },
        [PAGES.HOMEWORK_CREATION]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN],
            PATH: PATHS[PAGES.HOMEWORK_CREATION],
            COMPONENT: <QuizCreation quizType={QUIZ_TYPES.HOMEWORK} />,
            TEXT: "Adaugare Tema de Casa",
        },
        [PAGES.HOMEWORK_CREATION_REDIRECT]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN],
            PATH: PATHS[PAGES.HOMEWORK_CREATION_REDIRECT],
            COMPONENT: <QuizCreation quizType={QUIZ_TYPES.HOMEWORK} />,
            TEXT: "Adaugare Tema de Casa",
        },
        [PAGES.PRACTICAL_ACTIVITY_LISTING]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN, ROLES.PROFESOR, ROLES.ELEV],
            PATH: PATHS[PAGES.PRACTICAL_ACTIVITY_LISTING],
            COMPONENT: <PracticalActivityListing quizType={QUIZ_TYPES.PRACTICAL_ACTIVITY} />,
            TEXT: "Activitati Practice",
        },
        [PAGES.PRACTICAL_ACTIVITY_CREATION]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN],
            PATH: PATHS[PAGES.PRACTICAL_ACTIVITY_CREATION],
            COMPONENT: <QuizCreation quizType={QUIZ_TYPES.PRACTICAL_ACTIVITY} />,
            TEXT: "Adaugare Tema de Casa",
        },
        [PAGES.PRACTICAL_ACTIVITY_CREATION_REDIRECT]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN],
            PATH: PATHS[PAGES.PRACTICAL_ACTIVITY_CREATION_REDIRECT],
            COMPONENT: <QuizCreation quizType={QUIZ_TYPES.PRACTICAL_ACTIVITY} />,
            TEXT: "Adaugare Tema de Casa",
        },
        [PAGES.MONTHLY_CHALLENGES_LISTING]: {
            ALLOWED_ROLES: [ROLES.ADMIN, ROLES.ELEV],
            PATH: PATHS[PAGES.MONTHLY_CHALLENGES_LISTING],
            COMPONENT: <MonthlyChallengesListing quizType={QUIZ_TYPES.MONTHLY_CHALLENGE} />,
            TEXT: "Provocari Lunare",
        },
        [PAGES.MONTHLY_CHALLENGES_CREATION]: {
            ALLOWED_ROLES: [ROLES.ADMIN, ROLES.ELEV],
            PATH: PATHS[PAGES.MONTHLY_CHALLENGES_CREATION],
            COMPONENT: <QuizCreation quizType={QUIZ_TYPES.MONTHLY_CHALLENGE} />,
            TEXT: "Adaugare Provocare Lunara",
        },
        [PAGES.MONTHLY_CHALLENGES_CREATION_REDIRECT]: {
            ALLOWED_ROLES: [ROLES.ADMIN, ROLES.ELEV],
            PATH: PATHS[PAGES.MONTHLY_CHALLENGES_CREATION_REDIRECT],
            COMPONENT: <QuizCreation quizType={QUIZ_TYPES.MONTHLY_CHALLENGE} />,
            TEXT: "Adaugare Provocare Lunara",
        },

        [PAGES.DAILY_CHALLENGES_LISTING]: {
            ALLOWED_ROLES: [ROLES.ADMIN, ROLES.ELEV],
            PATH: PATHS[PAGES.DAILY_CHALLENGES_LISTING],
            COMPONENT: <DailyChallengesListing quizType={QUIZ_TYPES.DAILY_CHALLENGE} />,
            TEXT: "Provocari Zilnice",
        },
        [PAGES.DAILY_CHALLENGES_CREATION]: {
            ALLOWED_ROLES: [ROLES.ADMIN, ROLES.ELEV],
            PATH: PATHS[PAGES.DAILY_CHALLENGES_CREATION],
            COMPONENT: <QuizCreation quizType={QUIZ_TYPES.DAILY_CHALLENGE} />,
            TEXT: "Adaugare Provocare Zilnica",
        },
        [PAGES.DAILY_CHALLENGES_CREATION_REDIRECT]: {
            ALLOWED_ROLES: [ROLES.ADMIN, ROLES.ELEV],
            PATH: PATHS[PAGES.DAILY_CHALLENGES_CREATION_REDIRECT],
            COMPONENT: <QuizCreation quizType={QUIZ_TYPES.DAILY_CHALLENGE} />,
            TEXT: "Adaugare Provocare Zilnica",
        },

        [PAGES.WASTE_COLLECTION_FORM]: {
            ALLOWED_ROLES: [ROLES.PROFESOR],
            PATH: PATHS[PAGES.WASTE_COLLECTION_FORM],
            COMPONENT: <WasteCollection />,
            TEXT: "Plasare Comanda",
        },
        [PAGES.WASTE_COLLECTION_TABLE]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN, ROLES.PROFESOR, ROLES.LOGISTIC],
            PATH: PATHS[PAGES.WASTE_COLLECTION_TABLE],
            COMPONENT: <Orders />,
            TEXT: "Istoric Comenzi",
        },
        [PAGES.OUTBOX_V2]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN],
            PATH: PATHS[PAGES.OUTBOX_V2],
            COMPONENT: <SendNotification />,
            TEXT: "Trimite Mesaj",
        },
        [PAGES.INBOX]: {
            ALLOWED_ROLES: [ROLES.ADMIN, ROLES.PROFESOR, ROLES.ELEV],
            PATH: PATHS[PAGES.INBOX],
            COMPONENT: <NotificationsReceived />,
            TEXT: "Mesaje Primite",
        },
        [PAGES.SENT_NOTIFICATIONS]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN],
            PATH: PATHS[PAGES.SENT_NOTIFICATIONS],
            COMPONENT: <NotificationsSent />,
            TEXT: "Mesaje Trimise",
        },
        [PAGES.PROFILE]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN, ROLES.PROFESOR, ROLES.ELEV, ROLES.LOGISTIC],
            PATH: PATHS[PAGES.PROFILE],
            COMPONENT: <Profile />,
            TEXT: "Profil",
        },
        [PAGES.ACCOUNTS_AUTHORIZATION]: {
            ALLOWED_ROLES: [ROLES.ADMIN],
            PATH: PATHS[PAGES.ACCOUNTS_AUTHORIZATION],
            COMPONENT: <AccountAuthorization />,
            TEXT: "Raport Conturi Prof.",
        },
        [PAGES.PUPILS_REPORT]: {
            ALLOWED_ROLES: [ROLES.PROFESOR],
            PATH: PATHS[PAGES.PUPILS_REPORT],
            COMPONENT: <PupilAccounts />,
            TEXT: "Raport Conturi Elevi",
        },
        [PAGES.ACCOUNT_UNAUTHORIZED_PUPIL]: {
            ALLOWED_ROLES: [ROLES.ELEV],
            PATH: PATHS[PAGES.ACCOUNT_UNAUTHORIZED_PUPIL],
            COMPONENT: <AccountWaiting role={ROLES.ELEV} />,
            TEXT: "Cont in asteptare",
        },
        [PAGES.ACCOUNT_UNAUTHORIZED_TEACHER]: {
            ALLOWED_ROLES: [ROLES.PROFESOR],
            PATH: PATHS[PAGES.ACCOUNT_UNAUTHORIZED_TEACHER],
            COMPONENT: <AccountWaiting role={ROLES.PROFESOR} />,
            TEXT: "Cont in asteptare",
        },
        [PAGES.PARENTAL_APPROVAL]: {
            ALLOWED_ROLES: [ROLES.ELEV],
            PATH: PATHS[PAGES.PARENTAL_APPROVAL],
            COMPONENT: <ParentalApproval />,
            TEXT: "Acord Parental",
        },
        [PAGES.LESSON_COMPLETION]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN, ROLES.PROFESOR],
            PATH: PATHS[PAGES.LESSON_COMPLETION],
            COMPONENT: <QuizCompletion quizType={QUIZ_TYPES.LESSON} />,
            TEXT: "Completare lectie",
        },
        [PAGES.LESSON_COMPLETION_PREVIEW]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN, ROLES.PROFESOR],
            PATH: PATHS[PAGES.LESSON_COMPLETION_PREVIEW],
            COMPONENT: <QuizCompletion quizType={QUIZ_TYPES.LESSON} />,
            TEXT: "Completare lectie",
        },
        [PAGES.HOMEWORK_COMPLETION]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN, ROLES.PROFESOR, ROLES.ELEV],
            PATH: PATHS[PAGES.HOMEWORK_COMPLETION],
            COMPONENT: <QuizCompletion quizType={QUIZ_TYPES.HOMEWORK} />,
            TEXT: "Completare tema pentru acasa",
        },
        [PAGES.HOMEWORK_COMPLETION_PREVIEW]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN, ROLES.PROFESOR, ROLES.ELEV],
            PATH: PATHS[PAGES.HOMEWORK_COMPLETION_PREVIEW],
            COMPONENT: <QuizCompletion quizType={QUIZ_TYPES.HOMEWORK} />,
            TEXT: "Completare tema pentru acasa",
        },
        [PAGES.PRACTICAL_ACTIVITY_COMPLETION]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN, ROLES.PROFESOR],
            PATH: PATHS[PAGES.PRACTICAL_ACTIVITY_COMPLETION],
            COMPONENT: <QuizCompletion quizType={QUIZ_TYPES.PRACTICAL_ACTIVITY} />,
            TEXT: "Completare activitate practica",
        },
        [PAGES.PRACTICAL_ACTIVITY_COMPLETION_PREVIEW]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN, ROLES.PROFESOR, ROLES.ELEV],
            PATH: PATHS[PAGES.PRACTICAL_ACTIVITY_COMPLETION_PREVIEW],
            COMPONENT: <QuizCompletion quizType={QUIZ_TYPES.PRACTICAL_ACTIVITY} />,
            TEXT: "Completare activitate practica",
        },
        [PAGES.MONTHLY_CHALLENGES_COMPLETION]: {
            ALLOWED_ROLES: [ROLES.ADMIN, ROLES.ELEV],
            PATH: PATHS[PAGES.MONTHLY_CHALLENGES_COMPLETION],
            COMPONENT: <QuizCompletion quizType={QUIZ_TYPES.MONTHLY_CHALLENGE} />,
            TEXT: "Completare Provocare Lunara",
        },
        [PAGES.MONTHLY_CHALLENGES_COMPLETION_PREVIEW]: {
            ALLOWED_ROLES: [ROLES.ADMIN, ROLES.ELEV],
            PATH: PATHS[PAGES.MONTHLY_CHALLENGES_COMPLETION_PREVIEW],
            COMPONENT: <QuizCompletion quizType={QUIZ_TYPES.MONTHLY_CHALLENGE} />,
            TEXT: "Completare Provocare Lunara",
        },

        [PAGES.DAILY_CHALLENGES_COMPLETION]: {
            ALLOWED_ROLES: [ROLES.ADMIN, ROLES.ELEV],
            PATH: PATHS[PAGES.DAILY_CHALLENGES_COMPLETION],
            COMPONENT: <QuizCompletion quizType={QUIZ_TYPES.DAILY_CHALLENGE} />,
            TEXT: "Completare Provocare Zilnica",
        },
        [PAGES.DAILY_CHALLENGES_COMPLETION_PREVIEW]: {
            ALLOWED_ROLES: [ROLES.ADMIN, ROLES.ELEV],
            PATH: PATHS[PAGES.DAILY_CHALLENGES_COMPLETION_PREVIEW],
            COMPONENT: <QuizCompletion quizType={QUIZ_TYPES.DAILY_CHALLENGE} />,
            TEXT: "Completare Provocare Zilnica",
        },
        [PAGES.SCHOOLS_REPORT]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN],
            PATH: PATHS[PAGES.SCHOOLS_REPORT],
            COMPONENT: <Schools quizType={QUIZ_TYPES.PRACTICAL_ACTIVITY} />,
            TEXT: "Raport Scoli",
        },
        [PAGES.COMPLETED_QUIZZES]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN, ROLES.PROFESOR],
            PATH: PATHS[PAGES.COMPLETED_QUIZZES],
            COMPONENT: <Quizzes authorized={false} />,
            TEXT: "Istoric Activitate",
        },
        [PAGES.NOTIFICATIONS_REPORT]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN],
            PATH: PATHS[PAGES.NOTIFICATIONS_REPORT],
            COMPONENT: <Notifications />,
            TEXT: "Raport Notificari",
        },
        [PAGES.SCHOOL_FORM_V2]: {
            ALLOWED_ROLES: [ROLES.PROFESOR],
            PATH: PATHS[PAGES.SCHOOL_FORM_V2],
            COMPONENT: <SchoolDetailsV2 authorized={true} />,
            TEXT: "Detalii scoala",
        },
        // [PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V2]: {
        //     ALLOWED_ROLES: [ROLES.PROFESOR],
        //     PATH: PATHS[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V2],
        //     COMPONENT: <SchoolDetailsV2 authorized={false} />,
        //     TEXT: "Detalii scoala",
        // },
        // [PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3]: {
        //     ALLOWED_ROLES: [ROLES.PROFESOR],
        //     PATH: PATHS[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3],
        //     COMPONENT: <SchoolDetailsV2 authorized={false} />,
        //     TEXT: "Detalii scoala",
        // },
        [PAGES.FINAL_REPORT]: {
            ALLOWED_ROLES: [ROLES.DBEST, ROLES.ADMIN],
            PATH: PATHS[PAGES.FINAL_REPORT],
            COMPONENT: <Final />,
            TEXT: "Raport Editie",
        },
    });

    const PROFILE_LINKS = [
        NAV_LINKS_V2[PAGES.LESSON_QUIZ_LISTING],
        NAV_LINKS_V2[PAGES.HOMEWORK_LISTING],
        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_LISTING],
        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_LISTING],
        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_LISTING],
        NAV_LINKS_V2[PAGES.WASTE_COLLECTION_FORM],
        NAV_LINKS_V2[PAGES.FINAL_REPORT],
        NAV_LINKS_V2[PAGES.WASTE_COLLECTION_TABLE],
        NAV_LINKS_V2[PAGES.COMPLETED_QUIZZES],
        NAV_LINKS_V2[PAGES.SCHOOLS_REPORT],
        NAV_LINKS_V2[PAGES.ACCOUNTS_AUTHORIZATION],
        NAV_LINKS_V2[PAGES.PUPILS_REPORT],
        NAV_LINKS_V2[PAGES.NOTIFICATIONS_REPORT],
        NAV_LINKS_V2[PAGES.OUTBOX_V2],
        NAV_LINKS_V2[PAGES.SENT_NOTIFICATIONS],
        NAV_LINKS_V2[PAGES.INBOX],
    ];

    function getUserHomePage(role) {
        if (!role) return PATHS[PAGES.HOME];
        if (role === ROLES.ELEV) return PATHS[PAGES.HOMEWORK_LISTING];
        if (role === ROLES.PROFESOR) return PATHS[PAGES.PRACTICAL_ACTIVITY_LISTING];
        if (role === ROLES.ADMIN) return PATHS[PAGES.FINAL_REPORT];
        if (role === ROLES.DBEST) return PATHS[PAGES.FINAL_REPORT];
        if (role === ROLES.LOGISTIC) return PATHS[PAGES.WASTE_COLLECTION_TABLE];
        return PATHS[PAGES.HOME];
    }

    function getMainPresentationRole(role) {
        if (role in ROLES_PRESENTATION_NAME) return ROLES_PRESENTATION_NAME[role];
        console.log("If this ever shows up it's the end of everything!!");
        return "Intrus";
    }

    return (
        <Provider
            value={{
                ROLES,
                ROLES_PRESENTATION_NAME,
                PAGES,
                PATHS,
                NAV_LINKS_V2,
                PROFILE_LINKS,
                getUserHomePage,
                getMainPresentationRole,
            }}>
            {children}
        </Provider>
    );
};

RoutesProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { RoutesProviderContext, RoutesProvider };
