import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa6";
import { cn } from "../../../utils.js";
import PropTypes from "prop-types";

export default function SortButton({ name, value, handleChange, disabled }) {
    return (
        <button
            className={
                "relative flex w-[16px] items-center justify-center self-center pr-1 pt-1 focus:outline-none disabled:cursor-not-allowed"
            }
            onClick={() =>
                handleChange(
                    {
                        target: {
                            name: "sort",
                            value: !value.includes(name)
                                ? `${name},asc`
                                : value.includes(name) && value.includes("asc")
                                  ? `${name},desc`
                                  : `${name},asc`,
                        },
                    },
                    "sort",
                    true,
                )
            }
            disabled={disabled}>
            <FaSort
                className={cn(
                    !value.includes(name) ? "opacity-100" : "opacity-0",
                    "absolute transition-opacity duration-500",
                )}
            />
            <FaSortUp
                className={cn(
                    value.includes(name) && value.includes("asc") ? "opacity-100" : "opacity-0",
                    "absolute transition-opacity duration-500",
                )}
            />
            <FaSortDown
                className={cn(
                    value.includes(name) && value.includes("desc") ? "opacity-100" : "opacity-0",
                    "absolute transition-opacity duration-500",
                )}
            />
        </button>
    );
}
SortButton.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
};
