import { checkAnyErrorForPath, cn, formatUnixTimestamp, retrieveErrorForPath } from "../../../utils.js";
import BooleanInputBox from "../../forms/final-input-boxes/boolean-input-box.jsx";
import SelectInputBox from "../../forms/final-input-boxes/select-input-box.jsx";
import NumberInputBox from "../../forms/final-input-boxes/number-input-box.jsx";
import TextInputBox from "../../forms/final-input-boxes/text-input-box.jsx";
import FieldWrap from "../../forms/wraps/field-wrap.jsx";
import InputWrap from "../../forms/wraps/input-wrap.jsx";
import { Button } from "../../schadcn-ui/button.jsx";
import ErrorSpan from "../../layout/error-span.jsx";
import { FaXmark } from "react-icons/fa6";
import { forwardRef, Fragment } from "react";
import PropTypes from "prop-types";

const OrderFormDialog = forwardRef(
    (
        {
            counties,
            orderFormData,
            actionsDisabled,
            saveOrderDialogForm,
            isOrderDialogOpened,
            closeOrderDialogForm,
            orderCorrectionErrors,
            handleOrderAddressFieldChange,
            handleOrderStandardFieldChange,
        },
        ref,
    ) => {
        return (
            <Fragment>
                {isOrderDialogOpened && (
                    <div className="fixed left-0 top-0 z-40 flex h-screen w-screen animate-fade cursor-default items-center justify-center bg-black/50 backdrop-blur-sm animate-duration-200">
                        <div
                            ref={ref}
                            className="relative flex max-h-[80vh] min-w-[40vw] max-w-[80vw] flex-col overflow-hidden rounded-lg bg-light p-4 shadow-lg">
                            <div className={"relative flex justify-end pb-4"}>
                                <button onClick={() => closeOrderDialogForm()}>
                                    <FaXmark />
                                </button>
                            </div>
                            <div className="-pl-2 relative flex h-full max-h-[80vh] w-full min-w-[40vw] max-w-[80vw] select-text flex-col overflow-x-hidden overflow-y-scroll pr-2">
                                {Object.keys(orderFormData).length > 0 && (
                                    <Fragment>
                                        <TextInputBox
                                            disabled={actionsDisabled}
                                            name={"contactPersonName"}
                                            errors={orderCorrectionErrors}
                                            label={"Denumire Persoana Contact"}
                                            handleChange={handleOrderStandardFieldChange}
                                            value={orderFormData["contactPersonName"] || ""}
                                        />
                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                disabled={actionsDisabled}
                                                name={"contactPersonEmail"}
                                                errors={orderCorrectionErrors}
                                                label={"Email Persoana Contact"}
                                                handleChange={handleOrderStandardFieldChange}
                                                value={orderFormData["contactPersonEmail"] || ""}
                                            />
                                            <TextInputBox
                                                disabled={actionsDisabled}
                                                name={"contactPersonPhone"}
                                                errors={orderCorrectionErrors}
                                                label={"Telefon Persoana Contact"}
                                                handleChange={handleOrderStandardFieldChange}
                                                value={orderFormData["contactPersonPhone"] || ""}
                                            />
                                        </div>
                                        <TextInputBox
                                            disabled={true}
                                            errors={orderCorrectionErrors}
                                            name={"dataOrientativaColectare"}
                                            label={"Data orientativa de ridicare"}
                                            handleChange={handleOrderStandardFieldChange}
                                            value={formatUnixTimestamp(orderFormData["dataOrientativaColectare"]) || ""}
                                        />

                                        <BooleanInputBox
                                            disabled={actionsDisabled}
                                            errors={orderCorrectionErrors}
                                            name={"pickupAddressSameWithSchoolAddress"}
                                            handleChange={handleOrderStandardFieldChange}
                                            label={"Adresa de colectare corespunde cu adresa scolii?"}
                                            value={orderFormData["pickupAddressSameWithSchoolAddress"]}
                                        />

                                        {orderFormData["pickupAddressSameWithSchoolAddress"] !== null && (
                                            <Fragment>
                                                <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                                    <SelectInputBox
                                                        label={"Judet"}
                                                        options={[...counties]}
                                                        name={"pickupAddress.judet"}
                                                        errors={orderCorrectionErrors}
                                                        display={{ value: "value", keys: ["value"] }}
                                                        emptyValueLabel={"Selectati judetul de livrare"}
                                                        handleChange={(e) => handleOrderAddressFieldChange(e)}
                                                        value={orderFormData?.["pickupAddress"]?.["judet"] || ""}
                                                        disabled={
                                                            !!orderFormData["pickupAddressSameWithSchoolAddress"] ||
                                                            actionsDisabled
                                                        }
                                                    />
                                                    <TextInputBox
                                                        label={"Oras"}
                                                        name={"pickupAddress.oras"}
                                                        errors={orderCorrectionErrors}
                                                        handleChange={handleOrderAddressFieldChange}
                                                        value={orderFormData["pickupAddress"]["oras"] || ""}
                                                        disabled={
                                                            !!orderFormData["pickupAddressSameWithSchoolAddress"] ||
                                                            actionsDisabled
                                                        }
                                                    />
                                                </div>
                                                <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                                    <TextInputBox
                                                        label={"Comuna"}
                                                        name={"pickupAddress.comuna"}
                                                        errors={orderCorrectionErrors}
                                                        handleChange={handleOrderAddressFieldChange}
                                                        value={orderFormData["pickupAddress"]["comuna"] || ""}
                                                        disabled={
                                                            !!orderFormData["pickupAddressSameWithSchoolAddress"] ||
                                                            actionsDisabled
                                                        }
                                                    />
                                                    <TextInputBox
                                                        label={"Sat"}
                                                        name={"pickupAddress.sat"}
                                                        errors={orderCorrectionErrors}
                                                        handleChange={handleOrderAddressFieldChange}
                                                        value={orderFormData["pickupAddress"]["sat"] || ""}
                                                        disabled={
                                                            !!orderFormData["pickupAddressSameWithSchoolAddress"] ||
                                                            actionsDisabled
                                                        }
                                                    />
                                                </div>
                                                <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                                    <TextInputBox
                                                        label={"Strada"}
                                                        name={"pickupAddress.strada"}
                                                        errors={orderCorrectionErrors}
                                                        handleChange={handleOrderAddressFieldChange}
                                                        value={orderFormData["pickupAddress"]["strada"] || ""}
                                                        disabled={
                                                            !!orderFormData["pickupAddressSameWithSchoolAddress"] ||
                                                            actionsDisabled
                                                        }
                                                    />
                                                    <TextInputBox
                                                        label={"Numar"}
                                                        name={"pickupAddress.numar"}
                                                        errors={orderCorrectionErrors}
                                                        handleChange={handleOrderAddressFieldChange}
                                                        value={orderFormData["pickupAddress"]["numar"] || ""}
                                                        disabled={
                                                            !!orderFormData["pickupAddressSameWithSchoolAddress"] ||
                                                            actionsDisabled
                                                        }
                                                    />
                                                </div>

                                                <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                                    <TextInputBox
                                                        label={"Bloc"}
                                                        name={"pickupAddress.bloc"}
                                                        errors={orderCorrectionErrors}
                                                        handleChange={handleOrderAddressFieldChange}
                                                        value={orderFormData["pickupAddress"]["bloc"] || ""}
                                                        disabled={
                                                            !!orderFormData["pickupAddressSameWithSchoolAddress"] ||
                                                            actionsDisabled
                                                        }
                                                    />
                                                    <TextInputBox
                                                        label={"Scara"}
                                                        name={"pickupAddress.scara"}
                                                        errors={orderCorrectionErrors}
                                                        handleChange={handleOrderAddressFieldChange}
                                                        value={orderFormData["pickupAddress"]["scara"] || ""}
                                                        disabled={
                                                            !!orderFormData["pickupAddressSameWithSchoolAddress"] ||
                                                            actionsDisabled
                                                        }
                                                    />
                                                </div>

                                                <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                                    <TextInputBox
                                                        label={"Etaj"}
                                                        name={"pickupAddress.etaj"}
                                                        errors={orderCorrectionErrors}
                                                        handleChange={handleOrderAddressFieldChange}
                                                        value={orderFormData["pickupAddress"]["etaj"] || ""}
                                                        disabled={
                                                            !!orderFormData["pickupAddressSameWithSchoolAddress"] ||
                                                            actionsDisabled
                                                        }
                                                    />
                                                    <TextInputBox
                                                        label={"Apartament"}
                                                        errors={orderCorrectionErrors}
                                                        name={"pickupAddress.apartament"}
                                                        value={orderFormData["pickupAddress"]["apartament"] || ""}
                                                        handleChange={handleOrderAddressFieldChange}
                                                        disabled={
                                                            !!orderFormData["pickupAddressSameWithSchoolAddress"] ||
                                                            actionsDisabled
                                                        }
                                                    />
                                                </div>

                                                <TextInputBox
                                                    label={"Cod postal"}
                                                    errors={orderCorrectionErrors}
                                                    name={"pickupAddress.codPostal"}
                                                    handleChange={handleOrderAddressFieldChange}
                                                    value={orderFormData["pickupAddress"]["codPostal"] || ""}
                                                    disabled={
                                                        !!orderFormData["pickupAddressSameWithSchoolAddress"] ||
                                                        actionsDisabled
                                                    }
                                                />
                                            </Fragment>
                                        )}

                                        <NumberInputBox
                                            name={"deeeKg"}
                                            disabled={actionsDisabled}
                                            label={"Kilograme de DEEE"}
                                            errors={orderCorrectionErrors}
                                            value={orderFormData["deeeKg"] || ""}
                                            handleChange={handleOrderStandardFieldChange}
                                        />
                                        <NumberInputBox
                                            name={"dbaKg"}
                                            disabled={actionsDisabled}
                                            label={"Kilograme de DBA"}
                                            errors={orderCorrectionErrors}
                                            value={orderFormData["dbaKg"] || ""}
                                            handleChange={handleOrderStandardFieldChange}
                                        />
                                        <NumberInputBox
                                            name={"becuriSiNeoaneKg"}
                                            disabled={actionsDisabled}
                                            errors={orderCorrectionErrors}
                                            label={"Kilograme de becuri si neoane"}
                                            handleChange={handleOrderStandardFieldChange}
                                            value={orderFormData["becuriSiNeoaneKg"] || ""}
                                        />
                                        <FieldWrap customStyles={"animate-fade"}>
                                            <div className="flex h-full flex-1 items-center justify-center">
                                                <InputWrap
                                                    customStyles={cn(
                                                        checkAnyErrorForPath(`photos`, orderCorrectionErrors)
                                                            ? "border-red-600"
                                                            : "p-0",
                                                    )}>
                                                    <div className="group relative flex h-full w-full flex-1 flex-col items-center justify-center rounded-[5px] text-center text-magenta/80 transition duration-500">
                                                        {Array.isArray(orderFormData["photos"]) &&
                                                            orderFormData["photos"].length > 0 && (
                                                                <div className="flex flex-wrap">
                                                                    {orderFormData["photos"].map((photo, index) => (
                                                                        <div
                                                                            key={index}
                                                                            className="relative m-2 h-32 w-32">
                                                                            <img
                                                                                src={photo}
                                                                                alt={`Uploaded ${index}`}
                                                                                className="h-full w-full cursor-default object-cover"
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                    </div>
                                                    <ErrorSpan
                                                        text={retrieveErrorForPath(`photos`, orderCorrectionErrors)}
                                                        customStyles={cn(
                                                            checkAnyErrorForPath(`photos`, orderCorrectionErrors)
                                                                ? "flex"
                                                                : "hidden",
                                                        )}
                                                    />
                                                </InputWrap>
                                            </div>
                                        </FieldWrap>
                                        <BooleanInputBox
                                            name={"consent"}
                                            disabled={true}
                                            errors={orderCorrectionErrors}
                                            handleChange={handleOrderStandardFieldChange}
                                            value={orderFormData["consent"]}
                                            label="Prin prezenta confirm că am citit Informarea cu privire la prelucrarea datelor mele cu caracter personal prezentata pe www.ecotic.ro/politica-de-confidentialitate-si-de-prelucrare-datelor-cu-caracter-personal"
                                        />
                                    </Fragment>
                                )}
                                <div className={"flex flex-col space-y-4 pb-4 pt-2"}>
                                    <Button onClick={() => saveOrderDialogForm()}>Salvare</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    },
);
OrderFormDialog.displayName = "OrderFormDialog";
OrderFormDialog.propTypes = {
    counties: PropTypes.array.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
    orderFormData: PropTypes.object.isRequired,
    isOrderDialogOpened: PropTypes.bool.isRequired,
    saveOrderDialogForm: PropTypes.func.isRequired,
    closeOrderDialogForm: PropTypes.func.isRequired,
    orderCorrectionErrors: PropTypes.object.isRequired,
    handleOrderAddressFieldChange: PropTypes.func.isRequired,
    handleOrderStandardFieldChange: PropTypes.func.isRequired,
};

export default OrderFormDialog;
