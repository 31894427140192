import EditableFormDialog from "../../components/reports/account-authorization/editable-form-dialog.jsx";
import useAccountAtuhorizationReport from "../../hooks/reports/use-account-atuhorization-report.js";
import ApprovalDialog from "../../components/reports/account-authorization/approval-dialog.jsx";
import TableHeaders from "../../components/reports/account-authorization/table-headers.jsx";
import FiltersResetButton from "../../components/reports/tables/filters-reset-button.jsx";
import NotesDialog from "../../components/reports/account-authorization/notes-dialog.jsx";
import FormDialog from "../../components/reports/account-authorization/form-dialog.jsx";
import TableBody from "../../components/reports/account-authorization/table-body.jsx";
import RefreshButton from "../../components/reports/tables/refresh-button.jsx";
import ReportDetails from "../../components/reports/tables/report-details.jsx";
import SizeSelector from "../../components/reports/tables/size-selector.jsx";
import ExportButton from "../../components/reports/tables/export-button.jsx";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import Pagination from "../../components/reports/tables/pagination.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import EmailDialog from "../../components/reports/account-authorization/email-dialog.jsx";

export default function AccountAuthorization() {
    const {
        note,
        email,
        params,
        printRef,
        counties,
        saveNote,
        saveEmail,
        dialogRef,
        tableData,
        pagination,
        changeNote,
        noteErrors,
        exportTable,
        changeEmail,
        changeParams,
        rejectReason,
        resetFilters,
        noteDialogRef,
        formDialogRef,
        openDialogForm,
        isDialogOpened,
        dialogFormData,
        openNotesDialog,
        actionsDisabled,
        closeFormDialog,
        closeNotesDialog,
        authorizeAccount,
        openRejectDialog,
        closeRejectDialog,
        emailChangeErrors,
        siiirDropdownData,
        isNoteDialogOpened,
        unauthorizeAccount,
        isFormDialogOpened,
        changeRejectReason,
        formElementInDialog,
        saveFormCorrections,
        correctionFormErrors,
        editableFormDialogRef,
        handleSIIIRCodeChange,
        openEmailChangeDialog,
        closeEmailChangeDialog,
        renderAtuhorizationReport,
        isEmailChangeDialogOpened,
        handleManualSIIIRCodeChange,
        isFormCorrectionDialogOpened,
        handleSimpleCorrectionFieldChange,
        handleComplexCorrectionFieldChange,
    } = useAccountAtuhorizationReport();

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap customStyles={"pr-0 rounded-none overflow-hidden"}>
                    <div className="flex w-full flex-col space-y-4 pb-4 lg:flex-row lg:items-end lg:justify-end lg:space-x-4 lg:space-y-0">
                        <ReportDetails pagination={pagination} />
                        <SizeSelector actionsDisabled={actionsDisabled} changeParams={changeParams} params={params} />
                        <FiltersResetButton handleRefresh={resetFilters} disabled={actionsDisabled} />
                        <RefreshButton handleRefresh={renderAtuhorizationReport} disabled={actionsDisabled} />
                        <ExportButton handleExport={exportTable} disabled={actionsDisabled} />
                    </div>
                    <div className="relative flex-1 overflow-auto pr-4">
                        <div className={"absolute left-0 top-0 w-full"}>
                            <table ref={printRef} className="relative h-full w-full">
                                <TableHeaders
                                    params={params}
                                    changeParams={changeParams}
                                    actionsDisabled={actionsDisabled}
                                />
                                <TableBody
                                    tableData={tableData}
                                    openDialogForm={openDialogForm}
                                    actionsDisabled={actionsDisabled}
                                    openNotesDialog={openNotesDialog}
                                    authorizeAccount={authorizeAccount}
                                    openRejectDialog={openRejectDialog}
                                    openEmailChangeDialog={openEmailChangeDialog}
                                />
                            </table>
                        </div>
                    </div>
                    <Pagination
                        tableData={tableData}
                        pagination={pagination}
                        changeParams={changeParams}
                        actionsDisabled={actionsDisabled}
                    />
                    <EmailDialog
                        email={email}
                        saveEmail={saveEmail}
                        changeEmail={changeEmail}
                        actionsDisabled={actionsDisabled}
                        emailChangeErrors={emailChangeErrors}
                        closeEmailChangeDialog={closeEmailChangeDialog}
                        isEmailChangeDialogOpened={isEmailChangeDialogOpened}
                    />
                    <ApprovalDialog
                        ref={dialogRef}
                        rejectReason={rejectReason}
                        isDialogOpened={isDialogOpened}
                        actionsDisabled={actionsDisabled}
                        closeRejectDialog={closeRejectDialog}
                        unauthorizeAccount={unauthorizeAccount}
                        changeRejectReason={changeRejectReason}
                    />
                    <FormDialog
                        ref={formDialogRef}
                        dialogFormData={dialogFormData}
                        closeFormDialog={closeFormDialog}
                        openRejectDialog={openRejectDialog}
                        authorizeAccount={authorizeAccount}
                        isFormDialogOpened={isFormDialogOpened}
                        formElementInDialog={formElementInDialog}
                    />
                    <NotesDialog
                        note={note}
                        saveNote={saveNote}
                        ref={noteDialogRef}
                        noteErrors={noteErrors}
                        changeNote={changeNote}
                        actionsDisabled={actionsDisabled}
                        closeNotesDialog={closeNotesDialog}
                        isNoteDialogOpened={isNoteDialogOpened}
                    />
                    <EditableFormDialog
                        counties={counties}
                        sensibleDataEditable={true}
                        ref={editableFormDialogRef}
                        dialogFormData={dialogFormData}
                        closeFormDialog={closeFormDialog}
                        actionsDisabled={actionsDisabled}
                        siiirDropdownData={siiirDropdownData}
                        saveFormCorrections={saveFormCorrections}
                        correctionFormErrors={correctionFormErrors}
                        handleSIIIRCodeChange={handleSIIIRCodeChange}
                        handleManualSIIIRCodeChange={handleManualSIIIRCodeChange}
                        isFormCorrectionDialogOpened={isFormCorrectionDialogOpened}
                        handleSimpleCorrectionFieldChange={handleSimpleCorrectionFieldChange}
                        handleComplexCorrectionFieldChange={handleComplexCorrectionFieldChange}
                    />
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    );
}
