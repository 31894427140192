import TextInputBox from "../../components/forms/final-input-boxes/text-input-box.jsx";
import useForgottenPassword from "../../hooks/auth/use-forgotten-password.js";
import { useRoutesProvider } from "../../providers/provider-hooks.jsx";
import { Button } from "../../components/schadcn-ui/button.jsx";
import PageWrap from "../../components/auth/page-wrap.jsx";
import H1 from "../../components/layout/h1.jsx";
import { useNavigate } from "react-router-dom";

export default function ForgottenPassword() {
    const { PATHS, PAGES } = useRoutesProvider();
    const navigate = useNavigate();
    const { handleSubmit, handleChange, loading, logInData, errors } = useForgottenPassword();

    return (
        <PageWrap>
            <div className="rounded-2xl bg-light/70 p-4 shadow">
                <div className="z-10 flex w-full max-w-md flex-1 flex-col justify-center">
                    <H1 customStyles="text-center" text="Am uitat parola" />
                    <form onSubmit={handleSubmit} className="flex w-full flex-col" autoComplete="off">
                        <TextInputBox
                            handleChange={handleChange}
                            name={"email"}
                            label={"Email"}
                            value={logInData["email"]}
                            errors={errors}
                        />
                        <Button size={"lg"} disabled={loading} className={"m-2"}>
                            Resetare Parola!
                        </Button>
                    </form>
                </div>
            </div>
            <div className="mt-4 rounded-2xl bg-light/70 p-4 shadow">
                <div className="z-10 flex w-full max-w-md flex-1 flex-col justify-center">
                    <Button
                        type={"button"}
                        size={"lg"}
                        disabled={loading}
                        className={"m-2"}
                        onClick={() => navigate(PATHS[PAGES.HOME])}>
                        Inapoi la pagina principala
                    </Button>
                </div>
            </div>
        </PageWrap>
    );
}
