import FieldWrap from "../../forms/wraps/field-wrap.jsx";
import InputWrap from "../../forms/wraps/input-wrap.jsx";
import { getQuizPlaceholder } from "../../../utils.js";
import { Button } from "../../schadcn-ui/button.jsx";
import WrapWithBg from "./wrap-with-bg.jsx";
import PropTypes from "prop-types";

export default function InvalidQuizId({ quizType, redirectToListing }) {
    return (
        <WrapWithBg customStyles={"justify-center animate-fade"}>
            <div className={"flex h-full w-full flex-col items-center justify-center space-y-4"}>
                <FieldWrap customStyles={"w-auto max-w-screen-md animate-fade"}>
                    <InputWrap customStyles={"w-auto p-4"}>
                        <span className={"text-justify text-large font-semibold"}>
                            Ne pare rau, nu am putut incarca {getQuizPlaceholder(quizType, 3)} cautata 😞💔. Te invitam
                            sa incerci completarea altei {getQuizPlaceholder(quizType, 5)} 🌳🌊. In cazul in care
                            problema persista, anuntati echipa ECOTIC!
                        </span>
                    </InputWrap>
                </FieldWrap>

                <div className={"max-w-screen-md animate-fade p-2"}>
                    <Button className={"flex h-auto w-auto"} onClick={redirectToListing}>
                        <span className={"text-wrap"}>Inapoi la lista cu {getQuizPlaceholder(quizType, 5)}</span>
                    </Button>
                </div>
            </div>
        </WrapWithBg>
    );
}
InvalidQuizId.propTypes = {
    quizType: PropTypes.string.isRequired,
    redirectToListing: PropTypes.func.isRequired,
};
