import { QUESTION_SLIDE_COLORS } from "../../../../constants/question-slide-colors.js";
import FieldWrap from "../../../forms/wraps/field-wrap.jsx";
import InputWrap from "../../../forms/wraps/input-wrap.jsx";
import { Button } from "../../../schadcn-ui/button.jsx";
import { cn } from "../../../../utils.js";
import PropTypes from "prop-types";
import { useMemo } from "react";

export default function QuestionSlide({
    quizForm,
    currentSlide,
    saveAndMoveToAvailableSlide,
    currentAnswer,
    addOrRemoveOption,
    actionsDisabled,
}) {
    const slide = useMemo(() => quizForm.slides.find((slide) => slide.id === currentSlide), [currentSlide, quizForm]);

    return (
        <div className={"my-auto flex flex-col items-center"}>
            <FieldWrap customStyles={"w-auto max-w-md animate-fade"}>
                <InputWrap customStyles={"w-auto p-4"}>
                    <span className={"text-center text-large font-semibold"}>{slide.text}</span>
                </InputWrap>
            </FieldWrap>

            {slide.image && (
                <FieldWrap customStyles={"w-auto max-w-sm animate-fade"}>
                    <InputWrap customStyles={"w-auto p-0"}>
                        <img src={slide.image} alt={""} />
                    </InputWrap>
                </FieldWrap>
            )}

            <div className={"grid w-full animate-fade grid-cols-1 gap-4 px-2 py-2 lg:grid-cols-2"}>
                {slide.options.map((option, index) => {
                    return (
                        <InputWrap
                            key={index}
                            customStyles={cn(QUESTION_SLIDE_COLORS[index].completionStyles, "w-full shadow-2xl px-0")}>
                            <div
                                className={cn(
                                    QUESTION_SLIDE_COLORS[index].completionStyles,
                                    "flex w-full flex-1 flex-row space-x-2 rounded-[5px] p-2",
                                )}>
                                <div className="flex h-full items-center justify-center">
                                    <div className={"flex items-center justify-center rounded-full bg-white"}>
                                        <button
                                            onClick={() => addOrRemoveOption(option.id)}
                                            disabled={actionsDisabled}
                                            className={cn(
                                                "h-6 w-6 appearance-none rounded-full bg-white text-magenta ring-2 ring-dark ring-offset-2 transition duration-300 ease-in-out",
                                                currentAnswer["selectedOptionsIds"].includes(option.id)
                                                    ? "bg-dark"
                                                    : "",
                                            )}
                                        />
                                    </div>
                                </div>

                                {option.image && (
                                    <img
                                        src={option.image}
                                        alt="Uploaded"
                                        className="max-h-[130px] max-w-[180px] shrink rounded-xl object-contain sm:max-h-[125px] sm:max-w-[150px] md:max-h-[125px] md:max-w-[170px] xl:max-h-[150px] xl:max-w-[280px]"
                                    />
                                )}

                                {option.text && (
                                    <div
                                        className={
                                            "flex h-full min-h-[50px] w-full items-center justify-center p-2 text-xl font-bold text-white"
                                        }>
                                        <span className={"text-center"}>{option.text || ""}</span>
                                    </div>
                                )}
                            </div>
                        </InputWrap>
                    );
                })}
            </div>

            <div className={"flex animate-fade justify-center p-2"}>
                <Button
                    className={"h-auto max-w-md"}
                    disabled={actionsDisabled}
                    onClick={() => saveAndMoveToAvailableSlide(slide["id"])}>
                    <span className={"text-wrap"}>Salveaza raspunsul</span>
                </Button>
            </div>
        </div>
    );
}
QuestionSlide.propTypes = {
    actionsDisabled: PropTypes.bool,
    quizForm: PropTypes.object.isRequired,
    currentSlide: PropTypes.string.isRequired,
    currentAnswer: PropTypes.object.isRequired,
    addOrRemoveOption: PropTypes.func.isRequired,
    saveAndMoveToAvailableSlide: PropTypes.func.isRequired,
};
