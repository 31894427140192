import { useState, useEffect, useRef } from "react";
import { toastOneWarn } from "../../toast.js";

function useDidUpdateEffect(effect, deps) {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) {
            effect();
        } else didMount.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}

export default function useTableParams(updateTable, defaultParams) {
    const [params, setParams] = useState({ ...defaultParams });
    const [debouncedParams, setDebouncedParams] = useState({ ...defaultParams });
    const debounceTimer = useRef(null);

    function validateValue(value, type) {
        if (type === "number") {
            const numberPattern = /^$|^\d+(\.\d+)?$/;
            if (numberPattern.test(value)) {
                return true;
            } else {
                toastOneWarn("Valoarea acestui camp trebuie sa fie un numar.");
                return false;
            }
        }
        return true;
    }

    function changeParams(e, type, skip) {
        const { name, value } = e.target;

        if (validateValue(value, type)) {
            const newParams = { ...params, [name]: value };
            setParams(newParams);

            if (debounceTimer.current) clearTimeout(debounceTimer.current);

            if (skip) {
                setDebouncedParams(newParams);
                return;
            }

            debounceTimer.current = setTimeout(() => {
                setDebouncedParams(newParams);
            }, 1500);
        }
    }

    useDidUpdateEffect(() => {
        const freshParams = JSON.parse(JSON.stringify(debouncedParams));
        for (const key in freshParams) {
            if ([undefined, null, ""].includes(freshParams[key])) delete freshParams[key];
            if (typeof freshParams[key] === "string") freshParams[key] = freshParams[key].trim();
        }
        updateTable(freshParams);
    }, [debouncedParams]);

    return { params, changeParams, setParams };
}
