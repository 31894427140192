import FiltersResetButton from "../../components/reports/tables/filters-reset-button.jsx";
import { useNotificationsReport } from "../../hooks/reports/use-notifications-report.js";
import TableHeaders from "../../components/reports/notifications/table-headers.jsx";
import RefreshButton from "../../components/reports/tables/refresh-button.jsx";
import TableBody from "../../components/reports/notifications/table-body.jsx";
import ExportButton from "../../components/reports/tables/export-button.jsx";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import Pagination from "../../components/reports/tables/pagination.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import ReportDetails from "../../components/reports/tables/report-details.jsx";
import SizeSelector from "../../components/reports/tables/size-selector.jsx";

export default function Notifications() {
    const {
        tableData,
        printRef,
        resetFilters,
        renderNotificationsReport,
        pagination,
        actionsDisabled,
        params,
        changeParams,
        exportTable,
    } = useNotificationsReport();

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap customStyles={"pr-0 rounded-none overflow-hidden"}>
                    <div
                        className={
                            "flex w-full flex-col space-y-4 pb-4 lg:flex-row lg:items-end lg:justify-end lg:space-x-4 lg:space-y-0"
                        }>
                        <ReportDetails pagination={pagination} />
                        <SizeSelector actionsDisabled={actionsDisabled} changeParams={changeParams} params={params} />
                        <FiltersResetButton handleRefresh={resetFilters} disabled={actionsDisabled} />
                        <RefreshButton handleRefresh={renderNotificationsReport} disabled={actionsDisabled} />
                        <ExportButton handleExport={exportTable} disabled={actionsDisabled} />
                    </div>
                    <div className="relative flex-1 overflow-auto pr-4">
                        <div className={"absolute left-0 top-0 w-full"}>
                            <table ref={printRef} className="relative h-full w-full">
                                <TableHeaders
                                    actionsDisabled={actionsDisabled}
                                    params={params}
                                    changeParams={changeParams}
                                />
                                <TableBody tableData={tableData} actionsDisabled={actionsDisabled} />
                            </table>
                        </div>
                    </div>
                    <Pagination
                        pagination={pagination}
                        changeParams={changeParams}
                        actionsDisabled={actionsDisabled}
                        tableData={tableData}
                    />
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    );
}
