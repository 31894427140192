import PreviewTitle from "./preview-title.jsx";
import { cn } from "../../../../utils.js";
import PropTypes from "prop-types";

export default function SettingsPreview({ selectSlide, selectedSlide, checkAnyErrorForPath }) {
    const errors =
        checkAnyErrorForPath("id") ||
        checkAnyErrorForPath("title") ||
        checkAnyErrorForPath("description") ||
        checkAnyErrorForPath("companyId") ||
        checkAnyErrorForPath("timestamp");

    return (
        <div
            className={
                "group flex max-h-[60px] min-h-[60px] max-w-[132.82px] select-none flex-row space-x-1 sm:max-h-[30px] sm:min-h-[30px]"
            }>
            <div
                className={cn(
                    "flex h-full w-[15px] min-w-[15px] cursor-pointer flex-col justify-end space-y-2 opacity-0",
                )}
            />
            <div
                onClick={() => selectSlide(null)}
                className={cn(
                    errors
                        ? "ring-2 ring-red-600"
                        : selectedSlide === null
                          ? "ring-2 ring-magenta/80"
                          : "ring-magenta/80 group-hover:ring-2",
                    "relative flex h-full w-[91.18px] flex-1 cursor-pointer items-center rounded-md bg-gray-200 text-very-small",
                )}>
                <PreviewTitle titlePlaceholder={"Setari"} />
            </div>
        </div>
    );
}
SettingsPreview.propTypes = {
    selectSlide: PropTypes.func.isRequired,
    checkAnyErrorForPath: PropTypes.func.isRequired,
    selectedSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};
