import { cn } from "../../../utils.js";
import PropTypes from "prop-types";

export default function FieldWrap({ children, customStyles = "" }) {
    return <div className={cn("w-full rounded-md p-2", customStyles)}>{children}</div>;
}
FieldWrap.propTypes = {
    children: PropTypes.node.isRequired,
    customStyles: PropTypes.string,
};
