import MarkdownPreview from "../../components/forms/raw-inputs/preview-markdown.jsx";
import PreviewTextBox from "../../components/quizzes/creation/preview-text-box.jsx";
import Pagination from "../../components/reports/tables/pagination.jsx";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import { useInboxProvider } from "../../providers/provider-hooks.jsx";
import { Button } from "../../components/schadcn-ui/button.jsx";
import { cn, formatUnixTimestamp } from "../../utils.js";
import { useEffect, useMemo, useRef } from "react";
import { FaXmark } from "react-icons/fa6";

export default function NotificationsReceived() {
    const {
        pagination,
        changeParams,
        notifications,
        actionsDisabled,
        readNotification,
        openedNotification,
        setOpenedNotification,
    } = useInboxProvider();
    const unreadNotifications = useMemo(
        () => (notifications.length ? notifications.filter((n) => n["isRead"] === false).length : 0),
        [notifications],
    );
    const dialogRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) =>
            dialogRef.current && !dialogRef.current.contains(event.target) ? setOpenedNotification("") : null;
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap>
                    <div className={"flex flex-col"}>
                        <div>
                            <PreviewTextBox
                                text={`Inbox ${unreadNotifications > 0 ? (unreadNotifications === 1 ? " - 1 notificare necititia" : ` - ${unreadNotifications} notificari necitite`) : ""}`}
                            />
                        </div>
                        <div className="space-y-4 overflow-x-hidden p-2">
                            {Array.isArray(notifications) &&
                                notifications.length > 0 &&
                                notifications.map((notification, notificationIdx) => (
                                    <div
                                        key={notification["id"].toString()}
                                        style={{ animationDelay: `${notificationIdx * 100}ms` }}
                                        className={cn(
                                            !notification["isRead"] ? "border-red-500" : "border-green-500",
                                            "flex animate-fade flex-col rounded-lg border-2 bg-white p-4 shadow-md",
                                        )}>
                                        <h2 className="text-xl font-semibold">{notification["subject"]}</h2>
                                        <span className="mt-2 text-sm text-gray-500">
                                            Data primire: {formatUnixTimestamp(notification["sendAt"].toString())}
                                        </span>
                                        <span
                                            className={cn(
                                                !notification["isRead"] ? "text-red-500" : "text-green-500",
                                                "mt-2 text-sm",
                                            )}>
                                            {!notification["isRead"] ? "NECITIT" : "CITIT"}
                                        </span>
                                        <div className={"flex w-full justify-end"}>
                                            <Button
                                                variant={"link"}
                                                onClick={() => readNotification(notification["id"])}>
                                                Deschide
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>

                    <Pagination
                        pagination={pagination}
                        changeParams={changeParams}
                        actionsDisabled={actionsDisabled}
                        tableData={notifications}
                    />

                    {openedNotification && (
                        <div className="fixed left-0 top-0 z-50 flex h-screen w-screen animate-fade cursor-default items-center justify-center bg-black/50 backdrop-blur-sm animate-duration-200">
                            <div
                                ref={dialogRef}
                                className="relative flex max-h-[80vh] min-w-[40vw] max-w-[80vw] flex-col overflow-y-hidden rounded-lg bg-white p-4 shadow-lg">
                                <div className={"relative flex justify-end pb-4"}>
                                    <button onClick={() => setOpenedNotification("")} className="">
                                        <FaXmark />
                                    </button>
                                </div>
                                <div
                                    className={
                                        "relative flex h-full max-h-[80vh] w-full min-w-[40vw] max-w-[80vw] select-text overflow-y-scroll pr-4"
                                    }>
                                    <MarkdownPreview text={openedNotification} />
                                </div>
                            </div>
                        </div>
                    )}
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    );
}
