import FiltersResetButton from "../../components/reports/tables/filters-reset-button.jsx";
import ApprovalDialog from "../../components/reports/quizzes/approval-dialog.jsx";
import RefreshButton from "../../components/reports/tables/refresh-button.jsx";
import TableHeaders from "../../components/reports/quizzes/table-headers.jsx";
import QuizDialog from "../../components/reports/quizzes/quiz-dialog.jsx";
import useQuizzesReport from "../../hooks/reports/use-quizzes-report.js";
import Pagination from "../../components/reports/tables/pagination.jsx";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import TableBody from "../../components/reports/quizzes/table-body.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import ExportButton from "../../components/reports/tables/export-button.jsx";
import ReportDetails from "../../components/reports/tables/report-details.jsx";
import SizeSelector from "../../components/reports/tables/size-selector.jsx";

export default function Quizzes() {
    const {
        changeRejectReason,
        rejectReason,
        invalidateQuiz,
        quizData,
        tableData,
        printRef,
        quizDialogRef,
        elementInDialog,
        isApprovalDialogOpened,
        dialogRef,
        closeRejectDialog,
        params,
        changeParams,
        renderQuizzesReport,
        resetFilters,
        closeQuizDialog,
        openRejectDialog,
        validateQuiz,
        pagination,
        isQuizDialogOpened,
        quizAnswers,
        openQuizDialog,
        actionsDisabled,
        exportTable,
    } = useQuizzesReport();

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap customStyles={"pr-0 rounded-none overflow-hidden"}>
                    <div
                        className={
                            "flex w-full flex-col space-y-4 pb-4 lg:flex-row lg:items-end lg:justify-end lg:space-x-4 lg:space-y-0"
                        }>
                        <ReportDetails pagination={pagination} />
                        <SizeSelector actionsDisabled={actionsDisabled} changeParams={changeParams} params={params} />
                        <FiltersResetButton handleRefresh={resetFilters} disabled={actionsDisabled} />
                        <RefreshButton handleRefresh={renderQuizzesReport} disabled={actionsDisabled} />
                        <ExportButton handleExport={exportTable} disabled={actionsDisabled} />
                    </div>
                    <div className="relative flex-1 overflow-auto pr-4">
                        <div className={"absolute left-0 top-0 w-full"}>
                            <table ref={printRef} className="relative h-full w-full">
                                <TableHeaders
                                    actionsDisabled={actionsDisabled}
                                    params={params}
                                    changeParams={changeParams}
                                />
                                <TableBody
                                    tableData={tableData}
                                    openRejectDialog={openRejectDialog}
                                    validateQuiz={validateQuiz}
                                    actionsDisabled={actionsDisabled}
                                    openQuizDialog={openQuizDialog}
                                />
                            </table>
                        </div>
                    </div>
                    <Pagination
                        pagination={pagination}
                        changeParams={changeParams}
                        actionsDisabled={actionsDisabled}
                        tableData={tableData}
                    />
                    <ApprovalDialog
                        actionsDisabled={actionsDisabled}
                        isApprovalDialogOpened={isApprovalDialogOpened}
                        changeRejectReason={changeRejectReason}
                        closeRejectDialog={closeRejectDialog}
                        ref={dialogRef}
                        rejectReason={rejectReason}
                        invalidateQuiz={invalidateQuiz}
                    />
                    <QuizDialog
                        elementInDialog={elementInDialog}
                        closeQuizDialog={closeQuizDialog}
                        ref={quizDialogRef}
                        quizData={quizData}
                        quizAnswers={quizAnswers}
                        openRejectDialog={openRejectDialog}
                        validateQuiz={validateQuiz}
                        isQuizDialogOpened={isQuizDialogOpened}
                    />
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    );
}
