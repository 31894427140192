import { useAuthProvider, useRoutesProvider } from "./providers/provider-hooks.jsx";
import { Suspense, useEffect, useRef } from "react";
import { Route, Routes } from "react-router-dom";

export default function App() {
    const { checkPermissionsV2, redirectToHomePage, validateAppInitiation, token, refreshToken } = useAuthProvider();
    const { NAV_LINKS_V2, PAGES } = useRoutesProvider();
    const intervalRef = useRef(null);

    useEffect(() => {
        const validate = async () => await validateAppInitiation(token, refreshToken);
        if (intervalRef.current) clearInterval(intervalRef.current);
        intervalRef.current = setInterval(validate, 9.111111111 * 60 * 1000);
        return () => clearInterval(intervalRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, refreshToken]);

    return (
        <Suspense fallback={<div />}>
            <Routes>
                <Route exact path={"/"} element={redirectToHomePage()} />

                <Route path={NAV_LINKS_V2[PAGES.HOME].PATH} element={NAV_LINKS_V2[PAGES.HOME].COMPONENT} />

                <Route
                    path={NAV_LINKS_V2[PAGES.NEW_PASS_FOR_FORGOTTEN_PASSWORD].PATH}
                    element={NAV_LINKS_V2[PAGES.NEW_PASS_FOR_FORGOTTEN_PASSWORD].COMPONENT}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.TEACHER_AUTH].PATH}
                    element={NAV_LINKS_V2[PAGES.TEACHER_AUTH].COMPONENT}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.FORGOTTEN_PASSWORD].PATH}
                    element={NAV_LINKS_V2[PAGES.FORGOTTEN_PASSWORD].COMPONENT}
                />

                <Route path={NAV_LINKS_V2[PAGES.PUPIL_AUTH].PATH} element={NAV_LINKS_V2[PAGES.PUPIL_AUTH].COMPONENT} />

                <Route
                    path={NAV_LINKS_V2[PAGES.PUPIL_REGISTER].PATH}
                    element={NAV_LINKS_V2[PAGES.PUPIL_REGISTER].COMPONENT}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.BIG_SCHOOLS_DATA_TABLE].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.BIG_SCHOOLS_DATA_TABLE].PATH,
                        NAV_LINKS_V2[PAGES.BIG_SCHOOLS_DATA_TABLE].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.PUPILS_REPORT].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.PUPILS_REPORT].PATH,
                        NAV_LINKS_V2[PAGES.PUPILS_REPORT].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.LESSON_QUIZ_LISTING].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.LESSON_QUIZ_LISTING].PATH,
                        NAV_LINKS_V2[PAGES.LESSON_QUIZ_LISTING].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.PARENTAL_APPROVAL].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.PARENTAL_APPROVAL].PATH,
                        NAV_LINKS_V2[PAGES.PARENTAL_APPROVAL].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.LESSON_QUIZ_CREATION].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.LESSON_QUIZ_CREATION].PATH,
                        NAV_LINKS_V2[PAGES.LESSON_QUIZ_CREATION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.LESSON_QUIZ_CREATION_REDIRECT].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.LESSON_QUIZ_CREATION_REDIRECT].PATH,
                        NAV_LINKS_V2[PAGES.LESSON_QUIZ_CREATION_REDIRECT].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.HOMEWORK_LISTING].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.HOMEWORK_LISTING].PATH,
                        NAV_LINKS_V2[PAGES.HOMEWORK_LISTING].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.HOMEWORK_CREATION].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.HOMEWORK_CREATION].PATH,
                        NAV_LINKS_V2[PAGES.HOMEWORK_CREATION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.HOMEWORK_CREATION_REDIRECT].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.HOMEWORK_CREATION_REDIRECT].PATH,
                        NAV_LINKS_V2[PAGES.HOMEWORK_CREATION_REDIRECT].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_LISTING].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_LISTING].PATH,
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_LISTING].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_CREATION].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_CREATION].PATH,
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_CREATION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_CREATION_REDIRECT].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_CREATION_REDIRECT].PATH,
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_CREATION_REDIRECT].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_LISTING].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_LISTING].PATH,
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_LISTING].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_CREATION].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_CREATION].PATH,
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_CREATION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_CREATION_REDIRECT].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_CREATION_REDIRECT].PATH,
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_CREATION_REDIRECT].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_LISTING].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_LISTING].PATH,
                        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_LISTING].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_CREATION].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_CREATION].PATH,
                        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_CREATION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_CREATION_REDIRECT].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_CREATION_REDIRECT].PATH,
                        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_CREATION_REDIRECT].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.WASTE_COLLECTION_FORM].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.WASTE_COLLECTION_FORM].PATH,
                        NAV_LINKS_V2[PAGES.WASTE_COLLECTION_FORM].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.WASTE_COLLECTION_TABLE].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.WASTE_COLLECTION_TABLE].PATH,
                        NAV_LINKS_V2[PAGES.WASTE_COLLECTION_TABLE].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.OUTBOX_V2].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.OUTBOX_V2].PATH,
                        NAV_LINKS_V2[PAGES.OUTBOX_V2].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.INBOX].PATH}
                    element={checkPermissionsV2(NAV_LINKS_V2[PAGES.INBOX].PATH, NAV_LINKS_V2[PAGES.INBOX].COMPONENT)}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.SENT_NOTIFICATIONS].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.SENT_NOTIFICATIONS].PATH,
                        NAV_LINKS_V2[PAGES.SENT_NOTIFICATIONS].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.ACCOUNTS_AUTHORIZATION].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.ACCOUNTS_AUTHORIZATION].PATH,
                        NAV_LINKS_V2[PAGES.ACCOUNTS_AUTHORIZATION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.ACCOUNT_UNAUTHORIZED_PUPIL].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.ACCOUNT_UNAUTHORIZED_PUPIL].PATH,
                        NAV_LINKS_V2[PAGES.ACCOUNT_UNAUTHORIZED_PUPIL].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.ACCOUNT_UNAUTHORIZED_TEACHER].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.ACCOUNT_UNAUTHORIZED_TEACHER].PATH,
                        NAV_LINKS_V2[PAGES.ACCOUNT_UNAUTHORIZED_TEACHER].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.LESSON_COMPLETION].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.LESSON_COMPLETION].PATH,
                        NAV_LINKS_V2[PAGES.LESSON_COMPLETION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.LESSON_COMPLETION_PREVIEW].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.LESSON_COMPLETION_PREVIEW].PATH,
                        NAV_LINKS_V2[PAGES.LESSON_COMPLETION_PREVIEW].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.HOMEWORK_COMPLETION].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.HOMEWORK_COMPLETION].PATH,
                        NAV_LINKS_V2[PAGES.HOMEWORK_COMPLETION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.HOMEWORK_COMPLETION_PREVIEW].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.HOMEWORK_COMPLETION_PREVIEW].PATH,
                        NAV_LINKS_V2[PAGES.HOMEWORK_COMPLETION_PREVIEW].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_COMPLETION].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_COMPLETION].PATH,
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_COMPLETION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_COMPLETION_PREVIEW].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_COMPLETION_PREVIEW].PATH,
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_COMPLETION_PREVIEW].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_COMPLETION].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_COMPLETION].PATH,
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_COMPLETION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_COMPLETION_PREVIEW].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_COMPLETION_PREVIEW].PATH,
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_COMPLETION_PREVIEW].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_COMPLETION].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_COMPLETION].PATH,
                        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_COMPLETION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_COMPLETION_PREVIEW].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_COMPLETION_PREVIEW].PATH,
                        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_COMPLETION_PREVIEW].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.SCHOOLS_REPORT].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.SCHOOLS_REPORT].PATH,
                        NAV_LINKS_V2[PAGES.SCHOOLS_REPORT].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.PROFILE].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.PROFILE].PATH,
                        NAV_LINKS_V2[PAGES.PROFILE].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.COMPLETED_QUIZZES].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.COMPLETED_QUIZZES].PATH,
                        NAV_LINKS_V2[PAGES.COMPLETED_QUIZZES].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.NOTIFICATIONS_REPORT].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.NOTIFICATIONS_REPORT].PATH,
                        NAV_LINKS_V2[PAGES.NOTIFICATIONS_REPORT].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.SCHOOL_FORM_V2].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.SCHOOL_FORM_V2].PATH,
                        NAV_LINKS_V2[PAGES.SCHOOL_FORM_V2].COMPONENT,
                    )}
                />

                {/*<Route*/}
                {/*    path={NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V2].PATH}*/}
                {/*    element={checkPermissionsV2(*/}
                {/*        NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V2].PATH,*/}
                {/*        NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V2].COMPONENT,*/}
                {/*    )}*/}
                {/*/>*/}

                {/*<Route*/}
                {/*    path={NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3].PATH}*/}
                {/*    element={checkPermissionsV2(*/}
                {/*        NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3].PATH,*/}
                {/*        NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3].COMPONENT,*/}
                {/*    )}*/}
                {/*/>*/}

                <Route
                    path={NAV_LINKS_V2[PAGES.FINAL_REPORT].PATH}
                    element={checkPermissionsV2(
                        NAV_LINKS_V2[PAGES.FINAL_REPORT].PATH,
                        NAV_LINKS_V2[PAGES.FINAL_REPORT].COMPONENT,
                    )}
                />

                <Route path={"*"} element={redirectToHomePage()} />
            </Routes>
        </Suspense>
    );
}
