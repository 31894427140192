import useTextAreaResize from "../../../../hooks/use-text-area-resize.js";
import TextareaInput from "../../../forms/raw-inputs/textarea-input.jsx";
import FieldWrap from "../../../forms/wraps/field-wrap.jsx";
import InputWrap from "../../../forms/wraps/input-wrap.jsx";
import ErrorSpan from "../../../layout/error-span.jsx";
import PreviewTextBox from "../preview-text-box.jsx";
import { useEffect, useMemo } from "react";
import QuizWrap from "../quiz-wrap.jsx";
import { cn } from "../../../../utils.js";
import PropTypes from "prop-types";

export default function TextSlide({
    isInPreview,
    checkAnyErrorForPath,
    formData,
    selectedSlide,
    retrieveErrorForPath,
    changeSlideTitle,
}) {
    const slide = useMemo(() => formData.slides.find((slide) => slide.id === selectedSlide), [selectedSlide, formData]);
    const slideIdx = useMemo(
        () => formData.slides.findIndex((slide) => slide.id === selectedSlide),
        [selectedSlide, formData],
    );
    const { ref, resizeTextarea } = useTextAreaResize();

    useEffect(() => {
        if (!isInPreview) resizeTextarea(ref);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInPreview]);

    return (
        <QuizWrap>
            <div className={"flex h-full w-full items-center justify-center"}>
                <FieldWrap customStyles={"max-w-md"}>
                    <InputWrap
                        customStyles={cn(checkAnyErrorForPath(`slides[${slideIdx}].text`) ? "border-red-600" : "")}>
                        {!isInPreview && (
                            <TextareaInput
                                name={`text-${selectedSlide}`}
                                value={slide.text || ""}
                                ref={ref}
                                handleChange={(e) => changeSlideTitle(e.target.value)}
                                label={"Textul pentru compunerea textului (Obligatoriu)"}
                                customStyles={slide.text ? "font-black" : "sr-only"}
                            />
                        )}
                        {isInPreview && <PreviewTextBox text={slide.text} />}
                        <ErrorSpan
                            text={retrieveErrorForPath(`slides[${slideIdx}].text`)}
                            customStyles={cn(checkAnyErrorForPath(`slides[${slideIdx}].text`) ? "flex" : "hidden")}
                        />
                    </InputWrap>
                </FieldWrap>
            </div>
        </QuizWrap>
    );
}
TextSlide.propTypes = {
    formData: PropTypes.object.isRequired,
    isInPreview: PropTypes.bool.isRequired,
    changeSlideTitle: PropTypes.func.isRequired,
    checkAnyErrorForPath: PropTypes.func.isRequired,
    retrieveErrorForPath: PropTypes.func.isRequired,
    selectedSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};
