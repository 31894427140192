import { Popover, PopoverContent, PopoverTrigger } from "../../schadcn-ui/popover.jsx";
import { Calendar as CalendarIcon } from "lucide-react";
import Calendar from "../../schadcn-ui/calendar.jsx";
import { toastOneWarn } from "../../../toast.js";
import { format, endOfDay } from "date-fns";
import { cn } from "../../../utils.js";
import { ro } from "date-fns/locale";
import PropTypes from "prop-types";

export function DatePickerInput({ name, label, value, handleChange, disabled }) {
    return (
        <Popover>
            <PopoverTrigger asChild disabled={disabled} className={"disabled:cursor-not-allowed disabled:opacity-50"}>
                <button
                    className={cn(
                        "flex min-h-[28px] w-full min-w-[200px] flex-col justify-center rounded-md text-left text-dark hover:text-dark",
                    )}>
                    <div className={"flex w-full items-center justify-center pb-1 text-[16px]"}>
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        <span className={"w-full text-nowrap"}>
                            {value ? (
                                format(new Date(value * 1000), "PPP", { locale: ro })
                            ) : (
                                <span className={"font-bold text-dark/60"}>{label}</span>
                            )}
                        </span>
                    </div>
                    <div className={"min-h-[1px] w-full rounded-full bg-dark"} />
                </button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
                <Calendar
                    mode="single"
                    selected={value ? new Date(value * 1000) : null}
                    initialFocus
                    locale={ro}
                    disabled={disabled}
                    onSelect={(date) =>
                        handleChange({ target: { name: name, value: date ? Math.floor(date.getTime() / 1000) : "" } })
                    }
                />
            </PopoverContent>
        </Popover>
    );
}

DatePickerInput.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
};

export function CalendarRangePicker({ className, from, to, handleChange, fromName, toName, disabled }) {
    const handleDateChange = ({ target: { name, value } }) => {
        let newFrom = from;
        let newTo = to;

        if (name === fromName) {
            newFrom = value;
            if (newTo && newFrom && newFrom > newTo)
                return toastOneWarn("Data de început nu poate fi mai mare decât data de sfârșit.");
        } else if (name === toName) {
            if (value) {
                newTo = Math.floor(endOfDay(new Date(value * 1000)).getTime() / 1000);
            } else {
                newTo = value;
            }
            if (newFrom && newTo && newFrom > newTo)
                return toastOneWarn("Data de sfârșit nu poate fi mai mică decât data de început.");
        }

        if (newFrom !== from) handleChange({ target: { name: fromName, value: newFrom } });
        if (newTo !== to) handleChange({ target: { name: toName, value: newTo } });
    };

    return (
        <div className={cn("flex flex-nowrap space-x-4", className)}>
            <DatePickerInput
                name={fromName}
                label="De la..."
                value={from}
                disabled={disabled}
                handleChange={handleDateChange}
            />
            <DatePickerInput
                name={toName}
                label="Pana la..."
                value={to}
                handleChange={handleDateChange}
                disabled={disabled}
            />
        </div>
    );
}

CalendarRangePicker.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleChange: PropTypes.func.isRequired,
    fromName: PropTypes.string.isRequired,
    toName: PropTypes.string.isRequired,
};
