import { useAuthProvider, useRoutesProvider } from "../../../providers/provider-hooks.jsx";
import BooleanInputBox from "../../forms/final-input-boxes/boolean-input-box.jsx";
import NumberInputBox from "../../forms/final-input-boxes/number-input-box.jsx";
import TextInputBox from "../../forms/final-input-boxes/text-input-box.jsx";
import { Button } from "../../schadcn-ui/button.jsx";
import { forwardRef, Fragment } from "react";
import { FaXmark } from "react-icons/fa6";
import PropTypes from "prop-types";

const FormDialog = forwardRef(
    (
        {
            isFormDialogOpened,
            closeFormDialog,
            openRejectDialog,
            authorizeAccount,
            formElementInDialog,
            dialogFormData,
        },
        ref,
    ) => {
        const { authState } = useAuthProvider();
        const { ROLES } = useRoutesProvider();

        return (
            <Fragment>
                {isFormDialogOpened && (
                    <div className="fixed left-0 top-0 z-40 flex h-screen w-screen animate-fade cursor-default items-center justify-center bg-black/50 backdrop-blur-sm animate-duration-200">
                        <div
                            ref={ref}
                            className="relative flex max-h-[80vh] min-w-[40vw] max-w-[80vw] flex-col overflow-hidden rounded-lg bg-light p-4 shadow-lg">
                            <div className={"relative flex justify-end pb-4"}>
                                <button onClick={() => closeFormDialog()} className="">
                                    <FaXmark />
                                </button>
                            </div>
                            <div
                                className={
                                    "-pl-2 relative flex h-full max-h-[80vh] w-full min-w-[40vw] max-w-[80vw] select-text flex-col overflow-x-hidden overflow-y-scroll pr-2"
                                }>
                                {Object.keys(dialogFormData).length > 0 && (
                                    <Fragment>
                                        <TextInputBox
                                            name={"codSiiirUnitate"}
                                            label={"Codul SIIIR al institutiei de invatamant"}
                                            value={dialogFormData?.["codSiiirUnitate"] || ""}
                                            disabled={true}
                                            handleChange={() => false}
                                        />

                                        <TextInputBox
                                            name={"codSe"}
                                            label={"Cod SE"}
                                            value={dialogFormData?.["codSe"] || ""}
                                            disabled={true}
                                            handleChange={() => false}
                                        />

                                        <TextInputBox
                                            name={"cui"}
                                            label={"C.U.I."}
                                            disabled={true}
                                            value={dialogFormData?.["cui"] || ""}
                                            handleChange={() => false}
                                        />

                                        <TextInputBox
                                            name={"numeInstitutieInvatamant"}
                                            label={"Denumire institutie invatamant"}
                                            value={dialogFormData?.["numeInstitutieInvatamant"] || ""}
                                            disabled={true}
                                            handleChange={() => false}
                                        />

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                name={"adresa.judet"}
                                                label={"Judetul Scolii"}
                                                value={dialogFormData?.["adresa"]?.["judet"] || ""}
                                                handleChange={() => false}
                                                disabled={true}
                                            />
                                            <TextInputBox
                                                name={"adresa.oras"}
                                                label={"Orasul Scolii"}
                                                value={dialogFormData?.["adresa"]?.["oras"] || ""}
                                                handleChange={() => false}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                name={"adresa.comuna"}
                                                label={"Comuna Scolii"}
                                                value={dialogFormData?.["adresa"]?.["comuna"] || ""}
                                                handleChange={() => false}
                                                disabled={true}
                                            />
                                            <TextInputBox
                                                name={"adresa.sat"}
                                                label={"Satul Scolii"}
                                                value={dialogFormData?.["adresa"]?.["sat"] || ""}
                                                handleChange={() => false}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                name={"adresa.strada"}
                                                label={"Strada Scolii"}
                                                value={dialogFormData?.["adresa"]?.["strada"] || ""}
                                                handleChange={() => false}
                                                disabled={true}
                                            />
                                            <TextInputBox
                                                name={"adresa.numar"}
                                                label={"Numarul Strazii"}
                                                value={dialogFormData?.["adresa"]?.["numar"] || ""}
                                                handleChange={() => false}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                name={"adresa.bloc"}
                                                label={"Bloc"}
                                                value={dialogFormData?.["adresa"]?.["bloc"] || ""}
                                                handleChange={() => false}
                                                disabled={true}
                                            />
                                            <TextInputBox
                                                name={"adresa.scara"}
                                                label={"Scara"}
                                                value={dialogFormData?.["adresa"]?.["scara"] || ""}
                                                handleChange={() => false}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                name={"adresa.etaj"}
                                                label={"Etaj"}
                                                value={dialogFormData?.["adresa"]?.["etaj"] || ""}
                                                handleChange={() => false}
                                                disabled={true}
                                            />
                                            <TextInputBox
                                                name={"adresa.apartament"}
                                                label={"Apartament"}
                                                value={dialogFormData?.["adresa"]?.["apartament"] || ""}
                                                handleChange={() => false}
                                                disabled={true}
                                            />
                                        </div>

                                        <TextInputBox
                                            name={"adresa.codPostal"}
                                            label={"Cod Postal"}
                                            value={dialogFormData?.["adresa"]?.["codPostal"] || ""}
                                            handleChange={() => false}
                                            disabled={true}
                                        />

                                        <TextInputBox
                                            name={"numeReprezentatLegal"}
                                            label={"Nume reprezentant legal"}
                                            value={dialogFormData?.["numeReprezentatLegal"] || ""}
                                            handleChange={() => false}
                                            disabled={true}
                                        />

                                        <BooleanInputBox
                                            handleChange={() => false}
                                            disabled={true}
                                            name={"confirmCaAmAcordulDePrincipiu"}
                                            label={
                                                "Confirm că am acordul de principiu al conducerii instituției de învățământ pe care o reprezint pentru participarea la Campania Școala Ecoterrienilor, ediția 2024-2025, și că voi prezenta Protocolul de colaborare semnat, înainte de începere."
                                            }
                                            value={dialogFormData["confirmCaAmAcordulDePrincipiu"]}
                                        />

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                name={"telefon"}
                                                label={"Telefon instituție"}
                                                value={dialogFormData?.["telefon"] || ""}
                                                handleChange={() => false}
                                                disabled={true}
                                            />
                                            <NumberInputBox
                                                name={"numarElevi"}
                                                label={"Numar elevi"}
                                                value={dialogFormData?.["numarElevi"] || ""}
                                                handleChange={() => false}
                                                disabled={true}
                                            />
                                        </div>

                                        <BooleanInputBox
                                            handleChange={() => false}
                                            disabled={true}
                                            name={"detineRecipiente"}
                                            label={"Scoala detine recipiente de colectare?"}
                                            value={dialogFormData?.["detineRecipiente"]}
                                        />
                                        <BooleanInputBox
                                            handleChange={() => false}
                                            disabled={true}
                                            name={"adresaLivrareAceeasiCuScoala"}
                                            label={"Adresa de livrare este aceeasi cu a scolii?"}
                                            value={dialogFormData?.["adresaLivrareAceeasiCuScoala"]}
                                        />

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                name={"adresaLivrare.judet"}
                                                label={"Judet livrare"}
                                                disabled={true}
                                                value={dialogFormData?.["adresaLivrare"]?.["judet"] || ""}
                                                handleChange={() => false}
                                            />
                                            <TextInputBox
                                                name={"adresaLivrare.oras"}
                                                label={"Oras livrare"}
                                                disabled={true}
                                                value={dialogFormData?.["adresaLivrare"]?.["oras"] || ""}
                                                handleChange={() => false}
                                            />
                                        </div>

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                name={"adresaLivrare.comuna"}
                                                label={"Comuna livrare"}
                                                disabled={true}
                                                value={dialogFormData?.["adresaLivrare"]?.["comuna"] || ""}
                                                handleChange={() => false}
                                            />
                                            <TextInputBox
                                                name={"adresaLivrare.sat"}
                                                label={"Sat livrare"}
                                                disabled={true}
                                                value={dialogFormData?.["adresaLivrare"]?.["sat"] || ""}
                                                handleChange={() => false}
                                            />
                                        </div>

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                name={"adresaLivrare.strada"}
                                                label={"Strada livrare"}
                                                disabled={true}
                                                value={dialogFormData?.["adresaLivrare"]?.["strada"] || ""}
                                                handleChange={() => false}
                                            />
                                            <TextInputBox
                                                name={"adresaLivrare.numar"}
                                                disabled={true}
                                                label={"Numarul strazii de livrare"}
                                                value={dialogFormData?.["adresaLivrare"]?.["numar"] || ""}
                                                handleChange={() => false}
                                            />
                                        </div>

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                name={"adresaLivrare.bloc"}
                                                disabled={true}
                                                value={dialogFormData?.["adresaLivrare"]?.["bloc"] || ""}
                                                handleChange={() => false}
                                                label={"Bloc livrare"}
                                            />
                                            <TextInputBox
                                                name={"adresaLivrare.scara"}
                                                label={"Scara bloc livrare"}
                                                value={dialogFormData?.["adresaLivrare"]?.["scara"] || ""}
                                                handleChange={() => false}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                name={"adresaLivrare.etaj"}
                                                disabled={true}
                                                value={dialogFormData?.["adresaLivrare"]?.["etaj"] || ""}
                                                handleChange={() => false}
                                                label={"Etaj bloc livrare"}
                                            />
                                            <TextInputBox
                                                name={"adresaLivrare.apartament"}
                                                disabled={true}
                                                value={dialogFormData?.["adresaLivrare"]?.["apartament"] || ""}
                                                label={"Apartament bloc livrare"}
                                                handleChange={() => false}
                                            />
                                        </div>

                                        <TextInputBox
                                            name={"adresaLivrare.codPostal"}
                                            disabled={true}
                                            value={dialogFormData?.["adresaLivrare"]?.["codPostal"] || ""}
                                            label={"Cod postal adresa livrare"}
                                            handleChange={() => false}
                                        />

                                        <BooleanInputBox
                                            handleChange={() => false}
                                            disabled={true}
                                            name={"prinPrezentaConfirmamInscrierea"}
                                            value={dialogFormData?.["prinPrezentaConfirmamInscrierea"]}
                                            label={
                                                "Prin prezenta confirmam înscrierea in Campania „Scoala Ecoterrienilor” si acceptarea deplina si necondiționata a Regulamentului disponibil pe www.ecotic.ro."
                                            }
                                        />
                                        <BooleanInputBox
                                            handleChange={() => false}
                                            disabled={true}
                                            name={"amFostInformati"}
                                            value={dialogFormData?.["amFostInformati"]}
                                            label={
                                                "Am fost informați despre modul de desfășurare al campaniei, procedura de colectare si regulamentul campaniei si suntem de acord cu toate acestea. Confirmăm ca ni s-a adus la cunoștință și acceptăm posibilitatea modificării, suspendării sau încetării Campaniei care ni se aplică, caz în care aceste sunt postate pe www.ecotic.ro."
                                            }
                                        />
                                        <BooleanInputBox
                                            handleChange={() => false}
                                            disabled={true}
                                            name={"confirmFaptulCaReprezentatulLegal"}
                                            value={dialogFormData?.["confirmFaptulCaReprezentatulLegal"]}
                                            label={
                                                "Confirm faptul ca reprezentantul legal al instituției de învățământ a desemnat profesorul coordonator menționat in cadrul formularului."
                                            }
                                        />
                                        <BooleanInputBox
                                            handleChange={() => false}
                                            disabled={true}
                                            name={"prinPrezentaConfirmCaAmCititInformarea"}
                                            value={dialogFormData?.["prinPrezentaConfirmCaAmCititInformarea"]}
                                            label={
                                                "Prin prezenta confirm că am citit Informarea (Notă de informare Școala Ecoterrienilor - https://www.ecotic.ro/informare-scoala-ecoterrienilor/ și Notă de informare înscriere profesori/școală în Școala Ecoterrienilor - https://www.ecotic.ro/nota-de-informare-inscriere-profesori-scoala-in-scoala-ecoterrienilor/) cu privire la prelucrarea datelor mele cu caracter personal și înțeleg ca prelucrarea datelor mele personale (nume, prenume, email si telefon) este strict necesara pentru înscrierea si participarea la competiție, conform Regulamentului, care este acordul părților (eu, pe de o parte, ECOTIC si ECOTIC BAT, pe de alta parte). Înțeleg că, în cazul în care nu doresc ca ECOTIC sa îmi prelucreze datele cu caracter personal, pot sa nu bifez acest câmp, iar acest formular nu va transmite datele mele către ASOCIATIA ECOTIC si ECOTIC BAT și nu voi putea fi înscris în competiția Școala Ecoterrienilor, relația contractuala dintre părți neputându-se încheia."
                                            }
                                        />
                                        <BooleanInputBox
                                            handleChange={() => false}
                                            disabled={true}
                                            name={"confirmCaInCazulInCareProiectul"}
                                            value={dialogFormData?.["confirmCaInCazulInCareProiectul"]}
                                            label={
                                                "Confirm că în cazul în care proiectul înscris include imagini cu persoane (majore sau minore), acestea si-au dat consimțământul expres, direct sau prin reprezentantul legal, pentru utilizarea imaginilor de către Organizator, consimțământ care va fi pus la dispoziția ECOTIC odată cu depunerea materialului. In cazul in care nu exista consimțământ, acest material nu poate fi depus sau , daca este depus, va fi descalificat, iar ECOTIC îl va șterge."
                                            }
                                        />
                                        <BooleanInputBox
                                            handleChange={() => false}
                                            disabled={true}
                                            name={"consimtPentruPastrareaDatelorPersonale"}
                                            value={dialogFormData?.["consimtPentruPastrareaDatelorPersonale"]}
                                            label={
                                                "Consimt pentru păstrarea datelor personale in baza de date a ASOCIATIEI ECOTIC pentru o perioada de maxim 5 ani cu scopul de a primi materiale informative pe tema protecției mediului si invitație de înscriere la ediția următoare a proiectului sau la alte proiecte pe tema protecției mediului."
                                            }
                                        />
                                        <BooleanInputBox
                                            handleChange={() => false}
                                            disabled={true}
                                            name={"ecoticPoateFolosiImagini"}
                                            value={dialogFormData?.["ecoticPoateFolosiImagini"]}
                                            label={
                                                "ECOTIC poate folosi imagini in care pot aparea participantii in urmatoarele scopuri: ca dovada ca s-au realizat activitatile propuse in Concurs, pentru promovarea rezultatelor Concursului pe site-ul ECOTIC si/sau paginile de social media ale ECOTIC, pentru a fi incluse in materiale de prezentare a activitatii ECOTIC (tip retrospectiva, newsletter etc.). Cu toate acestea, înțeleg că Organizatorii recomandă transmiterea de imagini fără identificarea facială a persoanelor."
                                            }
                                        />
                                        <BooleanInputBox
                                            handleChange={() => false}
                                            disabled={true}
                                            name={"amCititSiSuntDeAcord"}
                                            value={dialogFormData?.["amCititSiSuntDeAcord"]}
                                            label={
                                                "Am citit și sunt de acord cu Nota de Informare - https://www.ecotic.ro/nota-de-informare-inscriere-profesori-scoala-in-scoala-ecoterrienilor/ și Regulamentul Campaniei - https://www.ecotic.ro/wp-content/uploads/2024/09/REGULAMENTUL-OFICIAL-SCOALA-ECOTERRIENILOR-2024-2025.pdf"
                                            }
                                        />
                                    </Fragment>
                                )}
                                {[ROLES.ADMIN, ROLES.DBEST].includes(authState.userInfo.role) && (
                                    <div className={"flex flex-col space-y-4 pb-4 pt-2"}>
                                        <Button onClick={() => authorizeAccount(formElementInDialog, true)}>
                                            Aprobare
                                        </Button>
                                        <Button
                                            variant={"destructive"}
                                            onClick={() => openRejectDialog(formElementInDialog)}>
                                            Respingere
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    },
);
FormDialog.displayName = "FormDialog";
FormDialog.propTypes = {
    formElementInDialog: PropTypes.string,
    closeFormDialog: PropTypes.func.isRequired,
    openRejectDialog: PropTypes.func.isRequired,
    authorizeAccount: PropTypes.func.isRequired,
    dialogFormData: PropTypes.object.isRequired,
    isFormDialogOpened: PropTypes.bool.isRequired,
};

export default FormDialog;
