import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../schadcn-ui/select.jsx";
import { checkAnyErrorForPath, cn, retrieveErrorForPath } from "../../../utils.js";
import ErrorSpan from "../../layout/error-span.jsx";
import InputWrap from "../wraps/input-wrap.jsx";
import FieldWrap from "../wraps/field-wrap.jsx";
import PropTypes from "prop-types";

export default function SelectInputBox({
    name,
    value,
    label,
    errors,
    options,
    display,
    handleChange,
    emptyValueLabel,
    customErrorStyles,
    customLabelStyles,
    disabled = false,
}) {
    return (
        <FieldWrap>
            <InputWrap customStyles={cn("px-0")}>
                <label
                    htmlFor={name}
                    className={cn(
                        disabled ? "opacity-50" : "",
                        value ? "font-black" : "sr-only",
                        "w-full text-center font-bold text-gray-400",
                        customLabelStyles,
                    )}>
                    {label}
                </label>
                <Select
                    disabled={disabled}
                    onValueChange={(v) => handleChange({ target: { name: name, value: v } })}
                    value={value}>
                    <SelectTrigger
                        className={"h-auto text-wrap border-0 bg-white text-[16px] [&>span]:line-clamp-none"}>
                        <SelectValue
                            placeholder={emptyValueLabel}
                            className={"line-clamp-none h-auto w-full text-wrap font-bold"}
                        />
                    </SelectTrigger>
                    <SelectContent
                        className={
                            "-ml-[3px] max-h-[300px] rounded-lg border-[3px] border-gray-300 bg-white text-[16px] shadow-2xl"
                        }>
                        <SelectGroup>
                            {options.map((option, optionIdx) => (
                                <SelectItem
                                    key={optionIdx}
                                    className={"h-auto text-wrap"}
                                    value={option[display.value]}>
                                    <div className={"text-wrap"}>
                                        {display.keys.map((key) => option[key]).join(" - ")}
                                    </div>
                                </SelectItem>
                            ))}
                        </SelectGroup>
                    </SelectContent>
                </Select>
                <ErrorSpan
                    text={retrieveErrorForPath(name, errors)}
                    customStyles={cn(checkAnyErrorForPath(name, errors) ? "flex" : "hidden", customErrorStyles)}
                />
            </InputWrap>
        </FieldWrap>
    );
}
SelectInputBox.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
    customErrorStyles: PropTypes.string,
    options: PropTypes.array.isRequired,
    customLabelStyles: PropTypes.string,
    display: PropTypes.object.isRequired,
    emptyValueLabel: PropTypes.string.isRequired,
};
