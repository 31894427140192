import MarkdownPreview from "../../../forms/raw-inputs/preview-markdown.jsx";
import FieldWrap from "../../../forms/wraps/field-wrap.jsx";
import InputWrap from "../../../forms/wraps/input-wrap.jsx";
import { Button } from "../../../schadcn-ui/button.jsx";
import { cn } from "../../../../utils.js";
import PropTypes from "prop-types";
import { useMemo } from "react";

export default function InfoSlide({ quizForm, currentSlide, saveAndMoveToAvailableSlide, actionsDisabled }) {
    const slide = useMemo(() => quizForm.slides.find((slide) => slide.id === currentSlide), [currentSlide, quizForm]);

    return (
        <div className={"flex h-full w-full flex-col items-center"}>
            <div className={cn(slide.image ? "grid-cols-1 sm:grid-cols-2" : "grid-cols-1", "grid h-full w-full")}>
                <div className={"col-span-1 flex h-full w-full flex-col justify-center"}>
                    <FieldWrap customStyles={"w-auto animate-fade"}>
                        <InputWrap customStyles={"w-auto p-4"}>
                            <span className={"text-center text-large font-semibold"}>{slide.text}</span>
                        </InputWrap>
                    </FieldWrap>

                    <FieldWrap customStyles={"w-full animate-fade"}>
                        <InputWrap customStyles={"w-auto p-4"}>
                            <MarkdownPreview text={slide.description} />
                        </InputWrap>
                    </FieldWrap>
                </div>
                {slide.image && (
                    <div className={"col-span-1 flex h-full flex-col justify-start sm:justify-center"}>
                        <FieldWrap customStyles={"w-auto animate-fade"}>
                            <InputWrap customStyles={"w-auto p-0"}>
                                <img src={slide.image} alt={""} className={"rounded-md"} />
                            </InputWrap>
                        </FieldWrap>
                    </div>
                )}
                <div className={"col-span-1 flex w-full animate-fade justify-center p-2 sm:col-span-2"}>
                    <Button
                        className={"max-w-md"}
                        disabled={actionsDisabled}
                        onClick={async () => await saveAndMoveToAvailableSlide(slide["id"])}>
                        <span className={"text-wrap"}>Continua</span>
                    </Button>
                </div>
            </div>
        </div>
    );
}
InfoSlide.propTypes = {
    actionsDisabled: PropTypes.bool,
    quizForm: PropTypes.object.isRequired,
    currentSlide: PropTypes.string.isRequired,
    saveAndMoveToAvailableSlide: PropTypes.func.isRequired,
};
