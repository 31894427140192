import { checkAnyErrorForPath, cn, retrieveErrorForPath } from "../../../utils.js";
import TextareaInput from "../../forms/raw-inputs/textarea-input.jsx";
import InputWrap from "../../forms/wraps/input-wrap.jsx";
import FieldWrap from "../../forms/wraps/field-wrap.jsx";
import { Button } from "../../schadcn-ui/button.jsx";
import ErrorSpan from "../../layout/error-span.jsx";
import { Fragment, forwardRef } from "react";
import { FaXmark } from "react-icons/fa6";
import PropTypes from "prop-types";

const NotesDialog = forwardRef(
    ({ isNoteDialogOpened, closeNotesDialog, saveNote, actionsDisabled, changeNote, note, noteErrors }, ref) => {
        return (
            <Fragment>
                {isNoteDialogOpened && (
                    <div className="fixed left-0 top-0 z-50 flex h-screen w-screen animate-fade cursor-default items-center justify-center bg-black/50 backdrop-blur-sm animate-duration-200">
                        <div
                            ref={ref}
                            className="relative flex max-h-[80vh] min-w-[40vw] max-w-[80vw] flex-col overflow-hidden rounded-lg bg-light p-4 shadow-lg">
                            <div className={"relative flex justify-end pb-4"}>
                                <button onClick={() => closeNotesDialog()} className="">
                                    <FaXmark />
                                </button>
                            </div>
                            <div
                                className={
                                    "relative flex h-full max-h-[80vh] w-full min-w-[40vw] max-w-[80vw] select-text flex-col overflow-hidden"
                                }>
                                <FieldWrap customStyles={cn("")}>
                                    <InputWrap
                                        customStyles={cn(
                                            checkAnyErrorForPath("phoneCallNote", noteErrors) ? "border-red-600" : "",
                                            "pb-2",
                                        )}>
                                        <TextareaInput
                                            name={`phoneCallNote`}
                                            value={note || ""}
                                            ref={ref}
                                            handleChange={changeNote}
                                            label={`Notite telefonice`}
                                            customStyles={note ? "font-black" : "sr-only"}
                                            rows={4}
                                        />
                                        <ErrorSpan
                                            text={retrieveErrorForPath("phoneCallNote", noteErrors)}
                                            customStyles={cn(
                                                checkAnyErrorForPath("phoneCallNote", noteErrors) ? "flex" : "hidden",
                                            )}
                                        />
                                    </InputWrap>
                                </FieldWrap>
                                <div className={"flex flex-row justify-between space-x-4 px-2 pt-4"}>
                                    <Button
                                        className={"w-full"}
                                        disabled={actionsDisabled}
                                        onClick={() => closeNotesDialog()}>
                                        Anulare
                                    </Button>
                                    <Button className={"w-full"} disabled={actionsDisabled} onClick={() => saveNote()}>
                                        Salvare
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    },
);
NotesDialog.displayName = "NotesDialog";
NotesDialog.propTypes = {
    note: PropTypes.string.isRequired,
    saveNote: PropTypes.func.isRequired,
    changeNote: PropTypes.func.isRequired,
    noteErrors: PropTypes.object.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
    closeNotesDialog: PropTypes.func.isRequired,
    isNoteDialogOpened: PropTypes.bool.isRequired,
};

export default NotesDialog;
