import { updateFailureToast, updateSuccessToast } from "../../toast.js";
import { useFetchProvider } from "../../providers/provider-hooks.jsx";
import { delay, findChangedPath } from "../../utils.js";
import { Slide, toast } from "react-toastify";
import { useEffect, useState } from "react";
import _ from "lodash";

const DEFAULT_FORM = Object.freeze({ email: "" });

export default function useForgottenPassword() {
    const [logInData, setLogInData] = useState(JSON.parse(JSON.stringify(DEFAULT_FORM)));
    const [oldLogInData, setOldLogInData] = useState(JSON.parse(JSON.stringify(DEFAULT_FORM)));
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const { postPasswordReset } = useFetchProvider();

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedData = { ...logInData };
        updatedData[name] = value;
        setLogInData(updatedData);
    };

    async function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);
        const toastId = toast.loading("Se incarca...", { transition: Slide });
        const successMessage =
            "Daca emailul va este asociat contului, veti primi pe acesta un link de resetare a parolei!";
        await delay(1000);
        if (!logInData.email) {
            updateFailureToast("Nu a fost introdusa o adresa de email.", toastId);
            return setLoading(false);
        }
        try {
            await postPasswordReset(logInData);
        } catch (error) {
            console.error(error);
            if (error.response.data.message || error.response.data.errors) {
                if (error.response.data.errors && Object.keys(error.response.data.errors).length > 0)
                    setErrors(error.response.data.errors);
                if (error.response.data.message) updateFailureToast(error.response.data.message, toastId);
                return;
            }
        }
        updateSuccessToast(successMessage, toastId);
        setLoading(false);
    }

    useEffect(() => {
        const changedPath = findChangedPath(logInData, oldLogInData);
        if (changedPath) {
            const errorPath = Object.keys(errors).find((errorKey) => errorKey === changedPath);
            if (errorPath) {
                const newErrors = JSON.parse(JSON.stringify(errors));
                delete newErrors[errorPath];
                setErrors(newErrors);
            }
            setOldLogInData(_.cloneDeep(logInData));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logInData, errors]);

    return {
        handleSubmit,
        handleChange,
        loading,
        logInData,
        errors,
    };
}
