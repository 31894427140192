import TextInputBox from "../../forms/final-input-boxes/text-input-box.jsx";
import { Button } from "../../schadcn-ui/button.jsx";
import { Fragment, forwardRef } from "react";
import { FaXmark } from "react-icons/fa6";
import PropTypes from "prop-types";

const ApprovalDialog = forwardRef(
    (
        { isDialogOpened, closeRejectDialog, invalidateOrder, actionsDisabled, changeRejectReason, rejectReason },
        ref,
    ) => {
        return (
            <Fragment>
                {isDialogOpened && (
                    <div className="fixed left-0 top-0 z-50 flex h-screen w-screen animate-fade cursor-default items-center justify-center bg-black/50 backdrop-blur-sm animate-duration-200">
                        <div
                            ref={ref}
                            className="relative flex max-h-[80vh] min-w-[40vw] max-w-[80vw] flex-col overflow-hidden rounded-lg bg-light p-4 shadow-lg">
                            <div className={"relative flex justify-end pb-4"}>
                                <button onClick={() => closeRejectDialog()} className="">
                                    <FaXmark />
                                </button>
                            </div>
                            <div
                                className={
                                    "relative flex h-full max-h-[80vh] w-full min-w-[40vw] max-w-[80vw] select-text flex-col overflow-hidden"
                                }>
                                <TextInputBox
                                    handleChange={changeRejectReason}
                                    name={"rejectReason"}
                                    label={"Motiv respingere"}
                                    value={rejectReason}
                                    disabled={actionsDisabled}
                                />
                                <div className={"flex flex-row justify-between space-x-4 px-2 pt-4"}>
                                    <Button
                                        className={"w-full"}
                                        disabled={actionsDisabled}
                                        onClick={() => closeRejectDialog()}>
                                        Anulare
                                    </Button>
                                    <Button
                                        variant={"destructive"}
                                        className={"w-full"}
                                        disabled={actionsDisabled}
                                        onClick={() => invalidateOrder()}>
                                        Respingere
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    },
);
ApprovalDialog.displayName = "ApprovalDialog";
ApprovalDialog.propTypes = {
    rejectReason: PropTypes.string.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
    invalidateOrder: PropTypes.func.isRequired,
    closeRejectDialog: PropTypes.func.isRequired,
    changeRejectReason: PropTypes.func.isRequired,
    isDialogOpened: PropTypes.bool.isRequired,
};

export default ApprovalDialog;
