import { cn } from "../../../utils.js";
import PropTypes from "prop-types";
import { Fragment } from "react";

export default function BooleanInput({ name, value, handleChange, label, customStyles, disabled }) {
    return (
        <Fragment>
            <div className={cn(disabled ? "opacity-50" : "", customStyles, "flex items-center font-semibold")}>
                <div className={"flex flex-col p-2 sm:flex-row"}>
                    <div className={"flex items-center"}>
                        <input
                            type="radio"
                            id={`${name}-true`}
                            name={name}
                            value="true"
                            checked={value === true}
                            disabled={disabled}
                            onChange={() => handleChange({ target: { name, value: true } })}
                            className="mr-2 cursor-pointer disabled:cursor-not-allowed"
                        />
                        <label htmlFor={`${name}-true`} className="mr-4 text-[16px] font-black">
                            Da
                        </label>
                    </div>
                    <div className={"flex items-center"}>
                        <input
                            type="radio"
                            id={`${name}-false`}
                            name={name}
                            value="false"
                            checked={value === false}
                            disabled={disabled}
                            onChange={() => handleChange({ target: { name, value: false } })}
                            className="mr-2 cursor-pointer disabled:cursor-not-allowed"
                        />
                        <label htmlFor={`${name}-false`} className="mr-4 text-[16px] font-black">
                            Nu
                        </label>
                    </div>
                </div>
                <span className="h-full w-full overflow-hidden text-wrap p-2 text-[16px] font-normal">{label}</span>
            </div>
        </Fragment>
    );
}

BooleanInput.propTypes = {
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    customStyles: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
};
