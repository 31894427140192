import PropTypes from "prop-types";
import { cn } from "../../../utils.js";

export default function HeadRow({ customStyles, children }) {
    return <tr className={cn("rounded-t-md transition-colors", customStyles)}>{children}</tr>;
}
HeadRow.propTypes = {
    customStyles: PropTypes.string,
    children: PropTypes.node,
};
