import PropTypes from "prop-types";
import { cn } from "../../utils.js";

export default function BgContentWrap({ children, customStyles }) {
    return (
        <main
            style={{ backgroundSize: "450px" }}
            className="flex w-full flex-grow space-y-4 rounded-md border border-lime-700 bg-light bg-[url('/bg-banner.jpg')] bg-contain bg-center bg-repeat">
            <div className={"h-full w-full rounded-md bg-white bg-opacity-40 p-4"}>
                <div
                    id={"main-scrollable-content"}
                    className={cn("flex h-full w-full flex-col overflow-y-scroll rounded-md pr-4", customStyles)}>
                    {children}
                </div>
            </div>
        </main>
    );
}
BgContentWrap.propTypes = {
    children: PropTypes.node.isRequired,
    customStyles: PropTypes.string,
};
