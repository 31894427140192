import { formatUnixTimestamp } from "../../../utils.js";
import BodyCell from "../tables/body-cell.jsx";
import BodyRow from "../tables/body-row.jsx";
import PropTypes from "prop-types";

export default function TableBody({ tableData }) {
    return (
        <tbody className={"bg-light"}>
            {tableData.map((row, rowIdx) => {
                return (
                    <BodyRow key={`row-${rowIdx}`} customStyles={"hover:bg-lilac/30"}>
                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["isEnabled"] === true ? "DA" : row["isEnabled"] === false ? "NU" : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["timestamp"] ? formatUnixTimestamp(row["timestamp"]) : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["nume"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["email"] || ""}</div>
                        </BodyCell>
                    </BodyRow>
                );
            })}
        </tbody>
    );
}
TableBody.propTypes = {
    tableData: PropTypes.array.isRequired,
};
