import { Button } from "../../schadcn-ui/button.jsx";
import BodyCell from "../tables/body-cell.jsx";
import BodyRow from "../tables/body-row.jsx";
import PropTypes from "prop-types";
import { Fragment } from "react";

export default function TableBody({ tableData, currentEdition, actionsDisabled, openDialogForm }) {
    return (
        <tbody className={"bg-light"}>
            {tableData.map((row, rowIdx) => {
                return (
                    <BodyRow key={`row-${rowIdx}`} customStyles={"hover:bg-lilac/30"}>
                        <BodyCell colSpan={1}>
                            <div className={"flex justify-between space-x-1 font-bold"}>
                                <button
                                    disabled={actionsDisabled}
                                    onClick={() => openDialogForm(row["id"])}
                                    className={
                                        "text-nowrap hover:underline focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:no-underline"
                                    }>
                                    Corecteaza date
                                </button>
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["professor"] &&
                                    row["professor"]["protocolDeColaborare"] &&
                                    Array.isArray(row["professor"]["protocolDeColaborare"]) &&
                                    row["professor"]["protocolDeColaborare"].length > 0 &&
                                    row["professor"]["protocolDeColaborare"].map((photoUrl, photoIdx) => (
                                        <Fragment key={photoIdx}>
                                            <span>{photoIdx !== 0 ? " / " : ""}</span>
                                            <Button
                                                variant={"link"}
                                                className={"px-0"}
                                                onClick={() => window.open(photoUrl, "_blank")}>
                                                Documentul {photoIdx + 1}
                                            </Button>
                                        </Fragment>
                                    ))}
                                {(!row["professor"] ||
                                    !row["professor"]["protocolDeColaborare"] ||
                                    !Array.isArray(row["professor"]["protocolDeColaborare"]) ||
                                    !row["professor"]["protocolDeColaborare"].length > 0) && <span>N/a</span>}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{currentEdition.nume || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["numeInstitutieInvatamant"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["codSiiirUnitate"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["codSe"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["cui"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresa"] && row["adresa"]["judet"] ? row["adresa"]["judet"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresa"] && row["adresa"]["oras"] ? row["adresa"]["oras"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresa"] && row["adresa"]["comuna"] ? row["adresa"]["comuna"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresa"] && row["adresa"]["sat"] ? row["adresa"]["sat"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresa"] && row["adresa"]["strada"] ? row["adresa"]["strada"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresa"] && row["adresa"]["numar"] ? row["adresa"]["numar"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresa"] && row["adresa"]["bloc"] ? row["adresa"]["bloc"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresa"] && row["adresa"]["scara"] ? row["adresa"]["scara"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresa"] && row["adresa"]["etaj"] ? row["adresa"]["etaj"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresa"] && row["adresa"]["apartament"] ? row["adresa"]["apartament"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresa"] && row["adresa"]["codPostal"] ? row["adresa"]["codPostal"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["numeReprezentatLegal"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["telefon"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["numarElevi"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["detineRecipiente"] === true
                                    ? "DA"
                                    : row["detineRecipiente"] === false
                                      ? "NU"
                                      : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresaLivrareAceeasiCuScoala"] === true
                                    ? "DA"
                                    : row["adresaLivrareAceeasiCuScoala"] === false
                                      ? "NU"
                                      : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresaLivrare"] && row["adresaLivrare"]["judet"]
                                    ? row["adresaLivrare"]["judet"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresaLivrare"] && row["adresaLivrare"]["oras"]
                                    ? row["adresaLivrare"]["oras"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresaLivrare"] && row["adresaLivrare"]["comuna"]
                                    ? row["adresaLivrare"]["comuna"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresaLivrare"] && row["adresaLivrare"]["sat"] ? row["adresaLivrare"]["sat"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresaLivrare"] && row["adresaLivrare"]["strada"]
                                    ? row["adresaLivrare"]["strada"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresaLivrare"] && row["adresaLivrare"]["numar"]
                                    ? row["adresaLivrare"]["numar"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresaLivrare"] && row["adresaLivrare"]["bloc"]
                                    ? row["adresaLivrare"]["bloc"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresaLivrare"] && row["adresaLivrare"]["scara"]
                                    ? row["adresaLivrare"]["scara"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresaLivrare"] && row["adresaLivrare"]["etaj"]
                                    ? row["adresaLivrare"]["etaj"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresaLivrare"] && row["adresaLivrare"]["apartament"]
                                    ? row["adresaLivrare"]["apartament"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresaLivrare"] && row["adresaLivrare"]["codPostal"]
                                    ? row["adresaLivrare"]["codPostal"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["participareEditieAnterioara"] === true
                                    ? "DA"
                                    : row["participareEditieAnterioara"] === false
                                      ? "NU"
                                      : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["professor"] && row["professor"]["nume"] ? row["professor"]["nume"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["professor"] && row["professor"]["email"] ? row["professor"]["email"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["professor"] && row["professor"]["telefon"] ? row["professor"]["telefon"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["professor"]
                                    ? row["professor"]["isApproved"] === true
                                        ? "DA"
                                        : row["professor"]["isApproved"] === false
                                          ? "NU"
                                          : ""
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["professor"]
                                    ? row["professor"]["hasSchoolRegistrationFormCompleted"] === true
                                        ? "DA"
                                        : row["professor"]["hasSchoolRegistrationFormCompleted"] === false
                                          ? "NU"
                                          : ""
                                    : ""}
                            </div>
                        </BodyCell>
                    </BodyRow>
                );
            })}
        </tbody>
    );
}
TableBody.propTypes = {
    currentEdition: PropTypes.object,
    tableData: PropTypes.array.isRequired,
    openDialogForm: PropTypes.func.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
};
