import { FaImage } from "react-icons/fa6";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import { cn } from "../../../../../utils.js";

export default function ImageUpload({ changeSlideImage, slide, slideIdx, checkAnyErrorForPath }) {
    const handleImageClick = () => document.getElementById("question-image-upload").click();

    return (
        <div
            onClick={handleImageClick}
            className={cn(
                checkAnyErrorForPath(`slides[${slideIdx}].image`) ? "rounded-t-[5px]" : "rounded-[5px]",
                "group relative flex h-full w-full flex-1 cursor-pointer flex-col items-center justify-center text-center text-magenta/80 transition duration-500 hover:bg-magenta/80 hover:text-white",
            )}>
            <Dropzone onDrop={(acceptedFiles) => changeSlideImage(acceptedFiles[0])}>
                {({ getRootProps, getInputProps }) => (
                    <div className="dz-message needsclick;" {...getRootProps()}>
                        <input id="question-image-upload" {...getInputProps()} />
                    </div>
                )}
            </Dropzone>
            {slide.image ? (
                <img
                    src={slide.image}
                    alt="Selected File"
                    className={cn(
                        checkAnyErrorForPath(`slides[${slideIdx}].image`) ? "rounded-t-[5px]" : "rounded-[5px]",
                        "flex aspect-[16/9] h-full w-full cursor-pointer object-contain",
                    )}
                />
            ) : (
                <div className={"flex flex-col items-center space-x-4 p-4"}>
                    <FaImage style={{ fontSize: "2.3em" }} />
                    <p className="m-0 text-lg font-semibold text-gray-400 transition duration-500 group-hover:text-white">
                        Atasati o poza acestei intrebari?
                    </p>
                </div>
            )}
        </div>
    );
}

ImageUpload.propTypes = {
    checkAnyErrorForPath: PropTypes.func.isRequired,
    changeSlideImage: PropTypes.func.isRequired,
    slide: PropTypes.object.isRequired,
    slideIdx: PropTypes.number.isRequired,
};
