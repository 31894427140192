import { APPLICATION_REPORTS, useTableExports } from "./use-table-exports.js";
import { useFetchProvider } from "../../providers/provider-hooks.jsx";
import { toastOneWarn, updateFailureToast } from "../../toast.js";
import { delay, findChangedPath, setValueAtPath } from "../../utils.js";
import useTableParams from "./use-table-params.js";
import { useEffect, useRef, useState } from "react";
import { useEditions } from "../use-editions.js";
import { Slide, toast } from "react-toastify";
import _ from "lodash";

const DEFAULT_LOGISTICS_DATA = Object.freeze({
    id: "",
    realDeeeKg: "",
    realDbaKg: "",
    realBecuriSiNeoaneKg: "",
    proceseVerbale: [],
});

export default function useOrdersReport() {
    const [logisticsData, setLogisticsData] = useState(JSON.parse(JSON.stringify(DEFAULT_LOGISTICS_DATA)));
    const [isLogisticsDialogOpened, setIsLogisticsDialogOpened] = useState(false);
    const [isOrderDialogOpened, setIsOrderDialogOpened] = useState(false);
    const [orderCorrectionErrors, setOrderCorrectionErrors] = useState({});
    const [actionsDisabled, setActionsDisabled] = useState(false);
    const [isDialogOpened, setIsDialogOpened] = useState(false);
    const [oldLogisticsData, setOldLogisticsData] = useState({});
    const [oldOrderFormData, setOldOrderFormData] = useState({});
    const [logisticsErrors, setLogisticsErrors] = useState({});
    const [elementInDialog, setElementInDialog] = useState(null);
    const [rejectReason, setRejectReason] = useState("");
    const [orderFormData, setOrderFormData] = useState({});
    const [ownSchoolData, setOwnSchoolData] = useState({});
    const [tableData, setTableData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [counties, setCounties] = useState([]);

    const { params, changeParams, setParams } = useTableParams(renderOrdersReport, { page: 0 });
    const { exportTable } = useTableExports(APPLICATION_REPORTS.ORDERS, params, setActionsDisabled);
    const { editions, currentEdition, loadEditions } = useEditions(params, setParams);
    const logisticsDialogRef = useRef(null);
    const orderDialogRef = useRef(null);
    const dialogRef = useRef(null);
    const printRef = useRef(null);
    const {
        getCounties,
        uploadImage,
        getOrderById,
        getSchoolById,
        getOrdersReport,
        patchLogisticsOrderData,
        patchWasteFormValidation,
        patchWasteCollectionForm,
    } = useFetchProvider();

    function handleOrderStandardFieldChange(e) {
        const { name, value } = e.target;
        const updatedData = { ...orderFormData };
        if (name === "pickupAddressSameWithSchoolAddress" && value === true) {
            updatedData["pickupAddress"]["judet"] = ownSchoolData?.["adresa"]?.["judet"];
            updatedData["pickupAddress"]["oras"] = ownSchoolData?.["adresa"]?.["oras"];
            updatedData["pickupAddress"]["comuna"] = ownSchoolData?.["adresa"]?.["comuna"];
            updatedData["pickupAddress"]["sat"] = ownSchoolData?.["adresa"]?.["sat"];
            updatedData["pickupAddress"]["strada"] = ownSchoolData?.["adresa"]?.["strada"];
            updatedData["pickupAddress"]["numar"] = ownSchoolData?.["adresa"]?.["numar"];
            updatedData["pickupAddress"]["bloc"] = ownSchoolData?.["adresa"]?.["bloc"];
            updatedData["pickupAddress"]["scara"] = ownSchoolData?.["adresa"]?.["scara"];
            updatedData["pickupAddress"]["etaj"] = ownSchoolData?.["adresa"]?.["etaj"];
            updatedData["pickupAddress"]["codPostal"] = ownSchoolData?.["adresa"]?.["codPostal"];
        }
        console.log(updatedData);
        updatedData[name] = value;
        setOrderFormData(updatedData);
    }

    function handleOrderAddressFieldChange(e) {
        const { name, value } = e.target;
        const updatedData = { ...orderFormData };
        setValueAtPath(updatedData, name, value);
        setOrderFormData(updatedData);
    }

    function closeOrderDialogForm() {
        setOrderFormData({});
        setOldOrderFormData({});
        setIsOrderDialogOpened(false);
    }

    async function openOrderDialogForm(orderId, schoolId) {
        setActionsDisabled(true);
        const errorMessage = "Comanda nu s-a putut deschide.";
        const toastId = toast.loading("Incarcam comanda utilizatorului...", { transition: Slide });

        try {
            await delay(1000);
            const dialogData = await getOrderById(orderId);
            const schoolData = await getSchoolById(schoolId);
            const newCounties = await getCounties();
            if (newCounties.length > 0) {
                setCounties(newCounties.map((county) => ({ value: county })));
            }
            setOwnSchoolData(schoolData);
            setOrderFormData(dialogData);
            setOldOrderFormData(dialogData);
            setIsOrderDialogOpened(true);
            toast.dismiss(toastId);
        } catch (error) {
            updateFailureToast(error.message || errorMessage, toastId);
        } finally {
            setActionsDisabled(false);
        }
    }

    async function saveOrderDialogForm() {
        setActionsDisabled(true);
        const toastId = toast.loading("Informatiile se salveaza...", { transition: Slide });
        try {
            await delay(1000);
            await patchWasteCollectionForm(orderFormData);
            toast.dismiss(toastId);
            closeOrderDialogForm();
            await renderOrdersReport();
        } catch (error) {
            console.log(error);
            if (error.message || error.errors) {
                if (error.errors && Object.keys(error.errors).length > 0) setOrderCorrectionErrors(error.errors);
                if (error.message) updateFailureToast(error.message, toastId);
                return;
            }
            updateFailureToast("Am intampinat o problema. Contactati echipa tehnica.", toastId);
        } finally {
            setActionsDisabled(false);
        }
    }

    async function handleImageUpload(file) {
        if (file) {
            try {
                setActionsDisabled(true);
                const uploadedPhoto = await uploadImage(file);
                setLogisticsData((prevState) => ({
                    ...prevState,
                    proceseVerbale: [...prevState.proceseVerbale, uploadedPhoto],
                }));
            } catch (error) {
                console.error(error);
            } finally {
                setActionsDisabled(false);
            }
        }
    }

    function removePhoto(index) {
        setLogisticsData((prevState) => ({
            ...prevState,
            proceseVerbale: prevState.proceseVerbale.filter((_, i) => i !== index),
        }));
    }

    async function changeLogisticsData(e) {
        const { name, value } = e.target;
        const updatedData = { ...logisticsData };
        updatedData[name] = value;
        setLogisticsData(updatedData);
    }

    function openLogisticsDialog(order) {
        setOldLogisticsData({});
        setLogisticsData({
            realDeeeKg: order["realDeeeKg"],
            realDbaKg: order["realDbaKg"],
            realBecuriSiNeoaneKg: order["realBecuriSiNeoaneKg"],
            proceseVerbale: order["proceseVerbale"],
            id: order["id"],
        });
        setIsLogisticsDialogOpened(true);
        setLogisticsErrors({});
    }

    async function saveLogisticsData() {
        setActionsDisabled(true);
        const toastId = toast.loading("Informatiile se salveaza...", { transition: Slide });
        try {
            await delay(1000);
            const { id, ...params } = logisticsData;
            await patchLogisticsOrderData(id, params);
            toast.dismiss(toastId);
            closeLogisticsDialog();
            await renderOrdersReport();
        } catch (error) {
            console.log(error);
            if (error.message || error.errors) {
                if (error.errors && Object.keys(error.errors).length > 0) setLogisticsErrors(error.errors);
                if (error.message) updateFailureToast(error.message, toastId);
                return;
            }
            updateFailureToast("Am intampinat o problema. Contactati echipa tehnica.", toastId);
        } finally {
            setActionsDisabled(false);
        }
    }

    function closeLogisticsDialog() {
        setIsLogisticsDialogOpened(false);
        setOldLogisticsData({});
        setLogisticsData(JSON.parse(JSON.stringify(DEFAULT_LOGISTICS_DATA)));
        setLogisticsErrors({});
    }

    function closeRejectDialog() {
        setIsDialogOpened(false);
        setElementInDialog(null);
        setRejectReason("");
    }

    function openRejectDialog(orderId) {
        setIsDialogOpened(true);
        setElementInDialog(orderId);
    }

    const changeRejectReason = (e) => setRejectReason(e.target.value);

    async function invalidateOrder() {
        setActionsDisabled(true);
        if (!rejectReason) {
            toastOneWarn("Motivul respingerii acestei comenzi nu a fost mentionat.");
            return setActionsDisabled(false);
        }
        try {
            await validateOrder(elementInDialog, false, rejectReason);
            closeRejectDialog();
        } catch (error) {
            console.error(error);
        } finally {
            setActionsDisabled(false);
        }
    }

    async function validateOrder(orderId, isValidated, rejectReason) {
        setActionsDisabled(true);
        let params = {};
        const errorMessage = "Aceasta comanda nu s-a putut valida.";
        const toastId = toast.loading("Operatiunea se proceseaza...", { transition: Slide });

        try {
            await delay(1000);
            if (![undefined, null].includes(isValidated) && typeof isValidated === "boolean")
                params.isValidated = isValidated;
            if (![undefined, null, ""].includes(rejectReason) && typeof rejectReason === "string")
                params.rejectReason = rejectReason;
            await patchWasteFormValidation(orderId, params);
            toast.dismiss(toastId);
        } catch (error) {
            console.error(error);
            updateFailureToast(error.message || errorMessage, toastId);
        } finally {
            await renderOrdersReport();
            setActionsDisabled(false);
        }
    }

    async function renderOrdersReport(freshParams) {
        setActionsDisabled(true);
        const errorMessage = "Tabelul nu s-a putut incarca.";
        const id = toast.loading("Tabelul se incarca...", { transition: Slide });

        try {
            await delay(1000);
            const data = await getOrdersReport(freshParams || params);
            setTableData(data.content);
            setPagination({
                totalPages: data.totalPages,
                totalElements: data.totalElements,
                ...data["pageable"],
                first: data.first,
                last: data.last,
            });
            toast.dismiss(id);
        } catch (error) {
            console.error(error);
            updateFailureToast(error.message || errorMessage, id);
        } finally {
            setActionsDisabled(false);
        }
    }

    async function resetFilters() {
        setParams({ page: params.page, editionId: params.editionId });
        await renderOrdersReport({ page: params.page, editionId: params.editionId });
    }

    useEffect(() => {
        loadEditions().then((res) => renderOrdersReport(res ? { ...params, ...res } : { ...params }).then());
        const handleClickOutside = (event) =>
            dialogRef.current && !dialogRef.current.contains(event.target) ? closeRejectDialog() : null;
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isLogisticsDialogOpened) return;
        const changedPath = findChangedPath(logisticsData, oldLogisticsData);
        if (changedPath) {
            const errorPath = Object.keys(logisticsErrors).find((errorKey) => errorKey === changedPath);
            if (errorPath) {
                const newErrors = JSON.parse(JSON.stringify(logisticsErrors));
                delete newErrors[errorPath];
                setLogisticsErrors(newErrors);
            }
            setOldLogisticsData(_.cloneDeep(logisticsData));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logisticsData, logisticsErrors]);

    useEffect(() => {
        const changedPath = findChangedPath(orderFormData, oldOrderFormData);
        if (changedPath) {
            const errorPath = Object.keys(orderCorrectionErrors).find((errorKey) => errorKey === changedPath);
            if (errorPath) {
                const newErrors = JSON.parse(JSON.stringify(orderCorrectionErrors));
                delete newErrors[errorPath];
                setOrderCorrectionErrors(newErrors);
            }
            setOldOrderFormData(_.cloneDeep(orderFormData));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderFormData, orderCorrectionErrors]);

    return {
        params,
        editions,
        printRef,
        counties,
        tableData,
        dialogRef,
        pagination,
        exportTable,
        removePhoto,
        changeParams,
        rejectReason,
        resetFilters,
        orderFormData,
        logisticsData,
        validateOrder,
        currentEdition,
        isDialogOpened,
        orderDialogRef,
        logisticsErrors,
        actionsDisabled,
        invalidateOrder,
        openRejectDialog,
        saveLogisticsData,
        closeRejectDialog,
        handleImageUpload,
        changeRejectReason,
        renderOrdersReport,
        logisticsDialogRef,
        changeLogisticsData,
        openLogisticsDialog,
        isOrderDialogOpened,
        openOrderDialogForm,
        saveOrderDialogForm,
        closeOrderDialogForm,
        closeLogisticsDialog,
        orderCorrectionErrors,
        isLogisticsDialogOpened,
        handleOrderAddressFieldChange,
        handleOrderStandardFieldChange,
    };
}
