import PropTypes from "prop-types";

export default function PreviewTitle({ title, titlePlaceholder }) {
    return (
        <div className="min-h-[17px] w-full truncate p-1 text-center font-semibold">{title || titlePlaceholder}</div>
    );
}
PreviewTitle.propTypes = {
    title: PropTypes.string,
    titlePlaceholder: PropTypes.string.isRequired,
};
