import PropTypes from "prop-types";
import { cn } from "../../../utils.js";

export default function InputWrap({ children, customStyles }) {
    return (
        <div
            className={cn(
                "flex w-full flex-col rounded-lg border-[3px] border-gray-300 bg-white px-2 shadow-2xl transition-colors duration-300 focus-within:border-gray-400",
                customStyles,
            )}>
            {children}
        </div>
    );
}
InputWrap.propTypes = {
    children: PropTypes.node.isRequired,
    customStyles: PropTypes.string,
};
