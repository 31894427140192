import BasePageWrap from "../layout/base-page-wrap.jsx";
import PropTypes from "prop-types";

export default function PageWrap({ children }) {
    return (
        <BasePageWrap customStyles="max-h-screen overflow-hidden">
            <div
                className={
                    "bg-[url('/thumbnail_interfata.jpg')] bg-cover bg-top md:bg-[url('/thumbnail_interfata_desktop.jpg')] md:bg-right"
                }>
                <div className="flex min-h-screen flex-col items-center justify-center px-4 py-6">
                    <div className="w-full max-w-md">{children}</div>
                </div>
            </div>
        </BasePageWrap>
    );
}
PageWrap.propTypes = {
    children: PropTypes.node.isRequired,
};
