import { Drawer, DrawerContent, DrawerDescription, DrawerTitle, DrawerTrigger } from "../schadcn-ui/drawer.jsx";
import useIsDesktopViewPort from "../../hooks/use-is-desktop-viewport.js";
import { FaCaretRight } from "react-icons/fa6";
import SideMenu from "./side-menu.jsx";
import TopNavBar from "./top-nav-bar.jsx";
import PropTypes from "prop-types";
import { cn } from "../../utils.js";
import { useState } from "react";

export default function NavBarWrap({ children, withoutSideMenu = false, withoutNav = false }) {
    const { isDesktopViewPort } = useIsDesktopViewPort();
    const [navVisible, setNavVisible] = useState(false);

    const closeNav = () => setNavVisible(false);

    return (
        <div className="flex h-screen max-h-screen min-h-screen w-full min-w-screen max-w-screen flex-col overflow-hidden p-4">
            {!withoutNav && <TopNavBar />}
            <div className={cn("relative flex h-full w-full overflow-hidden")}>
                {isDesktopViewPort && !withoutSideMenu && (
                    <div
                        style={{ backgroundSize: "400px" }}
                        className="mr-4 flex w-[250px] min-w-[250px] max-w-[250px] rounded-md border border-lime-700 bg-[url('/bg-banner.jpg')] bg-repeat focus:border-0 focus:outline-none">
                        <div className={"h-full w-full rounded-md bg-white bg-opacity-30 py-4 pl-4"}>
                            <SideMenu navVisible={navVisible} closeNav={closeNav} />
                        </div>
                    </div>
                )}
                {!isDesktopViewPort && !withoutSideMenu && (
                    <Drawer open={navVisible} onOpenChange={setNavVisible} direction="left">
                        <DrawerTrigger asChild>
                            <button
                                className={
                                    "fixed left-0 flex h-[70px] w-[30px] cursor-pointer items-center justify-center self-center rounded-r-2xl border bg-dark/80 duration-300 hover:border-magenta/80 hover:bg-magenta/80 hover:text-light"
                                }>
                                <FaCaretRight className={"-ml-1 h-full text-large"} />
                            </button>
                        </DrawerTrigger>
                        <DrawerContent
                            style={{ backgroundSize: "400px" }}
                            className="h-full w-[250px] min-w-[250px] rounded-none border-none bg-[url('/bg-banner.jpg')] bg-contain bg-center bg-repeat focus:border-0 focus:outline-none">
                            <DrawerTitle className={"sr-only"}>Interfata Test</DrawerTitle>
                            <DrawerDescription className={"sr-only"}>Interfata Test</DrawerDescription>
                            <div className={"h-full bg-white bg-opacity-30 bg-clip-border p-4"}>
                                <SideMenu navVisible={navVisible} closeNav={closeNav} />
                            </div>
                        </DrawerContent>
                    </Drawer>
                )}
                {children}
            </div>
        </div>
    );
}
NavBarWrap.propTypes = {
    children: PropTypes.node.isRequired,
    withoutSideMenu: PropTypes.bool,
    withoutNav: PropTypes.bool,
};
