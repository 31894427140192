import { Drawer as DrawerPrimitive } from "vaul";
import { forwardRef } from "react";
import { cn } from "../../utils.js";
import PropTypes from "prop-types";

const Drawer = ({ shouldScaleBackground = true, ...props }) => (
    <DrawerPrimitive.Root shouldScaleBackground={shouldScaleBackground} {...props} />
);
Drawer.displayName = "Drawer";
Drawer.propTypes = {
    shouldScaleBackground: PropTypes.bool,
};

const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerPortal = DrawerPrimitive.Portal;

const DrawerClose = DrawerPrimitive.Close;

const DrawerOverlay = forwardRef(({ className, ...props }, ref) => (
    <DrawerPrimitive.Overlay ref={ref} className={cn("fixed inset-0 z-50 bg-dark/80", className)} {...props} />
));
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;
DrawerOverlay.propTypes = {
    className: PropTypes.string,
};

const DrawerContent = forwardRef(({ className, children, ...props }, ref) => (
    <DrawerPortal>
        <DrawerOverlay />
        <DrawerPrimitive.Content
            ref={ref}
            className={cn(
                "fixed inset-x-0 bottom-0 z-50 mt-24 flex h-auto flex-col rounded-t-[10px] border border-dark bg-light",
                className,
            )}
            {...props}>
            {children}
        </DrawerPrimitive.Content>
    </DrawerPortal>
));
DrawerContent.displayName = "DrawerContent";
DrawerContent.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

const DrawerHeader = ({ className, ...props }) => (
    <div className={cn("grid gap-1.5 p-4 text-center sm:text-left", className)} {...props} />
);
DrawerHeader.displayName = "DrawerHeader";
DrawerHeader.propTypes = {
    className: PropTypes.string,
};

const DrawerFooter = ({ className, ...props }) => (
    <div className={cn("mt-auto flex flex-col gap-2 p-4", className)} {...props} />
);
DrawerFooter.displayName = "DrawerFooter";
DrawerFooter.propTypes = {
    className: PropTypes.string,
};

const DrawerTitle = forwardRef(({ className, ...props }, ref) => (
    <DrawerPrimitive.Title
        ref={ref}
        className={cn("text-large font-semibold leading-none tracking-tight", className)}
        {...props}
    />
));
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;
DrawerTitle.propTypes = {
    className: PropTypes.string,
};

const DrawerDescription = forwardRef(({ className, ...props }, ref) => (
    <DrawerPrimitive.Description ref={ref} className={cn("text-small", className)} {...props} />
));
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;
DrawerDescription.propTypes = {
    className: PropTypes.string,
};

export {
    Drawer,
    DrawerPortal,
    DrawerOverlay,
    DrawerTrigger,
    DrawerFooter,
    DrawerTitle,
    DrawerDescription,
    DrawerClose,
    DrawerContent,
    DrawerHeader,
};
