import TextInputBox from "../../forms/final-input-boxes/text-input-box.jsx";
import { Button } from "../../schadcn-ui/button.jsx";
import { Fragment, forwardRef } from "react";
import { FaXmark } from "react-icons/fa6";
import PropTypes from "prop-types";

const LogisticsDialog = forwardRef(
    (
        {
            isLogisticsDialogOpened,
            closeLogisticsDialog,
            sendLogisticsData,
            actionsDisabled,
            changeLogisticsData,
            logisticsData,
            logisticsErrors,
        },
        ref,
    ) => {
        return (
            <Fragment>
                {isLogisticsDialogOpened && (
                    <div className="fixed left-0 top-0 z-50 flex h-screen w-screen animate-fade cursor-default items-center justify-center bg-black/50 backdrop-blur-sm animate-duration-200">
                        <div
                            ref={ref}
                            className="relative flex max-h-[80vh] min-w-[40vw] max-w-[80vw] flex-col overflow-hidden rounded-lg bg-light p-4 shadow-lg">
                            <div className={"relative flex justify-end pb-4"}>
                                <button onClick={() => closeLogisticsDialog()} className="">
                                    <FaXmark />
                                </button>
                            </div>
                            <div
                                className={
                                    "relative flex h-full max-h-[80vh] w-full min-w-[40vw] max-w-[80vw] select-text flex-col overflow-hidden"
                                }>
                                <TextInputBox
                                    handleChange={changeLogisticsData}
                                    name={"realDeeeKg"}
                                    label={"Kg DEEE confirmate"}
                                    value={logisticsData["realDeeeKg"] || ""}
                                    disabled={actionsDisabled}
                                    errors={logisticsErrors}
                                />
                                <TextInputBox
                                    handleChange={changeLogisticsData}
                                    name={"realDbaKg"}
                                    label={"Kg DBA confirmate"}
                                    value={logisticsData["realDbaKg"] || ""}
                                    disabled={actionsDisabled}
                                    errors={logisticsErrors}
                                />
                                <TextInputBox
                                    handleChange={changeLogisticsData}
                                    name={"realBecuriSiNeoaneKg"}
                                    label={"Kg becuri si neoane confirmate"}
                                    value={logisticsData["realBecuriSiNeoaneKg"] || ""}
                                    disabled={actionsDisabled}
                                    errors={logisticsErrors}
                                />

                                <div className={"flex px-2 pt-4 font-bold text-black"}>
                                    Atentie! Aceste modificari nu se vor sincroniza cu datele din ERP. Asigurati-va de
                                    fiecare data ca noile date introduse se regasesc in ERP.
                                </div>

                                <div className={"flex flex-row justify-between space-x-4 px-2 pt-4"}>
                                    <Button
                                        variant={"destructive"}
                                        className={"w-full"}
                                        disabled={actionsDisabled}
                                        onClick={() => closeLogisticsDialog()}>
                                        Anulare
                                    </Button>
                                    <Button
                                        className={"w-full"}
                                        disabled={actionsDisabled}
                                        onClick={() => sendLogisticsData()}>
                                        Salvare
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    },
);
LogisticsDialog.displayName = "LogisticsDialog";
LogisticsDialog.propTypes = {
    removePhoto: PropTypes.func.isRequired,
    logisticsData: PropTypes.object.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
    logisticsErrors: PropTypes.object.isRequired,
    sendLogisticsData: PropTypes.func.isRequired,
    handleImageUpload: PropTypes.func.isRequired,
    changeLogisticsData: PropTypes.func.isRequired,
    closeLogisticsDialog: PropTypes.func.isRequired,
    isLogisticsDialogOpened: PropTypes.bool.isRequired,
};

export default LogisticsDialog;
