import TextareaInput from "../../../forms/raw-inputs/textarea-input.jsx";
import FieldWrap from "../../../forms/wraps/field-wrap.jsx";
import InputWrap from "../../../forms/wraps/input-wrap.jsx";
import { Button } from "../../../schadcn-ui/button.jsx";
import { useLocation } from "react-router-dom";
import { cn } from "../../../../utils.js";
import PropTypes from "prop-types";
import { useMemo } from "react";

export default function TextSlide({
    quizForm,
    currentSlide,
    saveAndMoveToAvailableSlide,
    currentAnswer,
    handleTextChange,
    actionsDisabled,
}) {
    const slide = useMemo(() => quizForm.slides.find((slide) => slide.id === currentSlide), [currentSlide, quizForm]);
    const location = useLocation();

    return (
        <div className={"my-auto flex flex-col items-center"}>
            <FieldWrap customStyles={"w-auto max-w-md animate-fade"}>
                <InputWrap customStyles={"w-auto p-4"}>
                    <span className={"text-center text-large font-semibold"}>{slide.text}</span>
                </InputWrap>
            </FieldWrap>

            <FieldWrap
                customStyles={cn(
                    location.pathname.includes("preview") ? "cursor-not-allowed" : "",
                    "w-full sm:w-[500px] lg:w-[700px]",
                )}>
                <InputWrap customStyles={"pr-4 pb-4"}>
                    <span className={"w-full text-wrap text-center font-black text-gray-400"}>
                        Continutul Compunerii (Obligatoriu)
                    </span>
                    <span className={"w-full text-wrap pb-1 text-center font-black text-gray-400"}>
                        Caractere:{" "}
                        <span
                            className={cn(
                                currentAnswer.text && currentAnswer.text.length >= 5000 ? "text-red-500" : "",
                            )}>
                            {currentAnswer.text ? currentAnswer.text.length : 0} / 5000
                        </span>
                    </span>
                    <TextareaInput
                        name={`text-${slide["id"]}`}
                        value={currentAnswer.text || ""}
                        rows={6}
                        handleChange={(e) => handleTextChange(e.target.value)}
                        label={``}
                        customStyles={"sr-only"}
                        inputStyles={"text-start"}
                        disabled={location.pathname.includes("preview")}
                    />
                </InputWrap>
            </FieldWrap>

            <div className={"flex animate-fade justify-center p-2"}>
                <Button
                    className={"h-auto max-w-md"}
                    disabled={actionsDisabled}
                    onClick={() => saveAndMoveToAvailableSlide(slide["id"])}>
                    <span className={"text-wrap"}>
                        {!location.pathname.includes("preview") ? "Salvare" : "Continuare"}
                    </span>
                </Button>
            </div>
        </div>
    );
}
TextSlide.propTypes = {
    actionsDisabled: PropTypes.bool,
    quizForm: PropTypes.object.isRequired,
    currentSlide: PropTypes.string.isRequired,
    currentAnswer: PropTypes.object.isRequired,
    handleTextChange: PropTypes.func.isRequired,
    saveAndMoveToAvailableSlide: PropTypes.func.isRequired,
};
