import {
    MDXEditor,
    linkPlugin,
    listsPlugin,
    quotePlugin,
    tablePlugin,
    headingsPlugin,
    linkDialogPlugin,
    diffSourcePlugin,
    frontmatterPlugin,
    markdownShortcutPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import PropTypes from "prop-types";
import { cn, generateUUID } from "../../../utils.js";

export default function MarkdownPreview({ text }) {
    return (
        <MDXEditor
            disabled={true}
            readOnly={true}
            spellCheck={false}
            markdown={text || ""}
            onChange={() => true}
            key={generateUUID()}
            plugins={[
                linkPlugin(),
                listsPlugin(),
                quotePlugin(),
                tablePlugin(),
                headingsPlugin(),
                linkDialogPlugin(),
                markdownShortcutPlugin(),
                diffSourcePlugin({ diffMarkdown: "An older version", viewMode: "rich-text" }),
                frontmatterPlugin(),
            ]}
            contentEditableClassName={cn(!text ? "" : "", "p-0 markdown p-0 rounded-md font-roboto-mono")}
        />
    );
}
MarkdownPreview.propTypes = {
    text: PropTypes.string,
};
