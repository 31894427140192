import BooleanFilterInput from "../tables/boolean-filter-input.jsx";
import SearchInput from "../tables/search-input.jsx";
import SortButton from "../tables/sort-button.jsx";
import HeadCell from "../tables/head-cell.jsx";
import HeadRow from "../tables/head-row.jsx";
import { cn } from "../../../utils.js";
import PropTypes from "prop-types";

export default function TableHeaders({ actionsDisabled, params, changeParams }) {
    return (
        <thead className={cn("sticky top-0 z-10 bg-light font-bold")}>
            <HeadRow>
                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <span className={"text-nowrap"}>Actiuni</span>
                    </div>
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"professor.protocolDeColaborare"}
                        />
                        <span className={"text-nowrap"}>Protocol de colaborare</span>
                    </div>
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["editionId"] || ""}
                            name={"editionId"}
                        />
                        <span className={"text-nowrap"}>Editia</span>
                    </div>
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"numeInstitutieInvatamant"}
                        />
                        <span className={"text-nowrap"}>Denumire institutie invatamant</span>
                    </div>
                    <SearchInput
                        placeholder={"Denumire institutie invatamant..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"numeInstitutieInvatamant"}
                        value={params["numeInstitutieInvatamant"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"codSiiirUnitate"}
                        />
                        <span className={"text-nowrap"}>Cod SIIIR</span>
                    </div>
                    <SearchInput
                        type={"number"}
                        name={"codSiiirUnitate"}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        placeholder={"Cod SIIIR..."}
                        value={params["codSiiirUnitate"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"codSeNumeric"}
                        />
                        <span className={"text-nowrap"}>Cod SE</span>
                    </div>
                    <SearchInput
                        placeholder={"Cod SE..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"codSe"}
                        value={params["codSe"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"cui"}
                        />
                        <span className={"text-nowrap"}>C.U.I.</span>
                    </div>
                    <SearchInput
                        placeholder={"C.U.I...."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"cui"}
                        value={params["cui"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresa.judet"}
                        />
                        <span className={"text-nowrap"}>Judet adresa</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Judet adresa..."}
                        name={`adresa.judet`}
                        value={params[`adresa.judet`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresa.oras"}
                        />
                        <span className={"text-nowrap"}>Oras adresa</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Oras adresa..."}
                        name={`adresa.oras`}
                        value={params[`adresa.oras`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresa.comuna"}
                        />
                        <span className={"text-nowrap"}>Comuna adresa</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Comuna adresa..."}
                        name={`adresa.comuna`}
                        value={params[`adresa.comuna`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresa.sat"}
                        />
                        <span className={"text-nowrap"}>Sat adresa</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Sat adresa..."}
                        name={`adresa.sat`}
                        value={params[`adresa.sat`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresa.strada"}
                        />
                        <span className={"text-nowrap"}>Strada adresa</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Strada adresa..."}
                        name={`adresa.strada`}
                        value={params[`adresa.strada`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresa.numar"}
                        />
                        <span className={"text-nowrap"}>Numar adresa</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Numar adresa..."}
                        name={`adresa.numar`}
                        value={params[`adresa.numar`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresa.bloc"}
                        />
                        <span className={"text-nowrap"}>Bloc</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Bloc..."}
                        name={`adresa.bloc`}
                        value={params[`adresa.bloc`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresa.scara"}
                        />
                        <span className={"text-nowrap"}>Scara bloc</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Scara bloc..."}
                        name={`adresa.scara`}
                        value={params[`adresa.scara`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresa.etaj"}
                        />
                        <span className={"text-nowrap"}>Etaj</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Etaj..."}
                        name={`adresa.etaj`}
                        value={params[`adresa.etaj`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresa.apartament"}
                        />
                        <span className={"text-nowrap"}>Apartament</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Apartament..."}
                        name={`adresa.apartament`}
                        value={params[`adresa.apartament`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresa.codPostal"}
                        />
                        <span className={"text-nowrap"}>Cod postal</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Cod postal..."}
                        name={`adresa.codPostal`}
                        value={params[`adresa.codPostal`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"numeReprezentatLegal"}
                        />
                        <span className={"text-nowrap"}>Nume reprezentant legal</span>
                    </div>
                    <SearchInput
                        placeholder={"Nume reprezentant legal..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"numeReprezentatLegal"}
                        value={params["numeReprezentatLegal"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"telefon"}
                        />
                        <span className={"text-nowrap"}>Telefon</span>
                    </div>
                    <SearchInput
                        placeholder={"Telefon..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"telefon"}
                        value={params["telefon"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"numarElevi"}
                        />
                        <span className={"text-nowrap"}>Numar elevi</span>
                    </div>
                    <SearchInput
                        placeholder={"Numar elevi..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"numarElevi"}
                        value={params["numarElevi"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"detineRecipiente"}
                        />
                        <span className={"text-nowrap"}>Detine recipiente</span>
                    </div>
                    <BooleanFilterInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        name={`detineRecipiente`}
                        value={params[`detineRecipiente`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresaLivrareAceeasiCuScoala"}
                        />
                        <span className={"text-nowrap"}>Adresa de livrare aceeasi cu scoala</span>
                    </div>
                    <BooleanFilterInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        name={`adresaLivrareAceeasiCuScoala`}
                        value={params[`adresaLivrareAceeasiCuScoala`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresaLivrare.judet"}
                        />
                        <span className={"text-nowrap"}>Judet adresa livrare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Judet adresa livrare..."}
                        name={`adresaLivrare.judet`}
                        value={params[`adresaLivrare.judet`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresaLivrare.oras"}
                        />
                        <span className={"text-nowrap"}>Oras adresa livrare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Oras adresa livrare..."}
                        name={`adresaLivrare.oras`}
                        value={params[`adresaLivrare.oras`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresaLivrare.comuna"}
                        />
                        <span className={"text-nowrap"}>Comuna adresa livrare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Comuna adresa livrare..."}
                        name={`adresaLivrare.comuna`}
                        value={params[`adresaLivrare.comuna`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresaLivrare.sat"}
                        />
                        <span className={"text-nowrap"}>Sat adresa livrare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Sat adresa livrare..."}
                        name={`adresaLivrare.sat`}
                        value={params[`adresaLivrare.sat`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresaLivrare.strada"}
                        />
                        <span className={"text-nowrap"}>Strada adresa livrare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Strada adresa livrare..."}
                        name={`adresaLivrare.strada`}
                        value={params[`adresaLivrare.strada`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresaLivrare.numar"}
                        />
                        <span className={"text-nowrap"}>Numar adresa livrare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Numar strada adresa livrare..."}
                        name={`adresaLivrare.numar`}
                        value={params[`adresaLivrare.numar`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresaLivrare.bloc"}
                        />
                        <span className={"text-nowrap"}>Bloc adresa livrare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Bloc adresa livrare..."}
                        name={`adresaLivrare.bloc`}
                        value={params[`adresaLivrare.bloc`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresaLivrare.scara"}
                        />
                        <span className={"text-nowrap"}>Scara blocului adresei de livrare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Scara blocului adresei de livrare..."}
                        name={`adresaLivrare.scara`}
                        value={params[`adresaLivrare.scara`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresaLivrare.etaj"}
                        />
                        <span className={"text-nowrap"}>Etajul blocului adresei de livrare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Etajul blocului adresei de livrare..."}
                        name={`adresaLivrare.etaj`}
                        value={params[`adresaLivrare.etaj`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresaLivrare.apartament"}
                        />
                        <span className={"text-nowrap"}>Apartamentul adresei de livrare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Apartamentul adresei de livrare..."}
                        name={`adresaLivrare.apartament`}
                        value={params[`adresaLivrare.apartament`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresaLivrare.codPostal"}
                        />
                        <span className={"text-nowrap"}>Cod postal adresa livrare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Cod postal adresa livrare..."}
                        name={`adresaLivrare.codPostal`}
                        value={params[`adresaLivrare.codPostal`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"participareEditieAnterioara"}
                        />
                        <span className={"text-nowrap"}>Participare editie anterioara</span>
                    </div>
                    <BooleanFilterInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        name={`participareEditieAnterioara`}
                        value={params[`participareEditieAnterioara`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"professor.nume"}
                        />
                        <span className={"text-nowrap"}>Nume profesor</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Nume profesor..."}
                        name={`professor.nume`}
                        value={params[`professor.nume`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"professor.email"}
                        />
                        <span className={"text-nowrap"}>Email profesor</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Email profesor..."}
                        name={`professor.email`}
                        value={params[`professor.email`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"professor.telefon"}
                        />
                        <span className={"text-nowrap"}>Telefon profesor</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Telefon profesor..."}
                        name={`professor.telefon`}
                        value={params[`professor.telefon`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"professor.isApproved"}
                        />
                        <span className={"text-nowrap"}>Profesor aprobat</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Profesor aprobat..."}
                        name={`professor.isApproved`}
                        value={params[`professor.isApproved`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"professor.hasSchoolRegistrationFormCompleted"}
                        />
                        <span className={"text-nowrap"}>Datele scolii actualizate</span>
                    </div>
                    <BooleanFilterInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        name={`professor.hasSchoolRegistrationFormCompleted`}
                        value={params[`professor.hasSchoolRegistrationFormCompleted`] || ""}
                    />
                </HeadCell>
            </HeadRow>
        </thead>
    );
}
TableHeaders.propTypes = {
    actionsDisabled: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    changeParams: PropTypes.func.isRequired,
};
