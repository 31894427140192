import { useRoutesProvider } from "../providers/provider-hooks.jsx";
import { Button } from "../components/schadcn-ui/button.jsx";
import { useNavigate } from "react-router-dom";

export default function Home() {
    const navigate = useNavigate();
    const { PAGES, PATHS } = useRoutesProvider();

    return (
        <div className={"overflow-x-hidden overflow-y-scroll bg-white"}>
            <div className="relative flex max-h-screen min-h-screen min-w-screen max-w-screen items-center justify-center bg-white">
                <div className="absolute flex max-h-screen min-h-screen min-w-screen max-w-screen flex-1">
                    <img
                        src="/thumbnail_interfata_desktop.jpg"
                        alt="Home Banner"
                        className="hidden max-h-screen min-h-screen min-w-screen max-w-screen object-cover object-right md:flex"
                    />
                </div>
                <div className="absolute flex max-h-screen min-h-screen min-w-screen max-w-screen flex-1">
                    <img
                        src="/thumbnail_interfata.jpg"
                        alt="Home Banner"
                        className="flex max-h-screen min-h-screen min-w-screen max-w-screen object-cover object-top md:hidden"
                    />
                </div>
                <div className="z-10 flex h-full w-full flex-col items-center justify-center space-y-8 bg-black bg-opacity-20 p-20">
                    <div className={"flex w-full justify-center"}>
                        <Button
                            className={
                                "flex items-center justify-center rounded-full border-2 border-white px-10 py-5 text-xl shadow"
                            }
                            onClick={() => navigate(PATHS[PAGES.PUPIL_AUTH])}>
                            <span className={"self-center pb-1 text-3xl"}>Sunt Elev</span>
                        </Button>
                    </div>
                    <div className={"flex w-full justify-center"}>
                        <Button
                            className={
                                "flex items-center justify-center rounded-full border-2 border-white px-10 py-5 text-xl shadow"
                            }
                            onClick={() => navigate(PATHS[PAGES.TEACHER_AUTH])}>
                            <span className={"self-center pb-1 text-3xl"}>Sunt Profesor</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
