import PreviewTitle from "./preview-title.jsx";
import PreviewWrap from "./preview-wrap.jsx";
import PropTypes from "prop-types";

export default function InfoPreview({ selectSlide, selectedSlide, slide, checkAnyErrorForPath, slideIdx }) {
    return (
        <PreviewWrap
            selectSlide={selectSlide}
            selectedSlide={selectedSlide}
            slide={slide}
            checkAnyErrorForPath={checkAnyErrorForPath}
            slideIdx={slideIdx}>
            <PreviewTitle title={slide.text} titlePlaceholder={"Info"} />
            <div className="h-full" />
            <div className="flex h-full w-full flex-col items-center justify-center space-y-1 self-end p-1">
                <div className={"flex w-full items-center justify-center space-x-1"}>
                    <div className={"h-1.5 max-h-1.5 w-1.5 max-w-1.5 rounded-full bg-green-600"} />
                    <div className={"flex h-2 flex-1 rounded-full bg-green-600"} />
                </div>
                <div className={"flex w-full items-center justify-center space-x-1"}>
                    <div className={"h-1.5 max-h-1.5 w-1.5 max-w-1.5 rounded-full bg-orange-600"} />
                    <div className={"flex h-2 flex-1 rounded-full bg-orange-600"} />
                </div>
                <div className={"flex w-full items-center justify-center space-x-1"}>
                    <div className={"h-1.5 max-h-1.5 w-1.5 max-w-1.5 rounded-full bg-cyan-600"} />
                    <div className={"flex h-2 flex-1 rounded-full bg-cyan-600"} />
                </div>
            </div>
        </PreviewWrap>
    );
}
InfoPreview.propTypes = {
    checkAnyErrorForPath: PropTypes.func.isRequired,
    selectSlide: PropTypes.func.isRequired,
    selectedSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    slide: PropTypes.object.isRequired,
    slideIdx: PropTypes.number.isRequired,
};
