import SelectInputBoxWithSearch from "../../components/forms/final-input-boxes/select-input-box-with-search.jsx";
import BooleanInputBox from "../../components/forms/final-input-boxes/boolean-input-box.jsx";
import NumberInputBox from "../../components/forms/final-input-boxes/number-input-box.jsx";
import SelectInputBox from "../../components/forms/final-input-boxes/select-input-box.jsx";
import TextInputBox from "../../components/forms/final-input-boxes/text-input-box.jsx";
import PreviewTextBox from "../../components/quizzes/creation/preview-text-box.jsx";
import useSchoolDetailsV2 from "../../hooks/forms/use-schools-details-v2.js";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import { useRoutesProvider } from "../../providers/provider-hooks.jsx";
import { Button } from "../../components/schadcn-ui/button.jsx";
import { useLocation, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Fragment } from "react";

export default function SchoolDetailsV2({ authorized }) {
    const {
        handleSimpleFieldChange,
        handleComplexFieldChange,
        counties,
        handleManualSIIIRCodeChange,
        dropdownData,
        saveSchoolForm,
        formData,
        errors,
        actionsDisabled,
        resetForm,
        handleSIIIRCodeChange,
        renderFullForm,
    } = useSchoolDetailsV2(authorized);
    const { PATHS, PAGES } = useRoutesProvider();
    const location = useLocation();

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap withoutNav={!authorized} withoutSideMenu={!authorized}>
                <BgContentWrap>
                    <div>
                        <PreviewTextBox text={"Formular de actualizare a datelor scolii"} />
                    </div>

                    {Object.keys(formData).length > 0 && (
                        <Fragment>
                            <SelectInputBoxWithSearch
                                name={"codSiiirUnitate"}
                                label={"Codul SIIIR al institutiei de invatamant"}
                                display={{
                                    value: "codSiiirUnitate",
                                    keys: ["codSiiirUnitate", "numeInstitutieInvatamant", "judet", "oras"],
                                }}
                                handleManualSIIIRCodeChange={handleManualSIIIRCodeChange}
                                emptyValueLabel={"Selectati codul SIIIR al institutiei de invatamant"}
                                value={formData["codSiiirUnitate"] || ""}
                                options={dropdownData}
                                disabled={authorized}
                                errors={errors}
                                handleChange={handleSIIIRCodeChange}
                            />
                            {!authorized && (
                                <span className={"px-2 text-lg text-black"}>
                                    În cazul în care întâmpinați dificultăți în identificarea codului SIIIR al
                                    instituției dvs. apăsați{" "}
                                    <Link
                                        to={PATHS[PAGES.BIG_SCHOOLS_DATA_TABLE]}
                                        className={"font-bold text-magenta hover:underline"}>
                                        AICI
                                    </Link>
                                    . Veți fi redirecționat către un tabel de unde veți putea să vă căutați instituția
                                    de învățământ de care aparțineți și să extrageți din tabel codul SIIIR
                                </span>
                            )}

                            {(renderFullForm || authorized) && (
                                <Fragment>
                                    <TextInputBox
                                        name={"codSe"}
                                        label={"Cod SE"}
                                        value={formData["codSe"] || ""}
                                        disabled={true}
                                        errors={errors}
                                        handleChange={handleSimpleFieldChange}
                                    />

                                    <TextInputBox
                                        name={"cui"}
                                        label={"C.U.I."}
                                        disabled={authorized}
                                        value={formData["cui"] || ""}
                                        errors={errors}
                                        handleChange={handleSimpleFieldChange}
                                    />

                                    <TextInputBox
                                        name={"numeInstitutieInvatamant"}
                                        disabled={authorized}
                                        label={"Denumire institutie invatamant"}
                                        value={formData["numeInstitutieInvatamant"] || ""}
                                        errors={errors}
                                        handleChange={handleSimpleFieldChange}
                                    />

                                    <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                        <SelectInputBox
                                            name={"adresa.judet"}
                                            label={"Judetul Scolii"}
                                            display={{ value: "value", keys: ["value"] }}
                                            disabled={authorized}
                                            emptyValueLabel={"Selectati judetul scolii"}
                                            value={formData["adresa"]["judet"] || ""}
                                            options={[...counties]}
                                            errors={errors}
                                            handleChange={handleComplexFieldChange}
                                        />
                                        <TextInputBox
                                            name={"adresa.oras"}
                                            label={"Orasul Scolii"}
                                            value={formData["adresa"]["oras"] || ""}
                                            disabled={authorized}
                                            errors={errors}
                                            handleChange={handleComplexFieldChange}
                                        />
                                    </div>

                                    <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                        <TextInputBox
                                            name={"adresa.comuna"}
                                            label={"Comuna Scolii"}
                                            value={formData["adresa"]["comuna"] || ""}
                                            disabled={authorized}
                                            errors={errors}
                                            handleChange={handleComplexFieldChange}
                                        />
                                        <TextInputBox
                                            name={"adresa.sat"}
                                            label={"Satul Scolii"}
                                            value={formData["adresa"]["sat"] || ""}
                                            disabled={authorized}
                                            errors={errors}
                                            handleChange={handleComplexFieldChange}
                                        />
                                    </div>

                                    <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                        <TextInputBox
                                            name={"adresa.strada"}
                                            label={"Strada Scolii"}
                                            value={formData["adresa"]["strada"] || ""}
                                            disabled={authorized}
                                            errors={errors}
                                            handleChange={handleComplexFieldChange}
                                        />
                                        <TextInputBox
                                            name={"adresa.numar"}
                                            label={"Numarul Strazii"}
                                            value={formData["adresa"]["numar"] || ""}
                                            disabled={authorized}
                                            errors={errors}
                                            handleChange={handleComplexFieldChange}
                                        />
                                    </div>

                                    <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                        <TextInputBox
                                            name={"adresa.bloc"}
                                            label={"Bloc"}
                                            disabled={authorized}
                                            value={formData["adresa"]["bloc"] || ""}
                                            errors={errors}
                                            handleChange={handleComplexFieldChange}
                                        />
                                        <TextInputBox
                                            name={"adresa.scara"}
                                            label={"Scara"}
                                            disabled={authorized}
                                            value={formData["adresa"]["scara"] || ""}
                                            errors={errors}
                                            handleChange={handleComplexFieldChange}
                                        />
                                    </div>

                                    <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                        <TextInputBox
                                            name={"adresa.etaj"}
                                            label={"Etaj"}
                                            disabled={authorized}
                                            value={formData["adresa"]["etaj"] || ""}
                                            errors={errors}
                                            handleChange={handleComplexFieldChange}
                                        />
                                        <TextInputBox
                                            name={"adresa.apartament"}
                                            label={"Apartament"}
                                            value={formData["adresa"]["apartament"] || ""}
                                            disabled={authorized}
                                            errors={errors}
                                            handleChange={handleComplexFieldChange}
                                        />
                                    </div>

                                    <TextInputBox
                                        name={"adresa.codPostal"}
                                        label={"Cod Postal"}
                                        value={formData["adresa"]["codPostal"] || ""}
                                        disabled={authorized}
                                        errors={errors}
                                        handleChange={handleComplexFieldChange}
                                    />

                                    <TextInputBox
                                        name={"numeReprezentatLegal"}
                                        label={"Nume reprezentant legal"}
                                        value={formData["numeReprezentatLegal"] || ""}
                                        disabled={authorized}
                                        errors={errors}
                                        handleChange={handleSimpleFieldChange}
                                    />

                                    <BooleanInputBox
                                        handleChange={handleSimpleFieldChange}
                                        errors={errors}
                                        name={"confirmCaAmAcordulDePrincipiu"}
                                        disabled={authorized}
                                        label={
                                            "Confirm că am acordul de principiu al conducerii instituției de învățământ pe care o reprezint pentru participarea la Campania Școala Ecoterrienilor, ediția 2024-2025, și că voi prezenta Protocolul de colaborare semnat, înainte de începere."
                                        }
                                        value={formData["confirmCaAmAcordulDePrincipiu"]}
                                    />

                                    <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                        <TextInputBox
                                            name={"telefon"}
                                            label={"Telefon instituție"}
                                            value={formData["telefon"] || ""}
                                            disabled={authorized}
                                            errors={errors}
                                            handleChange={handleSimpleFieldChange}
                                        />
                                        <NumberInputBox
                                            name={"numarElevi"}
                                            label={"Numar elevi"}
                                            value={formData["numarElevi"] || ""}
                                            disabled={authorized}
                                            errors={errors}
                                            handleChange={handleSimpleFieldChange}
                                        />
                                    </div>

                                    <BooleanInputBox
                                        handleChange={handleSimpleFieldChange}
                                        errors={errors}
                                        name={"detineRecipiente"}
                                        disabled={authorized}
                                        label={"Scoala detine recipiente de colectare?"}
                                        value={formData["detineRecipiente"]}
                                    />
                                    <BooleanInputBox
                                        handleChange={handleSimpleFieldChange}
                                        errors={errors}
                                        name={"adresaLivrareAceeasiCuScoala"}
                                        disabled={authorized}
                                        label={"Adresa de livrare este aceeasi cu a scolii?"}
                                        value={formData["adresaLivrareAceeasiCuScoala"]}
                                    />

                                    {formData["adresaLivrareAceeasiCuScoala"] === false && (
                                        <Fragment>
                                            <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                                <SelectInputBox
                                                    name={"adresaLivrare.judet"}
                                                    label={"Judet livrare"}
                                                    display={{ value: "value", keys: ["value"] }}
                                                    disabled={authorized}
                                                    emptyValueLabel={"Selectati judetul de livrare al scolii"}
                                                    value={formData["adresaLivrare"]["judet"] || ""}
                                                    options={[...counties]}
                                                    errors={errors}
                                                    handleChange={handleComplexFieldChange}
                                                />
                                                <TextInputBox
                                                    name={"adresaLivrare.oras"}
                                                    label={"Oras livrare"}
                                                    value={formData["adresaLivrare"]["oras"] || ""}
                                                    errors={errors}
                                                    disabled={authorized}
                                                    handleChange={handleComplexFieldChange}
                                                />
                                            </div>

                                            <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                                <TextInputBox
                                                    name={"adresaLivrare.comuna"}
                                                    label={"Comuna livrare"}
                                                    value={formData["adresaLivrare"]["comuna"] || ""}
                                                    errors={errors}
                                                    disabled={authorized}
                                                    handleChange={handleComplexFieldChange}
                                                />
                                                <TextInputBox
                                                    name={"adresaLivrare.sat"}
                                                    label={"Sat livrare"}
                                                    value={formData["adresaLivrare"]["sat"] || ""}
                                                    errors={errors}
                                                    disabled={authorized}
                                                    handleChange={handleComplexFieldChange}
                                                />
                                            </div>

                                            <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                                <TextInputBox
                                                    name={"adresaLivrare.strada"}
                                                    label={"Strada livrare"}
                                                    value={formData["adresaLivrare"]["strada"] || ""}
                                                    errors={errors}
                                                    disabled={authorized}
                                                    handleChange={handleComplexFieldChange}
                                                />
                                                <TextInputBox
                                                    name={"adresaLivrare.numar"}
                                                    label={"Numarul strazii de livrare"}
                                                    value={formData["adresaLivrare"]["numar"] || ""}
                                                    errors={errors}
                                                    disabled={authorized}
                                                    handleChange={handleComplexFieldChange}
                                                />
                                            </div>

                                            <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                                <TextInputBox
                                                    name={"adresaLivrare.bloc"}
                                                    label={"Bloc livrare"}
                                                    value={formData["adresaLivrare"]["bloc"] || ""}
                                                    errors={errors}
                                                    disabled={authorized}
                                                    handleChange={handleComplexFieldChange}
                                                />
                                                <TextInputBox
                                                    name={"adresaLivrare.scara"}
                                                    disabled={authorized}
                                                    label={"Scara bloc livrare"}
                                                    value={formData["adresaLivrare"]["scara"] || ""}
                                                    errors={errors}
                                                    handleChange={handleComplexFieldChange}
                                                />
                                            </div>

                                            <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                                <TextInputBox
                                                    name={"adresaLivrare.etaj"}
                                                    disabled={authorized}
                                                    label={"Etaj bloc livrare"}
                                                    value={formData["adresaLivrare"]["etaj"] || ""}
                                                    errors={errors}
                                                    handleChange={handleComplexFieldChange}
                                                />
                                                <TextInputBox
                                                    name={"adresaLivrare.apartament"}
                                                    disabled={authorized}
                                                    label={"Apartament bloc livrare"}
                                                    value={formData["adresaLivrare"]["apartament"] || ""}
                                                    errors={errors}
                                                    handleChange={handleComplexFieldChange}
                                                />
                                            </div>

                                            <TextInputBox
                                                name={"adresaLivrare.codPostal"}
                                                disabled={authorized}
                                                label={"Cod postal adresa livrare"}
                                                value={formData["adresaLivrare"]["codPostal"] || ""}
                                                errors={errors}
                                                handleChange={handleComplexFieldChange}
                                            />
                                        </Fragment>
                                    )}

                                    <BooleanInputBox
                                        handleChange={handleSimpleFieldChange}
                                        errors={errors}
                                        name={"prinPrezentaConfirmamInscrierea"}
                                        disabled={authorized}
                                        value={formData["prinPrezentaConfirmamInscrierea"]}
                                        label={
                                            "Prin prezenta confirmam înscrierea in Campania „Scoala Ecoterrienilor” si acceptarea deplina si necondiționata a Regulamentului disponibil pe www.ecotic.ro."
                                        }
                                    />
                                    <BooleanInputBox
                                        handleChange={handleSimpleFieldChange}
                                        errors={errors}
                                        name={"amFostInformati"}
                                        disabled={authorized}
                                        value={formData["amFostInformati"]}
                                        label={
                                            "Am fost informați despre modul de desfășurare al campaniei, procedura de colectare si regulamentul campaniei si suntem de acord cu toate acestea. Confirmăm ca ni s-a adus la cunoștință și acceptăm posibilitatea modificării, suspendării sau încetării Campaniei care ni se aplică, caz în care aceste sunt postate pe www.ecotic.ro."
                                        }
                                    />
                                    <BooleanInputBox
                                        handleChange={handleSimpleFieldChange}
                                        errors={errors}
                                        name={"confirmFaptulCaReprezentatulLegal"}
                                        disabled={authorized}
                                        value={formData["confirmFaptulCaReprezentatulLegal"]}
                                        label={
                                            "Confirm faptul ca reprezentantul legal al instituției de învățământ a desemnat profesorul coordonator menționat in cadrul formularului."
                                        }
                                    />
                                    <BooleanInputBox
                                        handleChange={handleSimpleFieldChange}
                                        errors={errors}
                                        name={"prinPrezentaConfirmCaAmCititInformarea"}
                                        disabled={authorized}
                                        value={formData["prinPrezentaConfirmCaAmCititInformarea"]}
                                        label={
                                            "Prin prezenta confirm că am citit Informarea (Notă de informare Școala Ecoterrienilor - https://www.ecotic.ro/informare-scoala-ecoterrienilor/ și Notă de informare înscriere profesori/școală în Școala Ecoterrienilor - https://www.ecotic.ro/nota-de-informare-inscriere-profesori-scoala-in-scoala-ecoterrienilor/) cu privire la prelucrarea datelor mele cu caracter personal și înțeleg ca prelucrarea datelor mele personale (nume, prenume, email si telefon) este strict necesara pentru înscrierea si participarea la competiție, conform Regulamentului, care este acordul părților (eu, pe de o parte, ECOTIC si ECOTIC BAT, pe de alta parte). Înțeleg că, în cazul în care nu doresc ca ECOTIC sa îmi prelucreze datele cu caracter personal, pot sa nu bifez acest câmp, iar acest formular nu va transmite datele mele către ASOCIATIA ECOTIC si ECOTIC BAT și nu voi putea fi înscris în competiția Școala Ecoterrienilor, relația contractuala dintre părți neputându-se încheia."
                                        }
                                    />
                                    <BooleanInputBox
                                        handleChange={handleSimpleFieldChange}
                                        errors={errors}
                                        name={"confirmCaInCazulInCareProiectul"}
                                        disabled={authorized}
                                        value={formData["confirmCaInCazulInCareProiectul"]}
                                        label={
                                            "Confirm că în cazul în care proiectul înscris include imagini cu persoane (majore sau minore), acestea si-au dat consimțământul expres, direct sau prin reprezentantul legal, pentru utilizarea imaginilor de către Organizator, consimțământ care va fi pus la dispoziția ECOTIC odată cu depunerea materialului. In cazul in care nu exista consimțământ, acest material nu poate fi depus sau , daca este depus, va fi descalificat, iar ECOTIC îl va șterge."
                                        }
                                    />
                                    <BooleanInputBox
                                        handleChange={handleSimpleFieldChange}
                                        errors={errors}
                                        name={"consimtPentruPastrareaDatelorPersonale"}
                                        disabled={authorized}
                                        value={formData["consimtPentruPastrareaDatelorPersonale"]}
                                        label={
                                            "Consimt pentru păstrarea datelor personale in baza de date a ASOCIATIEI ECOTIC pentru o perioada de maxim 5 ani cu scopul de a primi materiale informative pe tema protecției mediului si invitație de înscriere la ediția următoare a proiectului sau la alte proiecte pe tema protecției mediului."
                                        }
                                    />
                                    <BooleanInputBox
                                        handleChange={handleSimpleFieldChange}
                                        errors={errors}
                                        name={"ecoticPoateFolosiImagini"}
                                        disabled={authorized}
                                        value={formData["ecoticPoateFolosiImagini"]}
                                        label={
                                            "ECOTIC poate folosi imagini in care pot aparea participantii in urmatoarele scopuri: ca dovada ca s-au realizat activitatile propuse in Concurs, pentru promovarea rezultatelor Concursului pe site-ul ECOTIC si/sau paginile de social media ale ECOTIC, pentru a fi incluse in materiale de prezentare a activitatii ECOTIC (tip retrospectiva, newsletter etc.). Cu toate acestea, înțeleg că Organizatorii recomandă transmiterea de imagini fără identificarea facială a persoanelor."
                                        }
                                    />
                                    <BooleanInputBox
                                        handleChange={handleSimpleFieldChange}
                                        errors={errors}
                                        name={"amCititSiSuntDeAcord"}
                                        disabled={authorized}
                                        value={formData["amCititSiSuntDeAcord"]}
                                        label={
                                            "Am citit și sunt de acord cu Nota de Informare - https://www.ecotic.ro/nota-de-informare-inscriere-profesori-scoala-in-scoala-ecoterrienilor/ și Regulamentul Campaniei - https://www.ecotic.ro/wp-content/uploads/2024/09/REGULAMENTUL-OFICIAL-SCOALA-ECOTERRIENILOR-2024-2025.pdf"
                                        }
                                    />
                                    {!authorized && (
                                        <div
                                            className={
                                                "flex w-full flex-col items-center justify-center space-y-4 p-2 sm:flex-row sm:space-x-4 sm:space-y-0"
                                            }>
                                            <Button
                                                className={"w-full sm:w-auto"}
                                                disabled={actionsDisabled}
                                                onClick={() => {
                                                    // !authorized ? location.pathname === PATHS[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3] ? "UPDATE" : "SAVE" : "UPDATE"
                                                    saveSchoolForm(!authorized ? "SAVE" : "UPDATE");
                                                }}>
                                                <span>Salvare</span>
                                            </Button>
                                            {!authorized && (
                                                <Button className={"w-full sm:w-auto"} onClick={() => resetForm()}>
                                                    <span>Resetare formular</span>
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    );
}
SchoolDetailsV2.propTypes = {
    authorized: PropTypes.bool.isRequired,
};
