import BooleanInputBox from "../../forms/final-input-boxes/boolean-input-box.jsx";
import NumberInputBox from "../../forms/final-input-boxes/number-input-box.jsx";
import SelectInputBox from "../../forms/final-input-boxes/select-input-box.jsx";
import TextInputBox from "../../forms/final-input-boxes/text-input-box.jsx";
import { Button } from "../../schadcn-ui/button.jsx";
import { forwardRef, Fragment } from "react";
import { FaXmark } from "react-icons/fa6";
import PropTypes from "prop-types";
import SelectInputBoxWithSearch from "../../forms/final-input-boxes/select-input-box-with-search.jsx";
import FieldWrap from "../../forms/wraps/field-wrap.jsx";

const EditableFormDialog = forwardRef(
    (
        {
            counties,
            dialogFormData,
            closeFormDialog,
            actionsDisabled,
            siiirDropdownData = [],
            saveFormCorrections,
            correctionFormErrors,
            isFormCorrectionDialogOpened,
            handleSimpleCorrectionFieldChange,
            handleComplexCorrectionFieldChange,
            sensibleDataEditable = false,
            handleSIIIRCodeChange = () => false,
            handleManualSIIIRCodeChange = () => false,
        },
        ref,
    ) => {
        return (
            <Fragment>
                {isFormCorrectionDialogOpened && (
                    <div className="fixed left-0 top-0 z-40 flex h-screen w-screen animate-fade cursor-default items-center justify-center bg-black/50 backdrop-blur-sm animate-duration-200">
                        <div
                            ref={ref}
                            className="relative flex max-h-[80vh] min-w-[40vw] max-w-[80vw] flex-col overflow-hidden rounded-lg bg-light p-4 shadow-lg">
                            <div className={"relative flex justify-end pb-4"}>
                                <button onClick={() => closeFormDialog()} className="">
                                    <FaXmark />
                                </button>
                            </div>
                            <div
                                className={
                                    "-pl-2 relative flex h-full max-h-[80vh] w-full min-w-[40vw] max-w-[80vw] select-text flex-col overflow-x-hidden overflow-y-scroll pr-2"
                                }>
                                {Object.keys(dialogFormData).length > 0 && (
                                    <Fragment>
                                        {!sensibleDataEditable && (
                                            <Fragment>
                                                <TextInputBox
                                                    name={"codSiiirUnitate"}
                                                    errors={correctionFormErrors}
                                                    label={"Codul SIIIR al institutiei de invatamant"}
                                                    value={dialogFormData?.["codSiiirUnitate"] || ""}
                                                    disabled={true}
                                                    handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                                />

                                                <TextInputBox
                                                    name={"codSe"}
                                                    label={"Cod SE"}
                                                    errors={correctionFormErrors}
                                                    value={dialogFormData?.["codSe"] || ""}
                                                    disabled={true}
                                                    handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                                />

                                                <TextInputBox
                                                    name={"cui"}
                                                    label={"C.U.I."}
                                                    disabled={true}
                                                    errors={correctionFormErrors}
                                                    value={dialogFormData?.["cui"] || ""}
                                                    handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                                />
                                            </Fragment>
                                        )}

                                        {sensibleDataEditable && (
                                            <Fragment>
                                                <FieldWrap customStyles="w-full felx justify-center">
                                                    <span className="flex w-full justify-center text-center text-2xl font-bold text-red-600">
                                                        Atentie: Modificarile datelor critice, cum ar fi codul SIIIR,
                                                        cod SE si CUI, trebuie efectuate cu responsabilitate, fiind
                                                        esentiale pentru procesul de APROBARE si pentru actualizarea in
                                                        sistemul ERP. Asigurati-va ca informatiile introduse sunt
                                                        corecte!
                                                    </span>
                                                </FieldWrap>
                                                <SelectInputBoxWithSearch
                                                    name={"codSiiirUnitate"}
                                                    disabled={actionsDisabled}
                                                    options={siiirDropdownData}
                                                    errors={correctionFormErrors}
                                                    handleChange={handleSIIIRCodeChange}
                                                    value={dialogFormData["codSiiirUnitate"] || ""}
                                                    label={"Codul SIIIR al institutiei de invatamant"}
                                                    handleManualSIIIRCodeChange={handleManualSIIIRCodeChange}
                                                    emptyValueLabel={
                                                        "Selectati codul SIIIR al institutiei de invatamant"
                                                    }
                                                    display={{
                                                        value: "codSiiirUnitate",
                                                        keys: [
                                                            "codSiiirUnitate",
                                                            "numeInstitutieInvatamant",
                                                            "judet",
                                                            "oras",
                                                        ],
                                                    }}
                                                />

                                                <TextInputBox
                                                    name={"codSe"}
                                                    label={"Cod SE"}
                                                    disabled={actionsDisabled}
                                                    errors={correctionFormErrors}
                                                    value={dialogFormData?.["codSe"] || ""}
                                                    handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                                />

                                                <TextInputBox
                                                    name={"cui"}
                                                    label={"C.U.I."}
                                                    disabled={actionsDisabled}
                                                    errors={correctionFormErrors}
                                                    value={dialogFormData?.["cui"] || ""}
                                                    handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                                />
                                            </Fragment>
                                        )}

                                        <TextInputBox
                                            disabled={actionsDisabled}
                                            errors={correctionFormErrors}
                                            name={"numeInstitutieInvatamant"}
                                            label={"Denumire institutie invatamant"}
                                            value={dialogFormData?.["numeInstitutieInvatamant"] || ""}
                                            handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                        />

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <SelectInputBox
                                                name={"adresa.judet"}
                                                options={[...counties]}
                                                label={"Judetul Scolii"}
                                                disabled={actionsDisabled}
                                                errors={correctionFormErrors}
                                                emptyValueLabel={"Selectati judetul scolii"}
                                                display={{ value: "value", keys: ["value"] }}
                                                value={dialogFormData?.["adresa"]?.["judet"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                            <TextInputBox
                                                name={"adresa.oras"}
                                                label={"Orasul Scolii"}
                                                disabled={actionsDisabled}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["adresa"]?.["oras"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                        </div>

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                name={"adresa.comuna"}
                                                label={"Comuna Scolii"}
                                                disabled={actionsDisabled}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["adresa"]?.["comuna"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                            <TextInputBox
                                                name={"adresa.sat"}
                                                label={"Satul Scolii"}
                                                disabled={actionsDisabled}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["adresa"]?.["sat"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                        </div>

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                name={"adresa.strada"}
                                                label={"Strada Scolii"}
                                                disabled={actionsDisabled}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["adresa"]?.["strada"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                            <TextInputBox
                                                name={"adresa.numar"}
                                                label={"Numarul Strazii"}
                                                disabled={actionsDisabled}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["adresa"]?.["numar"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                        </div>

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                label={"Bloc"}
                                                name={"adresa.bloc"}
                                                disabled={actionsDisabled}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["adresa"]?.["bloc"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                            <TextInputBox
                                                label={"Scara"}
                                                name={"adresa.scara"}
                                                disabled={actionsDisabled}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["adresa"]?.["scara"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                        </div>

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                label={"Etaj"}
                                                name={"adresa.etaj"}
                                                disabled={actionsDisabled}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["adresa"]?.["etaj"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                            <TextInputBox
                                                label={"Apartament"}
                                                name={"adresa.apartament"}
                                                disabled={actionsDisabled}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["adresa"]?.["apartament"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                        </div>

                                        <TextInputBox
                                            label={"Cod Postal"}
                                            name={"adresa.codPostal"}
                                            disabled={actionsDisabled}
                                            errors={correctionFormErrors}
                                            value={dialogFormData?.["adresa"]?.["codPostal"] || ""}
                                            handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                        />

                                        <TextInputBox
                                            disabled={actionsDisabled}
                                            name={"numeReprezentatLegal"}
                                            errors={correctionFormErrors}
                                            label={"Nume reprezentant legal"}
                                            value={dialogFormData?.["numeReprezentatLegal"] || ""}
                                            handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                        />

                                        <BooleanInputBox
                                            disabled={true}
                                            errors={correctionFormErrors}
                                            name={"confirmCaAmAcordulDePrincipiu"}
                                            value={dialogFormData["confirmCaAmAcordulDePrincipiu"]}
                                            handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                            label="Confirm că am acordul de principiu al conducerii instituției de învățământ pe care o reprezint pentru participarea la Campania Școala Ecoterrienilor, ediția 2024-2025, și că voi prezenta Protocolul de colaborare semnat, înainte de începere."
                                        />

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                name={"telefon"}
                                                disabled={actionsDisabled}
                                                label={"Telefon instituție"}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["telefon"] || ""}
                                                handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                            />
                                            <NumberInputBox
                                                name={"numarElevi"}
                                                label={"Numar elevi"}
                                                disabled={actionsDisabled}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["numarElevi"] || ""}
                                                handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                            />
                                        </div>

                                        <BooleanInputBox
                                            name={"detineRecipiente"}
                                            disabled={actionsDisabled}
                                            errors={correctionFormErrors}
                                            value={dialogFormData?.["detineRecipiente"]}
                                            label={"Scoala detine recipiente de colectare?"}
                                            handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                        />
                                        <BooleanInputBox
                                            disabled={actionsDisabled}
                                            errors={correctionFormErrors}
                                            name={"adresaLivrareAceeasiCuScoala"}
                                            label={"Adresa de livrare este aceeasi cu a scolii?"}
                                            value={dialogFormData?.["adresaLivrareAceeasiCuScoala"]}
                                            handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                        />

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <SelectInputBox
                                                label={"Judet livrare"}
                                                options={[...counties]}
                                                disabled={actionsDisabled}
                                                name={"adresaLivrare.judet"}
                                                errors={correctionFormErrors}
                                                display={{ value: "value", keys: ["value"] }}
                                                emptyValueLabel={"Selectati judetul de livrare al scolii"}
                                                value={dialogFormData?.["adresaLivrare"]?.["judet"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                            <TextInputBox
                                                label={"Oras livrare"}
                                                disabled={actionsDisabled}
                                                name={"adresaLivrare.oras"}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["adresaLivrare"]?.["oras"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                        </div>

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                label={"Comuna livrare"}
                                                disabled={actionsDisabled}
                                                name={"adresaLivrare.comuna"}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["adresaLivrare"]?.["comuna"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                            <TextInputBox
                                                label={"Sat livrare"}
                                                disabled={actionsDisabled}
                                                name={"adresaLivrare.sat"}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["adresaLivrare"]?.["sat"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                        </div>

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                label={"Strada livrare"}
                                                disabled={actionsDisabled}
                                                name={"adresaLivrare.strada"}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["adresaLivrare"]?.["strada"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                            <TextInputBox
                                                disabled={actionsDisabled}
                                                name={"adresaLivrare.numar"}
                                                errors={correctionFormErrors}
                                                label={"Numarul strazii de livrare"}
                                                value={dialogFormData?.["adresaLivrare"]?.["numar"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                        </div>

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                label={"Bloc livrare"}
                                                disabled={actionsDisabled}
                                                name={"adresaLivrare.bloc"}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["adresaLivrare"]?.["bloc"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                            <TextInputBox
                                                disabled={actionsDisabled}
                                                name={"adresaLivrare.scara"}
                                                label={"Scara bloc livrare"}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["adresaLivrare"]?.["scara"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                        </div>

                                        <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                            <TextInputBox
                                                disabled={actionsDisabled}
                                                label={"Etaj bloc livrare"}
                                                name={"adresaLivrare.etaj"}
                                                errors={correctionFormErrors}
                                                value={dialogFormData?.["adresaLivrare"]?.["etaj"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                            <TextInputBox
                                                disabled={actionsDisabled}
                                                errors={correctionFormErrors}
                                                name={"adresaLivrare.apartament"}
                                                label={"Apartament bloc livrare"}
                                                value={dialogFormData?.["adresaLivrare"]?.["apartament"] || ""}
                                                handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            />
                                        </div>

                                        <TextInputBox
                                            disabled={actionsDisabled}
                                            errors={correctionFormErrors}
                                            name={"adresaLivrare.codPostal"}
                                            label={"Cod postal adresa livrare"}
                                            handleChange={(e) => handleComplexCorrectionFieldChange(e)}
                                            value={dialogFormData?.["adresaLivrare"]?.["codPostal"] || ""}
                                        />

                                        <BooleanInputBox
                                            disabled={true}
                                            errors={correctionFormErrors}
                                            name={"prinPrezentaConfirmamInscrierea"}
                                            handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                            value={dialogFormData?.["prinPrezentaConfirmamInscrierea"]}
                                            label="Prin prezenta confirmam înscrierea in Campania „Scoala Ecoterrienilor” si acceptarea deplina si necondiționata a Regulamentului disponibil pe www.ecotic.ro."
                                        />
                                        <BooleanInputBox
                                            disabled={true}
                                            name={"amFostInformati"}
                                            errors={correctionFormErrors}
                                            value={dialogFormData?.["amFostInformati"]}
                                            handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                            label="Am fost informați despre modul de desfășurare al campaniei, procedura de colectare si regulamentul campaniei si suntem de acord cu toate acestea. Confirmăm ca ni s-a adus la cunoștință și acceptăm posibilitatea modificării, suspendării sau încetării Campaniei care ni se aplică, caz în care aceste sunt postate pe www.ecotic.ro."
                                        />
                                        <BooleanInputBox
                                            disabled={true}
                                            errors={correctionFormErrors}
                                            name={"confirmFaptulCaReprezentatulLegal"}
                                            handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                            value={dialogFormData?.["confirmFaptulCaReprezentatulLegal"]}
                                            label="Confirm faptul ca reprezentantul legal al instituției de învățământ a desemnat profesorul coordonator menționat in cadrul formularului."
                                        />
                                        <BooleanInputBox
                                            disabled={true}
                                            errors={correctionFormErrors}
                                            name={"prinPrezentaConfirmCaAmCititInformarea"}
                                            handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                            value={dialogFormData?.["prinPrezentaConfirmCaAmCititInformarea"]}
                                            label="Prin prezenta confirm că am citit Informarea (Notă de informare Școala Ecoterrienilor - https://www.ecotic.ro/informare-scoala-ecoterrienilor/ și Notă de informare înscriere profesori/școală în Școala Ecoterrienilor - https://www.ecotic.ro/nota-de-informare-inscriere-profesori-scoala-in-scoala-ecoterrienilor/) cu privire la prelucrarea datelor mele cu caracter personal și înțeleg ca prelucrarea datelor mele personale (nume, prenume, email si telefon) este strict necesara pentru înscrierea si participarea la competiție, conform Regulamentului, care este acordul părților (eu, pe de o parte, ECOTIC si ECOTIC BAT, pe de alta parte). Înțeleg că, în cazul în care nu doresc ca ECOTIC sa îmi prelucreze datele cu caracter personal, pot sa nu bifez acest câmp, iar acest formular nu va transmite datele mele către ASOCIATIA ECOTIC si ECOTIC BAT și nu voi putea fi înscris în competiția Școala Ecoterrienilor, relația contractuala dintre părți neputându-se încheia."
                                        />
                                        <BooleanInputBox
                                            disabled={true}
                                            errors={correctionFormErrors}
                                            name={"confirmCaInCazulInCareProiectul"}
                                            handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                            value={dialogFormData?.["confirmCaInCazulInCareProiectul"]}
                                            label="Confirm că în cazul în care proiectul înscris include imagini cu persoane (majore sau minore), acestea si-au dat consimțământul expres, direct sau prin reprezentantul legal, pentru utilizarea imaginilor de către Organizator, consimțământ care va fi pus la dispoziția ECOTIC odată cu depunerea materialului. In cazul in care nu exista consimțământ, acest material nu poate fi depus sau , daca este depus, va fi descalificat, iar ECOTIC îl va șterge."
                                        />
                                        <BooleanInputBox
                                            disabled={true}
                                            errors={correctionFormErrors}
                                            name={"consimtPentruPastrareaDatelorPersonale"}
                                            handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                            value={dialogFormData?.["consimtPentruPastrareaDatelorPersonale"]}
                                            label="Consimt pentru păstrarea datelor personale in baza de date a ASOCIATIEI ECOTIC pentru o perioada de maxim 5 ani cu scopul de a primi materiale informative pe tema protecției mediului si invitație de înscriere la ediția următoare a proiectului sau la alte proiecte pe tema protecției mediului."
                                        />
                                        <BooleanInputBox
                                            disabled={true}
                                            errors={correctionFormErrors}
                                            name={"ecoticPoateFolosiImagini"}
                                            value={dialogFormData?.["ecoticPoateFolosiImagini"]}
                                            handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                            label="ECOTIC poate folosi imagini in care pot aparea participantii in urmatoarele scopuri: ca dovada ca s-au realizat activitatile propuse in Concurs, pentru promovarea rezultatelor Concursului pe site-ul ECOTIC si/sau paginile de social media ale ECOTIC, pentru a fi incluse in materiale de prezentare a activitatii ECOTIC (tip retrospectiva, newsletter etc.). Cu toate acestea, înțeleg că Organizatorii recomandă transmiterea de imagini fără identificarea facială a persoanelor."
                                        />
                                        <BooleanInputBox
                                            disabled={true}
                                            name={"amCititSiSuntDeAcord"}
                                            errors={correctionFormErrors}
                                            value={dialogFormData?.["amCititSiSuntDeAcord"]}
                                            handleChange={(e) => handleSimpleCorrectionFieldChange(e)}
                                            label="Am citit și sunt de acord cu Nota de Informare - https://www.ecotic.ro/nota-de-informare-inscriere-profesori-scoala-in-scoala-ecoterrienilor/ și Regulamentul Campaniei - https://www.ecotic.ro/wp-content/uploads/2024/09/REGULAMENTUL-OFICIAL-SCOALA-ECOTERRIENILOR-2024-2025.pdf"
                                        />
                                        <div className={"flex flex-col space-y-4 pb-4 pt-2"}>
                                            <Button onClick={() => saveFormCorrections()}>Salvare corectari</Button>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    },
);
EditableFormDialog.displayName = "EditableFormDialog";
EditableFormDialog.propTypes = {
    siiirDropdownData: PropTypes.array,
    counties: PropTypes.array.isRequired,
    sensibleDataEditable: PropTypes.bool,
    handleSIIIRCodeChange: PropTypes.func,
    closeFormDialog: PropTypes.func.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
    dialogFormData: PropTypes.object.isRequired,
    handleManualSIIIRCodeChange: PropTypes.func,
    saveFormCorrections: PropTypes.func.isRequired,
    correctionFormErrors: PropTypes.object.isRequired,
    isFormCorrectionDialogOpened: PropTypes.bool.isRequired,
    handleSimpleCorrectionFieldChange: PropTypes.func.isRequired,
    handleComplexCorrectionFieldChange: PropTypes.func.isRequired,
};

export default EditableFormDialog;
