import PropTypes from "prop-types";

export default function QuizWrap({ children }) {
    return (
        <div className={"relative h-full w-full overflow-x-hidden rounded-md border border-lime-700"}>
            <div className={"absolute inset-0 z-0"}>
                <img
                    src="/quiz-bg.jpg"
                    alt="imaginea de fundal a chestionarului"
                    className="h-full w-full rounded-md object-cover"
                    loading="lazy"
                />
            </div>
            <div className={"absolute z-10 h-full w-full rounded-md bg-white/40 p-2"}>
                <div className={"flex h-full w-full flex-col items-center overflow-y-scroll pr-2"}>{children}</div>
            </div>
        </div>
    );
}
QuizWrap.propTypes = {
    children: PropTypes.node.isRequired,
};
