import { delay, findChangedPath, trimStringsFromAnywhere } from "../../utils.js";
import { APPLICATION_REPORTS, useTableExports } from "./use-table-exports.js";
import { useFetchProvider } from "../../providers/provider-hooks.jsx";
import { toastOneSuccess, toastOneWarn, updateFailureToast } from "../../toast.js";
import useTableParams from "./use-table-params.js";
import { useEffect, useRef, useState } from "react";
import { Slide, toast } from "react-toastify";
import _ from "lodash";

export default function useAccountAtuhorizationReport() {
    const [isFormCorrectionDialogOpened, setIsFormCorrectionDialogOpened] = useState(false);
    const [isEmailChangeDialogOpened, setIsEmailChangeDialogOpened] = useState(false);
    const [isFormDialogOpened, setIsFormDialogOpened] = useState(false);
    const [isNoteDialogOpened, setIsNoteDialogOpened] = useState(false);
    const [userInEmailChangeDialog, setUserInEmailChangeDialog] = useState(null);
    const [correctionFormErrors, setCorrectionFormErrors] = useState({});
    const [actionsDisabled, setActionsDisabled] = useState(false);
    const [formElementInDialog, setFormElementInDialog] = useState(null);
    const [isDialogOpened, setIsDialogOpened] = useState(false);
    const [siiirDropdownData, setSiiirDropdownData] = useState([]);
    const [oldDialogFormData, setOldDialogFormData] = useState({});
    const [emailChangeErrors, setEmailChangeErrors] = useState({});
    const [elementInDialog, setElementInDialog] = useState(null);
    const [dialogFormData, setDialogFormData] = useState({});
    const [rejectReason, setRejectReason] = useState("");
    const [noteInDialog, setNoteInDialog] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [noteErrors, setNoteErrors] = useState({});
    const [counties, setCounties] = useState([]);
    const [email, setEmail] = useState("");
    const [note, setNote] = useState("");

    const printRef = useRef(null);
    const dialogRef = useRef(null);
    const formDialogRef = useRef(null);
    const noteDialogRef = useRef(null);
    const editableFormDialogRef = useRef(null);

    const {
        patchNote,
        getCounties,
        patchUserEmail,
        patchActivation,
        getSchoolsReport,
        getCuiCheckDataForSchool,
        getSchoolRegistrationForm,
        getProfileActivationTable,
        patchSchoolRegistrationForm,
    } = useFetchProvider();
    const { params, changeParams, setParams } = useTableParams(renderAtuhorizationReport, { page: 0 });
    const { exportTable } = useTableExports(APPLICATION_REPORTS.ACCOUNT_AUTHORIZATION, params, setActionsDisabled);

    function openEmailChangeDialog(id) {
        setIsEmailChangeDialogOpened(true);
        setEmailChangeErrors({});
        setUserInEmailChangeDialog(id);
        setEmail("");
    }

    function closeEmailChangeDialog() {
        setIsEmailChangeDialogOpened(false);
        setEmailChangeErrors({});
        setUserInEmailChangeDialog(null);
        setEmail("");
    }

    function changeEmail(e) {
        const { value } = e.target;
        setEmail(value);
    }

    async function saveEmail() {
        setActionsDisabled(true);
        let errorMessage = "Emailul nu s-a putut actualiza.";
        const toastId = toast.loading("Modificarea se salveaza...", { transition: Slide });

        try {
            await delay(1000);
            const trimmedData = trimStringsFromAnywhere(email);
            await patchUserEmail({ userId: userInEmailChangeDialog, newEmail: trimmedData });
            closeEmailChangeDialog();
            toast.dismiss(toastId);
            await renderAtuhorizationReport(params);
        } catch (error) {
            console.error(error);
            if (error.message || error.errors) {
                if (error.errors && Object.keys(error.errors).length > 0) setEmailChangeErrors(error.errors);
                if (error.message) updateFailureToast(error.message, toastId);
                return;
            }
            updateFailureToast(errorMessage, toastId);
        } finally {
            setActionsDisabled(false);
        }
    }

    function openNotesDialog(id, note) {
        setIsNoteDialogOpened(true);
        setNoteErrors({});
        setNoteInDialog(id);
        setNote(note);
    }

    function closeNotesDialog() {
        setIsNoteDialogOpened(false);
        setNoteInDialog(null);
        setNoteErrors({});
        setNote("");
    }

    function changeNote(e) {
        const { value } = e.target;
        setNote(value);
    }

    async function saveNote() {
        setActionsDisabled(true);
        let errorMessage = "Mesajul nu s-a putut salva.";
        const toastId = toast.loading("Mesajul se salveaza...", { transition: Slide });

        try {
            await delay(1000);
            const trimmedData = trimStringsFromAnywhere(note);
            await patchNote(noteInDialog, trimmedData);
            closeNotesDialog();
            toast.dismiss(toastId);
            await renderAtuhorizationReport(params);
        } catch (error) {
            console.error(error);
            if (error.message || error.errors) {
                if (error.errors && Object.keys(error.errors).length > 0) setNoteErrors(error.errors);
                if (error.message) updateFailureToast(error.message, toastId);
                return;
            }
            updateFailureToast(errorMessage, toastId);
        } finally {
            setActionsDisabled(false);
        }
    }

    function closeFormDialog() {
        setIsFormCorrectionDialogOpened(false);
        setIsFormDialogOpened(false);
        setIsDialogOpened(false);
        setFormElementInDialog(null);
        setElementInDialog(null);
        setDialogFormData({});
        setOldDialogFormData({});
        setRejectReason("");
    }

    async function openDialogForm(accountId, editable = false) {
        setActionsDisabled(true);
        let errorMessage = "Formularul nu s-a putut deschide.";
        const toastId = toast.loading("Incarcam formularul de inregistrare al utilizatorului...", {
            transition: Slide,
        });

        try {
            await delay(1000);
            const dialogData = await getSchoolRegistrationForm(accountId);
            const { content } = await getCuiCheckDataForSchool({ size: 20000 });
            if (content && Array.isArray(content) && content.length) setSiiirDropdownData(content);
            setDialogFormData(dialogData);
            editable ? setOldDialogFormData(dialogData) : null;
            !editable ? setIsFormDialogOpened(true) : setIsFormCorrectionDialogOpened(true);
            setFormElementInDialog(accountId);
            toast.dismiss(toastId);
        } catch (error) {
            updateFailureToast(error.message || errorMessage, toastId);
        } finally {
            setActionsDisabled(false);
        }
    }

    function handleSimpleCorrectionFieldChange(e) {
        const { name, value } = e.target;
        const updatedData = { ...dialogFormData };
        updatedData[name] = value;
        setDialogFormData(updatedData);
    }

    function handleComplexCorrectionFieldChange(e) {
        const { name, value } = e.target;
        const updatedData = { ...dialogFormData };
        updatedData[name.split(".")[0]][name.split(".")[1]] = value;
        setDialogFormData(updatedData);
    }

    async function handleSIIIRCodeChange(e) {
        setActionsDisabled(true);
        const newFormData = { ...dialogFormData };
        const toastId = toast.loading("Datele scolii se incarca...", { transition: Slide });
        try {
            await delay(1000);
            const {
                target: { name, value },
            } = e;
            newFormData[name] = value;
            const officialData = siiirDropdownData.find((el) => el["codSiiirUnitate"] === value);

            try {
                const { content } = await getSchoolsReport({ codSiiirUnitate: value });
                if (!content || !Array.isArray(content) || content.length !== 1) {
                    newFormData["codSe"] = null;
                } else newFormData["codSe"] = content[0]["codSe"];
            } catch (error) {
                console.error(error);
            }
            const { judet, oras, comuna, sat, strada, codPostal, telefon, numeInstitutieInvatamant, cui, numar } =
                officialData;

            cui ? (newFormData["cui"] = cui) : null;
            sat ? (newFormData["adresa"]["sat"] = sat) : null;
            telefon ? (newFormData["telefon"] = telefon) : null;
            oras ? (newFormData["adresa"]["oras"] = oras) : null;
            numar ? (newFormData["adresa"]["numar"] = numar) : null;
            judet ? (newFormData["adresa"]["judet"] = judet) : null;
            comuna ? (newFormData["adresa"]["comuna"] = comuna) : null;
            strada ? (newFormData["adresa"]["strada"] = strada) : null;
            codPostal ? (newFormData["adresa"]["codPostal"] = codPostal) : null;
            numeInstitutieInvatamant ? (newFormData["numeInstitutieInvatamant"] = numeInstitutieInvatamant) : null;
            setDialogFormData(newFormData);
            toast.dismiss(toastId);
        } catch (error) {
            console.error(error);
        } finally {
            setActionsDisabled(false);
        }
    }

    function handleManualSIIIRCodeChange(SIIIRCode) {
        setActionsDisabled(true);
        try {
            const newFormData = { ...dialogFormData };
            newFormData["codSiiirUnitate"] = SIIIRCode;
            setDialogFormData(newFormData);
        } catch (error) {
            console.error(error);
        } finally {
            setActionsDisabled(false);
        }
    }

    async function saveFormCorrections() {
        setActionsDisabled(true);
        let errorMessage = "Formularul nu s-a putut salva.";
        const toastId = toast.loading("Salvam corectarile aplicate formularului...", { transition: Slide });

        try {
            await delay(1000);
            const trimmedData = trimStringsFromAnywhere(dialogFormData);
            await patchSchoolRegistrationForm(trimmedData);
            toast.dismiss(toastId);
            closeFormDialog();
            await renderAtuhorizationReport(params);
        } catch (error) {
            console.error(error);
            if (error.message || error.errors) {
                if (error.errors && Object.keys(error.errors).length > 0) setCorrectionFormErrors(error.errors);
                if (error.message) updateFailureToast(error.message, toastId);
                return;
            }
            updateFailureToast(errorMessage, toastId);
        } finally {
            setActionsDisabled(false);
        }
    }

    function closeRejectDialog() {
        setIsFormDialogOpened(false);
        setFormElementInDialog(null);
        setDialogFormData({});
        setOldDialogFormData({});
        setIsDialogOpened(false);
        setElementInDialog(null);
        setRejectReason("");
    }

    function openRejectDialog(accountId) {
        setIsDialogOpened(true);
        setElementInDialog(accountId);
    }

    const changeRejectReason = (e) => setRejectReason(e.target.value);

    async function unauthorizeAccount() {
        setActionsDisabled(true);
        if (!rejectReason) {
            toastOneWarn("Motivul respingerii acestui cont nu a fost mentionat.");
            return setActionsDisabled(false);
        }
        try {
            await authorizeAccount(elementInDialog, false, rejectReason);
            closeRejectDialog();
        } catch (error) {
            console.error(error);
        } finally {
            setActionsDisabled(false);
        }
    }

    async function authorizeAccount(id, isAuthorized, rejectReason) {
        setActionsDisabled(true);
        let errorMessage = "Procesul de autorizare nu s-a putut efectua.";
        const toastId = toast.loading("Actiunea este in proces de efectuare...", { transition: Slide });

        try {
            await delay(1000);
            await patchActivation(id, { activat: isAuthorized, rejectReason: rejectReason });
            toast.dismiss(toastId);
            closeRejectDialog();
            closeFormDialog();
        } catch (error) {
            console.error(error);
            updateFailureToast(error.message || errorMessage, toastId);
        } finally {
            setActionsDisabled(false);
            await renderAtuhorizationReport();
        }
    }

    async function renderAtuhorizationReport(freshParams) {
        setActionsDisabled(true);
        let errorMessage = "Tabelul nu s-a putut incarca";
        const id = toast.loading("Tabelul se incarca...", { transition: Slide });

        try {
            await delay(1000);
            const data = await getProfileActivationTable(freshParams || params);
            setTableData(data.content);
            setPagination({
                totalPages: data.totalPages,
                totalElements: data.totalElements,
                ...data["pageable"],
                first: data.first,
                last: data.last,
            });
            toast.dismiss(id);
        } catch (error) {
            console.error(error);
            updateFailureToast(error.message || errorMessage, id);
        } finally {
            setActionsDisabled(false);
        }
    }

    async function resetFilters() {
        setParams({ page: params.page });
        await renderAtuhorizationReport({ page: params.page });
    }

    async function populateCounties() {
        try {
            const newCounties = await getCounties();
            if (newCounties.length > 0) {
                setCounties(newCounties.map((county) => ({ value: county })));
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        populateCounties().then(() => renderAtuhorizationReport().then());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const changedPath = findChangedPath(dialogFormData, oldDialogFormData);
        if (changedPath) {
            const errorPath = Object.keys(correctionFormErrors).find((errorKey) => errorKey === changedPath);
            if (errorPath) {
                const newErrors = JSON.parse(JSON.stringify(correctionFormErrors));
                delete newErrors[errorPath];
                setCorrectionFormErrors(newErrors);
            }
            setOldDialogFormData(_.cloneDeep(dialogFormData));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogFormData, correctionFormErrors]);

    return {
        note,
        email,
        params,
        printRef,
        counties,
        saveNote,
        saveEmail,
        dialogRef,
        tableData,
        pagination,
        changeNote,
        noteErrors,
        exportTable,
        changeEmail,
        changeParams,
        rejectReason,
        resetFilters,
        noteDialogRef,
        formDialogRef,
        openDialogForm,
        isDialogOpened,
        dialogFormData,
        openNotesDialog,
        actionsDisabled,
        closeFormDialog,
        closeNotesDialog,
        authorizeAccount,
        openRejectDialog,
        closeRejectDialog,
        emailChangeErrors,
        siiirDropdownData,
        isNoteDialogOpened,
        unauthorizeAccount,
        isFormDialogOpened,
        changeRejectReason,
        formElementInDialog,
        saveFormCorrections,
        handleSIIIRCodeChange,
        correctionFormErrors,
        editableFormDialogRef,
        openEmailChangeDialog,
        closeEmailChangeDialog,
        renderAtuhorizationReport,
        isEmailChangeDialogOpened,
        handleManualSIIIRCodeChange,
        isFormCorrectionDialogOpened,
        handleSimpleCorrectionFieldChange,
        handleComplexCorrectionFieldChange,
    };
}
