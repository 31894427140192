import { QUIZ_TYPES } from "../../../../pages/quizzes/quiz-creation.jsx";
import { Fragment, useState, useEffect, useRef } from "react";
import { Button } from "../../../schadcn-ui/button.jsx";
import PropTypes from "prop-types";

export default function PreviewActions({
    isInPreview,
    setIsInPreview,
    quizType,
    saveQuiz,
    addQuestionSlide,
    addVideoSlide,
    addInfoSlide,
    addTextInputSlide,
    addImageUploadSlide,
}) {
    const [loading, setLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const dialogRef = useRef(null);

    async function handleAction(action) {
        setLoading(true);
        await action();
        setIsDialogOpen(false);
        setLoading(false);
    }

    const handleClickOutside = (event) =>
        dialogRef.current && !dialogRef.current.contains(event.target) ? setIsDialogOpen(false) : null;

    useEffect(() => {
        if (isDialogOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else document.removeEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [isDialogOpen]);

    return (
        <Fragment>
            {isInPreview ? (
                <Button onClick={() => setIsInPreview(false)}>Editare</Button>
            ) : (
                <Button onClick={() => setIsInPreview(true)}>Vizualizare</Button>
            )}
            {!isInPreview && (
                <Fragment>
                    <Button onClick={() => setIsDialogOpen(true)}>+ Slide</Button>
                    <Button onClick={() => handleAction(() => saveQuiz())}>Salvare</Button>
                </Fragment>
            )}
            {isDialogOpen && (
                <div className="fixed -top-2 left-0 z-50 flex h-screen w-screen animate-fade cursor-default items-center justify-center bg-black/50 backdrop-blur-sm animate-duration-200">
                    <div ref={dialogRef} className="flex flex-col space-y-4 rounded bg-white p-4 shadow-lg">
                        <Button onClick={() => handleAction(addInfoSlide)} disabled={loading}>
                            Info
                        </Button>
                        <Button onClick={() => handleAction(addVideoSlide)} disabled={loading}>
                            Video
                        </Button>
                        {[QUIZ_TYPES.HOMEWORK].includes(quizType) && (
                            <Button onClick={() => handleAction(addQuestionSlide)} disabled={loading}>
                                Intrebare
                            </Button>
                        )}
                        {[
                            QUIZ_TYPES.PRACTICAL_ACTIVITY,
                            QUIZ_TYPES.MONTHLY_CHALLENGE,
                            QUIZ_TYPES.DAILY_CHALLENGE,
                        ].includes(quizType) && (
                            <Button onClick={() => handleAction(addImageUploadSlide)} disabled={loading}>
                                Upload Imagine
                            </Button>
                        )}
                        {[QUIZ_TYPES.MONTHLY_CHALLENGE, QUIZ_TYPES.DAILY_CHALLENGE].includes(quizType) && (
                            <Button onClick={() => handleAction(addTextInputSlide)} disabled={loading}>
                                Completare Text
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </Fragment>
    );
}

PreviewActions.propTypes = {
    saveQuiz: PropTypes.func.isRequired,
    quizType: PropTypes.string.isRequired,
    isInPreview: PropTypes.bool.isRequired,
    addInfoSlide: PropTypes.func.isRequired,
    addVideoSlide: PropTypes.func.isRequired,
    setIsInPreview: PropTypes.func.isRequired,
    addQuestionSlide: PropTypes.func.isRequired,
    addTextInputSlide: PropTypes.func.isRequired,
    addImageUploadSlide: PropTypes.func.isRequired,
};
