import { useAuthProvider, useRoutesProvider } from "../../../providers/provider-hooks.jsx";
import { CalendarRangePicker } from "../tables/calendar-range-picker.jsx";
import BooleanFilterInput from "../tables/boolean-filter-input.jsx";
import SearchInput from "../tables/search-input.jsx";
import SortButton from "../tables/sort-button.jsx";
import HeadCell from "../tables/head-cell.jsx";
import HeadRow from "../tables/head-row.jsx";
import { cn } from "../../../utils.js";
import PropTypes from "prop-types";

export default function TableHeaders({ actionsDisabled, params, changeParams }) {
    const { authState } = useAuthProvider();
    const { ROLES } = useRoutesProvider();

    return (
        <thead className={cn("sticky top-0 z-10 bg-light font-bold")}>
            <HeadRow>
                {authState.userInfo.role === ROLES.ADMIN && (
                    <HeadCell colSpan={1}>
                        <div className="flex items-center space-y-1 py-2">
                            <span className={"text-nowrap"}>Actiune</span>
                        </div>
                    </HeadCell>
                )}

                {authState.userInfo.role === ROLES.ADMIN && (
                    <HeadCell colSpan={1}>
                        <div className="flex items-center space-y-1 py-2">
                            <span className={"text-nowrap"}>Actiune Notite</span>
                        </div>
                    </HeadCell>
                )}

                {authState.userInfo.role === ROLES.ADMIN && (
                    <HeadCell colSpan={1}>
                        <div className="flex items-center space-y-1 py-2">
                            <span className={"text-nowrap"}>Formular de inscriere</span>
                        </div>
                    </HeadCell>
                )}

                {authState.userInfo.role === ROLES.ADMIN && (
                    <HeadCell colSpan={1}>
                        <div className="flex items-center space-y-1 py-2">
                            <span className={"text-nowrap"}>Actiune Email</span>
                        </div>
                    </HeadCell>
                )}

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"isEnabled"}
                        />
                        <span className={"text-nowrap"}>Activat</span>
                    </div>
                    <BooleanFilterInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        name={`isEnabled`}
                        value={params[`isEnabled`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"hasSchoolRegistrationFormCompleted"}
                        />
                        <span className={"text-nowrap"}>Formular inscriere completat</span>
                    </div>
                    <BooleanFilterInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        name={`hasSchoolRegistrationFormCompleted`}
                        value={params[`hasSchoolRegistrationFormCompleted`] || ""}
                    />
                </HeadCell>

                {authState.userInfo.role === ROLES.ADMIN && (
                    <HeadCell colSpan={1} customStyles={"max-w-[350px]"}>
                        <div className="flex items-center space-y-1 py-2">
                            <SortButton
                                handleChange={changeParams}
                                disabled={actionsDisabled}
                                value={params["sort"] || ""}
                                name={"phoneCallNote"}
                            />
                            <span className={"text-nowrap"}>Notite telefonice</span>
                        </div>
                        <SearchInput
                            placeholder={"Notite telefonice..."}
                            disabled={actionsDisabled}
                            handleChange={changeParams}
                            name={"phoneCallNote"}
                            value={params["phoneCallNote"] || ""}
                        />
                    </HeadCell>
                )}

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"rejectReason"}
                        />
                        <span className={"text-nowrap"}>Motiv respingere</span>
                    </div>
                    <SearchInput
                        placeholder={"Motiv respingere..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"rejectReason"}
                        value={params["rejectReason"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"timestampInregistrare"}
                        />
                        <span className={"text-nowrap"}>Data inscriere</span>
                    </div>
                    <CalendarRangePicker
                        handleChange={changeParams}
                        fromName={"timestampInregistrare.start"}
                        toName={"timestampInregistrare.end"}
                        from={params["timestampInregistrare.start"] || ""}
                        to={params["timestampInregistrare.end"] || ""}
                        disabled={actionsDisabled}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"timestampFormularInscriere"}
                        />
                        <span className={"text-nowrap"}>Data completare formular cu datele scolii</span>
                    </div>
                    <CalendarRangePicker
                        handleChange={changeParams}
                        fromName={"timestampFormularInscriere.start"}
                        toName={"timestampFormularInscriere.end"}
                        from={params["timestampFormularInscriere.start"] || ""}
                        to={params["timestampFormularInscriere.end"] || ""}
                        disabled={actionsDisabled}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"telefon"}
                        />
                        <span className={"text-nowrap"}>Telefon</span>
                    </div>
                    <SearchInput
                        placeholder={"Telefon..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"telefon"}
                        value={params["telefon"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"nume"}
                        />
                        <span className={"text-nowrap"}>Nume</span>
                    </div>
                    <SearchInput
                        placeholder={"Nume..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"nume"}
                        value={params["nume"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"email"}
                        />
                        <span className={"text-nowrap"}>Email</span>
                    </div>
                    <SearchInput
                        placeholder={"Email..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"email"}
                        value={params["email"] || ""}
                    />
                </HeadCell>

                {authState.userInfo.role === ROLES.ADMIN && (
                    <HeadCell colSpan={1} customStyles={"max-w-[350px]"}>
                        <div className="flex items-center space-y-1 py-2">
                            <SortButton
                                handleChange={changeParams}
                                disabled={actionsDisabled}
                                value={params["sort"] || ""}
                                name={"numeInstitutieInvatamant"}
                            />
                            <span className={"text-nowrap"}>Nume institutie invatamant</span>
                        </div>
                        <SearchInput
                            placeholder={"Nume institutie invatamant..."}
                            disabled={actionsDisabled}
                            handleChange={changeParams}
                            name={"numeInstitutieInvatamant"}
                            value={params["numeInstitutieInvatamant"] || ""}
                        />
                    </HeadCell>
                )}

                {authState.userInfo.role === ROLES.ADMIN && (
                    <HeadCell colSpan={1}>
                        <div className="flex items-center space-y-1 py-2">
                            <SortButton
                                handleChange={changeParams}
                                disabled={actionsDisabled}
                                value={params["sort"] || ""}
                                name={"codSiiirUnitate"}
                            />
                            <span className={"text-nowrap"}>Cod SIIIR</span>
                        </div>
                        <SearchInput
                            placeholder={"Cod SIIIR..."}
                            disabled={actionsDisabled}
                            handleChange={changeParams}
                            name={"codSiiirUnitate"}
                            value={params["codSiiirUnitate"] || ""}
                        />
                    </HeadCell>
                )}

                {authState.userInfo.role === ROLES.ADMIN && (
                    <HeadCell colSpan={1}>
                        <div className="flex items-center space-y-1 py-2">
                            <SortButton
                                handleChange={changeParams}
                                disabled={actionsDisabled}
                                value={params["sort"] || ""}
                                name={"codSe"}
                            />
                            <span className={"text-nowrap"}>Cod SE</span>
                        </div>
                        <SearchInput
                            placeholder={"Cod SE..."}
                            disabled={actionsDisabled}
                            handleChange={changeParams}
                            name={"codSe"}
                            value={params["codSe"] || ""}
                        />
                    </HeadCell>
                )}
            </HeadRow>
        </thead>
    );
}
TableHeaders.propTypes = {
    actionsDisabled: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    changeParams: PropTypes.func.isRequired,
};
