import { FaClock } from "react-icons/fa6";
import { cn } from "../../../../utils.js";
import PropTypes from "prop-types";

export default function PreviewSlideBox({ content, customStyles, withClock = false }) {
    return (
        <div
            className={cn(
                "relative flex aspect-square h-full select-none items-center justify-center rounded-xl border-[2px] border-gray-200 bg-white font-semibold sm:h-auto sm:w-full",
                customStyles,
            )}>
            <span>{content}</span>
            {withClock && (
                <div className={"absolute -right-1 -top-1 z-10"}>
                    <FaClock className={"rounded-full bg-white text-small text-dark"} />
                </div>
            )}
        </div>
    );
}
PreviewSlideBox.propTypes = {
    withClock: PropTypes.bool,
    customStyles: PropTypes.string,
    content: PropTypes.string.isRequired,
};
