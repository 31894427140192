import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../schadcn-ui/select.jsx";
import PropTypes from "prop-types";

export default function SizeSelector({ actionsDisabled, params, changeParams }) {
    return (
        <div>
            <Select
                disabled={actionsDisabled}
                value={params["size"] || ""}
                onValueChange={(value) => changeParams({ target: { name: "size", value: value } }, "text", true)}>
                <SelectTrigger className="h-[36px] bg-dark font-semibold text-light placeholder:font-semibold">
                    <SelectValue placeholder="Elemente in pagina" />
                </SelectTrigger>
                <SelectContent className={"bg-white"}>
                    <SelectGroup>
                        <SelectItem value="100">100</SelectItem>
                        <SelectItem value="1000">1000</SelectItem>
                        <SelectItem value="10000">10000</SelectItem>
                        <SelectItem value="100000">100000</SelectItem>
                        <SelectItem value="1000000">1000000</SelectItem>
                    </SelectGroup>
                </SelectContent>
            </Select>
        </div>
    );
}
SizeSelector.propTypes = {
    params: PropTypes.object.isRequired,
    changeParams: PropTypes.func.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
};
