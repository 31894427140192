export const CLASSES = Object.freeze([
    { value: "0", label: "Clasa 0" },
    { value: "I", label: "Clasa I" },
    { value: "II", label: "Clasa a II-a" },
    { value: "III", label: "Clasa a III-a" },
    { value: "IV", label: "Clasa a IV-a" },
    { value: "V", label: "Clasa a V-a" },
    { value: "VI", label: "Clasa a VI-a" },
    { value: "VII", label: "Clasa a VII-a" },
    { value: "VIII", label: "Clasa a VIII-a" },
]);
