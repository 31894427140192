import { cn } from "../../../utils.js";
import HeadRow from "../tables/head-row.jsx";
import PropTypes from "prop-types";
import SortButton from "../tables/sort-button.jsx";
import SearchInput from "../tables/search-input.jsx";
import HeadCell from "../tables/head-cell.jsx";
import BooleanFilterInput from "../tables/boolean-filter-input.jsx";

export default function TableHeaders({ actionsDisabled, params, changeParams }) {
    return (
        <thead className={cn("sticky top-0 z-10 bg-light font-bold")}>
            <HeadRow>
                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"numeInstitutieInvatamant"}
                        />
                        <span className={"text-nowrap"}>Denumire institutie invatamant</span>
                    </div>
                    <SearchInput
                        placeholder={"Denumire institutie invatamant..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"numeInstitutieInvatamant"}
                        value={params["numeInstitutieInvatamant"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"codSeNumeric"}
                        />
                        <span className={"text-nowrap"}>Cod SE</span>
                    </div>
                    <SearchInput
                        placeholder={"Cod SE..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"codSe"}
                        value={params["codSe"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"cui"}
                        />
                        <span className={"text-nowrap"}>C.U.I.</span>
                    </div>
                    <SearchInput
                        placeholder={"C.U.I...."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"cui"}
                        value={params["cui"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["editionId"] || ""}
                            name={"editionId"}
                        />
                        <span className={"text-nowrap"}>Editia</span>
                    </div>
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"totalDeeeKg"}
                        />
                        <span className={"text-nowrap"}>Kg DEEE</span>
                    </div>
                    <SearchInput
                        placeholder={"Kg DEEE..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"totalDeeeKg"}
                        value={params["totalDeeeKg"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"totalDbaKg"}
                        />
                        <span className={"text-nowrap"}>Kg DBA</span>
                    </div>
                    <SearchInput
                        placeholder={"Kg DBA..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"totalDbaKg"}
                        value={params["totalDbaKg"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"totalBecuriSiNeoaneKg"}
                        />
                        <span className={"text-nowrap"}>Kg Becuri si Neoane</span>
                    </div>
                    <SearchInput
                        placeholder={"Kg Becuri si Neoane..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"totalBecuriSiNeoaneKg"}
                        value={params["totalBecuriSiNeoaneKg"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"totalKg"}
                        />
                        <span className={"text-nowrap"}>Total kg</span>
                    </div>
                    <SearchInput
                        placeholder={"Total kg..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"totalKg"}
                        value={params["totalKg"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"totalPointsPracticalActivity"}
                        />
                        <span className={"text-nowrap"}>Total puncte din activitati practice</span>
                    </div>
                    <SearchInput
                        placeholder={"Total puncte din activitati practice..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"totalPointsPracticalActivity"}
                        value={params["totalPointsPracticalActivity"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"totalPointsHomework"}
                        />
                        <span className={"text-nowrap"}>Total puncte din teme de casa</span>
                    </div>
                    <SearchInput
                        placeholder={"Total puncte din teme de casa..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"totalPointsHomework"}
                        value={params["totalPointsHomework"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"totalPointsDailyChallenge"}
                        />
                        <span className={"text-nowrap"}>Total puncte din provocari</span>
                    </div>
                    <SearchInput
                        placeholder={"Total puncte din provocari..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"totalPointsDailyChallenge"}
                        value={params["totalPointsDailyChallenge"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"countApprovedPracticalActivities"}
                        />
                        <span className={"text-nowrap"}>Activitati practice aprobate</span>
                    </div>
                    <SearchInput
                        placeholder={"Activitati practice aprobate..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"countApprovedPracticalActivities"}
                        value={params["countApprovedPracticalActivities"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"countApprovedHomework"}
                        />
                        <span className={"text-nowrap"}>Teme de casa aprobate</span>
                    </div>
                    <SearchInput
                        placeholder={"Teme de casa aprobate..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"countApprovedHomework"}
                        value={params["countApprovedHomework"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"countApprovedDailyChallenge"}
                        />
                        <span className={"text-nowrap"}>Provocari aprobate</span>
                    </div>
                    <SearchInput
                        placeholder={"Provocari aprobate..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"countApprovedDailyChallenge"}
                        value={params["countApprovedDailyChallenge"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"lessonTotalPoints"}
                        />
                        <span className={"text-nowrap"}>Total puncte din lectii</span>
                    </div>
                    <SearchInput
                        placeholder={"Total puncte din lectii..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"lessonTotalPoints"}
                        value={params["lessonTotalPoints"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"wasteCollectionFormPoints"}
                        />
                        <span className={"text-nowrap"}>Puncte din comenzi de colectare</span>
                    </div>
                    <SearchInput
                        placeholder={"Puncte din comenzi de colectare..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"wasteCollectionFormPoints"}
                        value={params["wasteCollectionFormPoints"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"sumTotalPoints"}
                        />
                        <span className={"text-nowrap"}>Total puncte</span>
                    </div>
                    <SearchInput
                        placeholder={"Total puncte..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"sumTotalPoints"}
                        value={params["sumTotalPoints"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"mainJudet"}
                        />
                        <span className={"text-nowrap"}>Judet</span>
                    </div>
                    <SearchInput
                        placeholder={"Judet..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"mainJudet"}
                        value={params["mainJudet"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"mainOras"}
                        />
                        <span className={"text-nowrap"}>Oras</span>
                    </div>
                    <SearchInput
                        placeholder={"Oras..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"mainOras"}
                        value={params["mainOras"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"mainComuna"}
                        />
                        <span className={"text-nowrap"}>Comuna</span>
                    </div>
                    <SearchInput
                        placeholder={"Comuna..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"mainComuna"}
                        value={params["mainComuna"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"mainSat"}
                        />
                        <span className={"text-nowrap"}>Sat</span>
                    </div>
                    <SearchInput
                        placeholder={"Sat..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"mainSat"}
                        value={params["mainSat"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"mainStrada"}
                        />
                        <span className={"text-nowrap"}>Strada</span>
                    </div>
                    <SearchInput
                        placeholder={"Strada..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"mainStrada"}
                        value={params["mainStrada"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"mainNumar"}
                        />
                        <span className={"text-nowrap"}>Numar</span>
                    </div>
                    <SearchInput
                        placeholder={"Numar..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"mainNumar"}
                        value={params["mainNumar"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"mainCodPostal"}
                        />
                        <span className={"text-nowrap"}>Cod postal</span>
                    </div>
                    <SearchInput
                        placeholder={"Cod postal..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"mainCodPostal"}
                        value={params["mainCodPostal"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"mainBloc"}
                        />
                        <span className={"text-nowrap"}>Bloc</span>
                    </div>
                    <SearchInput
                        placeholder={"Bloc..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"mainBloc"}
                        value={params["mainBloc"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"mainScara"}
                        />
                        <span className={"text-nowrap"}>Scara</span>
                    </div>
                    <SearchInput
                        placeholder={"Scara..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"mainScara"}
                        value={params["mainScara"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"mainEtaj"}
                        />
                        <span className={"text-nowrap"}>Etaj</span>
                    </div>
                    <SearchInput
                        placeholder={"Etaj..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"mainEtaj"}
                        value={params["mainEtaj"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"mainApartament"}
                        />
                        <span className={"text-nowrap"}>Apartament</span>
                    </div>
                    <SearchInput
                        placeholder={"Apartament..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"mainApartament"}
                        value={params["mainApartament"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"deliveryJudet"}
                        />
                        <span className={"text-nowrap"}>Judet livrare</span>
                    </div>
                    <SearchInput
                        placeholder={"Judet livrare..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"deliveryJudet"}
                        value={params["deliveryJudet"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"deliveryOras"}
                        />
                        <span className={"text-nowrap"}>Oras livrare</span>
                    </div>
                    <SearchInput
                        placeholder={"Oras livrare..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"deliveryOras"}
                        value={params["deliveryOras"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"deliveryComuna"}
                        />
                        <span className={"text-nowrap"}>Comuna livrare</span>
                    </div>
                    <SearchInput
                        placeholder={"Comuna livrare..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"deliveryComuna"}
                        value={params["deliveryComuna"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"deliverySat"}
                        />
                        <span className={"text-nowrap"}>Sat livrare</span>
                    </div>
                    <SearchInput
                        placeholder={"Sat livrare..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"deliverySat"}
                        value={params["deliverySat"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"deliveryStrada"}
                        />
                        <span className={"text-nowrap"}>Strada livrare</span>
                    </div>
                    <SearchInput
                        placeholder={"Strada livrare..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"deliveryStrada"}
                        value={params["deliveryStrada"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"deliveryNumar"}
                        />
                        <span className={"text-nowrap"}>Numar livrare</span>
                    </div>
                    <SearchInput
                        placeholder={"Numar livrare..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"deliveryNumar"}
                        value={params["deliveryNumar"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"deliveryCodPostal"}
                        />
                        <span className={"text-nowrap"}>Cod postal livrare</span>
                    </div>
                    <SearchInput
                        placeholder={"Cod postal livrare..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"deliveryCodPostal"}
                        value={params["deliveryCodPostal"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"deliveryBloc"}
                        />
                        <span className={"text-nowrap"}>Bloc livrare</span>
                    </div>
                    <SearchInput
                        placeholder={"Bloc livrare..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"deliveryBloc"}
                        value={params["deliveryBloc"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"deliveryScara"}
                        />
                        <span className={"text-nowrap"}>Scara livrare</span>
                    </div>
                    <SearchInput
                        placeholder={"Scara livrare..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"deliveryScara"}
                        value={params["deliveryScara"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"deliveryEtaj"}
                        />
                        <span className={"text-nowrap"}>Etaj livrare</span>
                    </div>
                    <SearchInput
                        placeholder={"Etaj livrare..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"deliveryEtaj"}
                        value={params["deliveryEtaj"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"deliveryApartament"}
                        />
                        <span className={"text-nowrap"}>Apartament livrare</span>
                    </div>
                    <SearchInput
                        placeholder={"Apartament livrare..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"deliveryApartament"}
                        value={params["deliveryApartament"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"telefon"}
                        />
                        <span className={"text-nowrap"}>Telefon</span>
                    </div>
                    <SearchInput
                        placeholder={"Telefon..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"telefon"}
                        value={params["telefon"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"numeReprezentatLegal"}
                        />
                        <span className={"text-nowrap"}>Nume reprezentat legal</span>
                    </div>
                    <SearchInput
                        placeholder={"Nume reprezentat legal..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"numeReprezentatLegal"}
                        value={params["numeReprezentatLegal"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"professorNume"}
                        />
                        <span className={"text-nowrap"}>Nume profesor</span>
                    </div>
                    <SearchInput
                        placeholder={"Nume profesor..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"professorNume"}
                        value={params["professorNume"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"professorEmail"}
                        />
                        <span className={"text-nowrap"}>Email profesor</span>
                    </div>
                    <SearchInput
                        placeholder={"Email profesor..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"professorEmail"}
                        value={params["professorEmail"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"professorTelefon"}
                        />
                        <span className={"text-nowrap"}>Telefon professor</span>
                    </div>
                    <SearchInput
                        placeholder={"Telefon professor..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"professorTelefon"}
                        value={params["professorTelefon"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"numarElevi"}
                        />
                        <span className={"text-nowrap"}>Numar elevi</span>
                    </div>
                    <SearchInput
                        placeholder={"Numar e  levi..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"numarElevi"}
                        value={params["numarElevi"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"detineRecipiente"}
                        />
                        <span className={"text-nowrap"}>Detine recipiente</span>
                    </div>
                    <BooleanFilterInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        name={`detineRecipiente`}
                        value={params[`detineRecipiente`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"participareEditieAnterioara"}
                        />
                        <span className={"text-nowrap"}>Participare editie anterioara</span>
                    </div>
                    <BooleanFilterInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        name={`participareEditieAnterioara`}
                        value={params[`participareEditieAnterioara`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"adresaLivrareAceeasiCuScoala"}
                        />
                        <span className={"text-nowrap"}>Adresa de livrare aceeasi cu scoala</span>
                    </div>
                    <BooleanFilterInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        name={`adresaLivrareAceeasiCuScoala`}
                        value={params[`adresaLivrareAceeasiCuScoala`] || ""}
                    />
                </HeadCell>
            </HeadRow>
        </thead>
    );
}
TableHeaders.propTypes = {
    actionsDisabled: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    changeParams: PropTypes.func.isRequired,
};
