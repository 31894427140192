import { cn } from "../../../utils.js";
import PropTypes from "prop-types";
import { Fragment } from "react";

export default function NumberInput({ value, name, label, handleChange, customStyles = "", disabled }) {
    return (
        <Fragment>
            <label
                htmlFor={name}
                className={cn(
                    "w-full text-center font-bold text-gray-400",
                    customStyles,
                    value ? "font-black" : "sr-only",
                )}>
                {label}
            </label>
            <input
                type="number"
                id={name}
                name={name}
                value={value || ""}
                onChange={handleChange}
                placeholder={label}
                min="0"
                disabled={disabled}
                className="h-full w-full appearance-none rounded-md p-2 text-center text-[16px] font-normal placeholder:font-bold focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
            />
        </Fragment>
    );
}
NumberInput.propTypes = {
    disabled: PropTypes.bool,
    customStyles: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
