import FieldWrap from "../../../forms/wraps/field-wrap.jsx";
import InputWrap from "../../../forms/wraps/input-wrap.jsx";
import { Button } from "../../../schadcn-ui/button.jsx";
import { FaImage, FaTrashCan } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { cn } from "../../../../utils.js";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import { useMemo } from "react";

export default function ImageUploadSlide({
    quizForm,
    currentSlide,
    saveAndMoveToAvailableSlide,
    currentAnswer,
    handleImageUpload,
    handleDZClick,
    removePhoto,
    actionsDisabled,
}) {
    const slide = useMemo(() => quizForm.slides.find((slide) => slide.id === currentSlide), [currentSlide, quizForm]);
    const location = useLocation();

    return (
        <div className={"my-auto flex flex-col items-center"}>
            <FieldWrap customStyles={"w-auto max-w-md animate-fade"}>
                <InputWrap customStyles={"w-auto p-4"}>
                    <span className={"text-center text-large font-semibold"}>{slide.text}</span>
                </InputWrap>
            </FieldWrap>

            {!location.pathname.includes("preview") && (
                <FieldWrap customStyles={"animate-fade"}>
                    <div className="flex h-full flex-1 items-center justify-center">
                        <InputWrap customStyles={"p-0"}>
                            <div
                                onClick={handleDZClick}
                                className={cn(
                                    "group relative flex h-full w-full flex-1 cursor-pointer flex-col items-center justify-center rounded-[5px] text-center text-magenta/80 transition duration-500 hover:bg-magenta/80 hover:text-white",
                                )}>
                                <Dropzone onDrop={handleImageUpload} disabled={actionsDisabled}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div className="dz-message needsclick;" {...getRootProps()}>
                                            <input
                                                multiple={true}
                                                disabled={actionsDisabled}
                                                id="question-image-upload"
                                                {...getInputProps({ accept: "image/*" })}
                                            />
                                        </div>
                                    )}
                                </Dropzone>
                                {Array.isArray(currentAnswer.photos) && currentAnswer.photos.length > 0 ? (
                                    <div className="flex flex-wrap">
                                        {currentAnswer.photos.map((photo, index) => (
                                            <div key={index} className={"relative m-2 h-32"}>
                                                <img
                                                    src={photo.toString()}
                                                    alt={`Uploaded ${index}`}
                                                    className="object-fit h-full w-auto animate-fade cursor-default"
                                                />
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        removePhoto(index);
                                                    }}
                                                    className="absolute right-1 top-1 rounded-full bg-red-500 p-1 text-white">
                                                    <FaTrashCan />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className={"flex flex-col items-center space-x-4 p-4"}>
                                        <FaImage style={{ fontSize: "2.3em" }} />
                                        <p className="m-0 text-lg font-semibold text-gray-400 transition duration-500 group-hover:text-white">
                                            Apasati aici pentru a adauga poze
                                        </p>
                                    </div>
                                )}
                            </div>
                        </InputWrap>
                    </div>
                </FieldWrap>
            )}

            <div className={"flex animate-fade justify-center p-2"}>
                <Button
                    className={"h-auto max-w-md"}
                    disabled={actionsDisabled}
                    onClick={() => saveAndMoveToAvailableSlide(slide["id"])}>
                    <span className={"text-wrap"}>
                        {!location.pathname.includes("preview") ? "Salvare" : "Continuare"}
                    </span>
                </Button>
            </div>
        </div>
    );
}
ImageUploadSlide.propTypes = {
    actionsDisabled: PropTypes.bool,
    quizForm: PropTypes.object.isRequired,
    removePhoto: PropTypes.func.isRequired,
    handleDZClick: PropTypes.func.isRequired,
    currentSlide: PropTypes.string.isRequired,
    currentAnswer: PropTypes.object.isRequired,
    handleImageUpload: PropTypes.func.isRequired,
    saveAndMoveToAvailableSlide: PropTypes.func.isRequired,
};
