import { cn } from "../../../../utils.js";
import PropTypes from "prop-types";

export default function PreviewWrap({ selectSlide, selectedSlide, slide, checkAnyErrorForPath, slideIdx, children }) {
    return (
        <div
            onClick={() => {
                console.log("hit");
                console.log(slide);
                selectSlide(slide.id);
            }}
            className={cn(
                checkAnyErrorForPath(`slides[${slideIdx}]`)
                    ? "ring-2 ring-red-600"
                    : selectedSlide === slide.id
                      ? "ring-2 ring-magenta/80"
                      : "ring-magenta/80 group-hover:ring-2",
                "relative flex h-full w-[91.18px] flex-1 animate-fade cursor-pointer flex-col rounded-md bg-gray-200 text-very-small",
            )}>
            {children}
        </div>
    );
}
PreviewWrap.propTypes = {
    slide: PropTypes.object.isRequired,
    slideIdx: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
    selectSlide: PropTypes.func.isRequired,
    checkAnyErrorForPath: PropTypes.func.isRequired,
    selectedSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};
