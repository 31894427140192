import { Popover, PopoverContent, PopoverTrigger } from "../../schadcn-ui/popover.jsx";
import { checkAnyErrorForPath, cn, retrieveErrorForPath } from "../../../utils.js";
import React, { useRef, useEffect, useState, useCallback } from "react";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import ErrorSpan from "../../layout/error-span.jsx";
import { Button } from "../../schadcn-ui/button.jsx";
import { ChevronsUpDown } from "lucide-react";
import InputWrap from "../wraps/input-wrap.jsx";
import FieldWrap from "../wraps/field-wrap.jsx";
import PropTypes from "prop-types";
import { toastOneError, toastOneWarn } from "../../../toast.js";

// Custom Item Component
const CustomItem = React.memo(({ option, onSelect, isSelected, display }) => {
    return (
        <div
            className={cn(
                "custom-item mr-2 flex h-full cursor-pointer flex-row flex-wrap items-center text-wrap rounded-md p-2",
                isSelected ? "bg-magenta/40" : "bg-white hover:bg-magenta/20",
            )}
            onClick={() => onSelect(option[display.value])}>
            <div className={"flex text-wrap"}>{display.keys.map((key) => option[key]).join(" - ")}</div>
        </div>
    );
});
CustomItem.displayName = "CustomItem";

// Custom List Component
const CustomList = React.memo(({ options, onSelect, selectedValue, display }) => {
    const listRef = useRef();

    const getItemSize = (index) => {
        const option = options[index];
        return Math.max(100, option[display.keys[0]].length * 10); // Example calculation
    };

    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0, true);
        }
    }, [options]);

    const Row = ({ index, style }) => {
        const option = options[index];
        return (
            <div style={style}>
                <CustomItem
                    key={option.id}
                    option={option}
                    onSelect={onSelect}
                    isSelected={selectedValue === option[display.value]}
                    display={display}
                />
            </div>
        );
    };

    return (
        <AutoSizer>
            {({ width }) => (
                <List ref={listRef} height={290} width={width} itemSize={getItemSize} itemCount={options.length}>
                    {Row}
                </List>
            )}
        </AutoSizer>
    );
});
CustomList.displayName = "CustomList";

export default function SelectInputBoxWithSearch({
    errors,
    options,
    display,
    customErrorStyles,
    customLabelStyles,
    handleChange,
    disabled,
    label,
    emptyValueLabel,
    value,
    name,
    handleManualSIIIRCodeChange,
}) {
    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [filterOptions, setFilterOptions] = useState(options);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        // Regular expression to match only digits
        const isNumeric = /^[0-9]*$/.test(newValue);

        if (isNumeric) {
            setSearchQuery(newValue);
        } else {
            toastOneWarn("Codul SIIIR poate contine doar cifre!");
        }
    };

    const handleSearch = useCallback(() => {
        if (!searchQuery) {
            setFilterOptions([...options]);
            return;
        }

        const filtered = options.filter((option) =>
            option[display.value].toLowerCase().includes(searchQuery.toLowerCase()),
        );
        setFilterOptions(filtered);
    }, [searchQuery, options, display.value]);

    useEffect(() => {
        const debounceTimeout = setTimeout(handleSearch, 300);
        return () => clearTimeout(debounceTimeout);
    }, [searchQuery, handleSearch]);

    return (
        <FieldWrap>
            <InputWrap customStyles={cn("px-0")}>
                <label
                    htmlFor={name}
                    className={cn(
                        disabled ? "opacity-50" : "",
                        "w-full text-center font-bold text-gray-400",
                        value ? "font-black" : "sr-only",
                        customLabelStyles,
                    )}>
                    {label}
                </label>
                <Popover open={open} onOpenChange={setOpen}>
                    <PopoverTrigger asChild>
                        <Button
                            disabled={disabled}
                            variant="outline"
                            role="combobox"
                            aria-expanded={open}
                            className={cn(
                                "h-auto text-wrap border-0 bg-white text-[16px] text-dark hover:bg-white hover:text-dark [&>span]:line-clamp-none",
                                disabled ? "cursor-not-allowed" : "",
                            )}>
                            {value
                                ? options.find((option) => option[display.value] === value)
                                    ? options.find((option) => option[display.value] === value)[display.keys[0]]
                                    : value
                                : emptyValueLabel}
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="max-h-[350px] min-h-[350px] border-[3px] border-gray-300 bg-white p-2">
                        <div className="command">
                            <input
                                type="text"
                                placeholder="Cauta..."
                                value={searchQuery}
                                onChange={handleInputChange}
                                className="flex h-10 w-full border-b border-dark bg-transparent bg-white p-2 py-3 text-sm outline-none disabled:cursor-not-allowed disabled:opacity-50"
                            />
                            {Array.isArray(filterOptions) && filterOptions.length > 0 && (
                                <CustomList
                                    options={filterOptions}
                                    onSelect={(selectedValue) => {
                                        handleChange({
                                            target: { name: name, value: selectedValue },
                                        });
                                        setOpen(false);
                                    }}
                                    selectedValue={value}
                                    display={display}
                                />
                            )}
                            {Array.isArray(filterOptions) && filterOptions.length === 0 && searchQuery && (
                                <div className={"mt-[12px] flex h-full flex-1 items-center"}>
                                    <Button
                                        className={"flex h-auto"}
                                        onClick={() => {
                                            if (searchQuery.length !== 10)
                                                return toastOneError("Codul SIIIR trebuie sa contina 10 caractere");
                                            handleManualSIIIRCodeChange(searchQuery);
                                            setOpen(false);
                                        }}>
                                        <span className={"text-wrap"}>
                                            Daca nu ati gasit codul SIIIR cautat apasati aici pentru a adauga manual
                                            codul: {searchQuery}
                                        </span>
                                    </Button>
                                </div>
                            )}
                        </div>
                    </PopoverContent>
                </Popover>
                <ErrorSpan
                    text={retrieveErrorForPath(name, errors)}
                    customStyles={cn(checkAnyErrorForPath(name, errors) ? "flex" : "hidden", customErrorStyles)}
                />
            </InputWrap>
        </FieldWrap>
    );
}

SelectInputBoxWithSearch.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
    customErrorStyles: PropTypes.string,
    options: PropTypes.array.isRequired,
    customLabelStyles: PropTypes.string,
    display: PropTypes.object.isRequired,
    emptyValueLabel: PropTypes.string.isRequired,
    handleManualSIIIRCodeChange: PropTypes.func.isRequired,
};
