import { useAuthProvider, useRoutesProvider } from "../../providers/provider-hooks.jsx";
import useAccountWaiting from "../../hooks/auth/use-account-waiting.js";
import PageWrap from "../../components/auth/page-wrap.jsx";
import PropTypes from "prop-types";
import { Button } from "../../components/schadcn-ui/button.jsx";

const TeacherMessage = () => {
    const { logout } = useAuthProvider();

    return (
        <div className="rounded-2xl bg-light/70 p-4 shadow">
            <div className={"flex h-full w-full flex-col space-y-4 font-semibold"}>
                <p>
                    Va multumim ca v-ati inregistrat in Scoala Ecoterrienilor! Suntem incantati sa va avem alaturi si
                    apreciem interesul dumneavoastra.
                </p>
                <p>Contul dumneavoastra este in curs de verificare si va fi confirmat in cel mai scurt timp posibil.</p>
                <p>
                    De indata ce procesul de confirmare este finalizat, veti primi o notificare pe email si acces
                    complet la aplicatie.
                </p>
                <p>Va multumim pentru rabdare si intelegere.</p>
                <p>Cu stima, Echipa ECOTIC</p>
                <div className={"flex w-full justify-center"}>
                    <Button onClick={() => logout()}>Inapoi la pagina principala</Button>
                </div>
            </div>
        </div>
    );
};

const PupilMessage = () => {
    const { logout } = useAuthProvider();

    return (
        <div className="rounded-2xl bg-light/70 p-4 shadow">
            <div className={"flex h-full w-full flex-col space-y-4 font-semibold"}>
                <p>Bun venit la Scoala Ecoterrienilor! Suntem foarte bucurosi ca te-ai alaturat noua!</p>
                <p>Contul tau este in curs de verificare de catre profesorul din scoala selectata.</p>
                <p>De indata ce contul tau este confirmat, vei primi un email si vei putea folosi aplicatia.</p>
                <p>Multumim pentru rabdare! Abia asteptam sa incepi aventura cu noi!</p>
                <p>Cu drag, Echipa ECOTIC</p>
                <div className={"flex w-full justify-center"}>
                    <Button onClick={() => logout()}>Inapoi la pagina principala</Button>
                </div>
            </div>
        </div>
    );
};

export default function AccountWaiting({ role }) {
    const { ROLES } = useRoutesProvider();
    useAccountWaiting();

    return (
        <PageWrap>
            <div className="z-10 flex w-full max-w-lg flex-1 flex-col justify-center">
                {ROLES.PROFESOR === role && <TeacherMessage />}
                {ROLES.ELEV === role && <PupilMessage />}
            </div>
        </PageWrap>
    );
}
AccountWaiting.propTypes = {
    role: PropTypes.string.isRequired,
};
