import { useEffect, useRef } from "react";

export default function useTextAreaResize() {
    const ref = useRef(null);

    const resizeTextarea = (ref) => {
        if (!ref.current) return;
        ref.current.rows = 1;
        const lineHeight = 46 - 8 - 8; // line height
        const maxRows = 4;
        ref.current.rows = Math.min(Math.floor(ref.current.scrollHeight / lineHeight), maxRows);
    };

    useEffect(() => {
        window.addEventListener("resize", () => resizeTextarea(ref));
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => window.removeEventListener("resize", () => resizeTextarea(ref));
    }, []);

    return { ref, resizeTextarea };
}
