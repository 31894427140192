import FieldWrap from "../../../forms/wraps/field-wrap.jsx";
import InputWrap from "../../../forms/wraps/input-wrap.jsx";
import { getQuizPlaceholder } from "../../../../utils.js";
import { Button } from "../../../schadcn-ui/button.jsx";
import PropTypes from "prop-types";

export default function PresentationSlide({ quizForm, quizAnswers, quizType, saveAndMoveToAvailableSlide }) {
    return (
        <div className={"flex h-full w-full flex-col items-center"}>
            <div className={"grid h-full grid-cols-1 sm:grid-cols-2"}>
                <div className={"col-span-1 flex h-full flex-col justify-center"}>
                    <FieldWrap customStyles={"w-auto animate-fade"}>
                        <InputWrap customStyles={"w-auto p-4"}>
                            <span className={"text-center text-large font-semibold"}>{quizForm.title}</span>
                        </InputWrap>
                    </FieldWrap>

                    <FieldWrap customStyles={"w-auto animate-fade"}>
                        <InputWrap customStyles={"w-auto p-4"}>
                            <span className={"text-[16px]"}>{quizForm.description}</span>
                        </InputWrap>
                    </FieldWrap>
                </div>
                <div className={"col-span-1 flex h-full flex-col justify-start sm:justify-center"}>
                    <FieldWrap customStyles={"w-auto animate-fade"}>
                        <InputWrap customStyles={"w-auto p-0"}>
                            <img src={quizForm.image} alt={""} className={"rounded-md"} />
                        </InputWrap>
                    </FieldWrap>
                </div>
                <div className={"col-span-1 flex w-full animate-fade justify-center p-2 sm:col-span-2"}>
                    <Button className={"max-w-md"} onClick={() => saveAndMoveToAvailableSlide()}>
                        <span className={"text-wrap"}>
                            {quizAnswers.length === 0
                                ? `Incepe ${getQuizPlaceholder(quizType, 3)}`
                                : `Continua ${getQuizPlaceholder(quizType, 3)}`}
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    );
}
PresentationSlide.propTypes = {
    quizForm: PropTypes.object.isRequired,
    quizType: PropTypes.string.isRequired,
    quizAnswers: PropTypes.array.isRequired,
    saveAndMoveToAvailableSlide: PropTypes.func.isRequired,
};
