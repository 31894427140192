import { Button } from "../../schadcn-ui/button.jsx";
import PropTypes from "prop-types";
import { Fragment } from "react";
import DoubleCheckAction from "../tables/double-check-action.jsx";
import { cn } from "../../../utils.js";

export default function ApprovalAction({ row, validateQuiz, actionsDisabled, openRejectDialog }) {
    return (
        <Fragment>
            {row["isApproved"] === null ? (
                <Fragment>
                    <DoubleCheckAction
                        actionsDisabled={actionsDisabled}
                        buttonMessage={"Aprobare"}
                        spanMessage={"Sunteti sigur ca doriti sa aprobati?"}
                        yesAction={() => validateQuiz(row["id"], true)}
                        buttonStyles={cn(
                            "inline-flex items-center justify-center whitespace-nowrap rounded-md text-small font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50",
                            "underline-offset-4 hover:underline",
                            "px-0 disabled:cursor-not-allowed text-green-700",
                        )}
                    />
                    <span> / </span>
                    <Button
                        onClick={() => openRejectDialog(row["id"])}
                        className={"px-0 text-red-700 disabled:cursor-not-allowed"}
                        variant={"link"}
                        disabled={actionsDisabled}>
                        Respingere
                    </Button>
                </Fragment>
            ) : (
                <span>N/a</span>
            )}
        </Fragment>
    );
}
ApprovalAction.propTypes = {
    row: PropTypes.object.isRequired,
    validateQuiz: PropTypes.func.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
    openRejectDialog: PropTypes.func.isRequired,
};
