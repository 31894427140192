import useTextAreaResize from "../../../../hooks/use-text-area-resize.js";
import TextareaInput from "../../../forms/raw-inputs/textarea-input.jsx";
import useYoutubeEmbed from "../../../../hooks/use-youtube-embed.js";
import NumberInput from "../../../forms/raw-inputs/number-input.jsx";
import TextInput from "../../../forms/raw-inputs/text-input.jsx";
import FieldWrap from "../../../forms/wraps/field-wrap.jsx";
import InputWrap from "../../../forms/wraps/input-wrap.jsx";
import ErrorSpan from "../../../layout/error-span.jsx";
import PreviewTextBox from "../preview-text-box.jsx";
import { Fragment, useEffect, useMemo } from "react";
import { cn } from "../../../../utils.js";
import QuizWrap from "../quiz-wrap.jsx";
import YouTube from "react-youtube";
import PropTypes from "prop-types";

export default function VideoSlide({
    formData,
    setFormData,
    selectedSlide,
    changeSlideTitle,
    isInPreview,
    retrieveErrorForPath,
    checkAnyErrorForPath,
}) {
    const slide = useMemo(() => formData.slides.find((slide) => slide.id === selectedSlide), [selectedSlide, formData]);
    const slideIdx = useMemo(
        () => formData.slides.findIndex((slide) => slide.id === selectedSlide),
        [selectedSlide, formData],
    );
    const {
        handleVideoIdChange,
        startMinutes,
        handleStartMinutesChange,
        startSeconds,
        handleStartSecondsChange,
        endMinutes,
        handleEndMinutesChange,
        endSeconds,
        handleEndSecondsChange,
        opts,
        onPlayerStateChange,
        validateTimes,
    } = useYoutubeEmbed(slide, formData, setFormData, selectedSlide);
    const { ref, resizeTextarea } = useTextAreaResize();
    const handleTextChange = (e) => {
        changeSlideTitle(e.target.value);
        resizeTextarea(ref);
    };

    useEffect(() => {
        if (!isInPreview) resizeTextarea(ref); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInPreview]);

    return (
        <QuizWrap>
            <FieldWrap>
                <InputWrap
                    customStyles={cn(
                        checkAnyErrorForPath(`slides[${slideIdx}].text`) ? "border-red-600 pr-2" : "pr-2",
                    )}>
                    {!isInPreview && (
                        <TextareaInput
                            name={`text-${selectedSlide}`}
                            value={slide.text || ""}
                            ref={ref}
                            handleChange={handleTextChange}
                            label={"Descrierea videoclipului (Obligatoriu)"}
                            customStyles={slide.text ? "font-black" : "sr-only"}
                        />
                    )}
                    {isInPreview && <PreviewTextBox text={slide.text} />}
                    <ErrorSpan
                        text={retrieveErrorForPath(`slides[${slideIdx}].text`)}
                        customStyles={cn(checkAnyErrorForPath(`slides[${slideIdx}].text`) ? "flex" : "hidden")}
                    />
                </InputWrap>
            </FieldWrap>
            {!isInPreview && (
                <Fragment>
                    <FieldWrap>
                        <InputWrap
                            customStyles={cn(
                                checkAnyErrorForPath(`slides[${slideIdx}].video`) ? "border-red-600 pr-2" : "pr-2",
                            )}>
                            <TextInput
                                name={`video-${selectedSlide}`}
                                value={slide.video || ""}
                                handleChange={handleVideoIdChange}
                                label={"Id Videoclip"}
                                customStyles={slide.video ? "font-black" : "sr-only"}
                            />
                            <ErrorSpan
                                text={retrieveErrorForPath(`slides[${slideIdx}].video`)}
                                customStyles={cn(checkAnyErrorForPath(`slides[${slideIdx}].video`) ? "flex" : "hidden")}
                            />
                        </InputWrap>
                    </FieldWrap>
                    <FieldWrap customStyles="flex space-x-4">
                        <InputWrap
                            customStyles={cn(
                                checkAnyErrorForPath(`slides[${slideIdx}].videoStart`) ? "border-red-600 pr-2" : "pr-2",
                            )}>
                            <NumberInput
                                name={`start-minutes-${selectedSlide}`}
                                value={startMinutes.toString()}
                                handleChange={handleStartMinutesChange}
                                label={"Minut Start"}
                                customStyles={
                                    ![undefined, null, ""].includes(startMinutes.toString()) ? "font-black" : "sr-only"
                                }
                            />
                            <ErrorSpan
                                text={retrieveErrorForPath(`slides[${slideIdx}].videoStart`)}
                                customStyles={cn(
                                    checkAnyErrorForPath(`slides[${slideIdx}].videoStart`) ? "flex" : "hidden",
                                )}
                            />
                        </InputWrap>
                        <InputWrap
                            customStyles={cn(
                                checkAnyErrorForPath(`slides[${slideIdx}].videoStart`) ? "border-red-600 pr-2" : "pr-2",
                            )}>
                            <NumberInput
                                name={`start-seconds-${selectedSlide}`}
                                value={startSeconds.toString()}
                                handleChange={handleStartSecondsChange}
                                label={"Secunda Start"}
                                customStyles={
                                    ![undefined, null, ""].includes(startSeconds.toString()) ? "font-black" : "sr-only"
                                }
                            />
                            <ErrorSpan
                                text={retrieveErrorForPath(`slides[${slideIdx}].videoStart`)}
                                customStyles={cn(
                                    checkAnyErrorForPath(`slides[${slideIdx}].videoStart`) ? "flex" : "hidden",
                                )}
                            />
                        </InputWrap>
                    </FieldWrap>
                    <FieldWrap customStyles="flex space-x-4">
                        <InputWrap
                            customStyles={cn(
                                checkAnyErrorForPath(`slides[${slideIdx}].videoEnd`) ? "border-red-600 pr-2" : "pr-2",
                            )}>
                            <NumberInput
                                name={`end-minutes-${selectedSlide}`}
                                value={endMinutes.toString()}
                                handleChange={handleEndMinutesChange}
                                label={"Minut Final"}
                                customStyles={
                                    ![undefined, null, ""].includes(endMinutes.toString()) ? "font-black" : "sr-only"
                                }
                            />
                            <ErrorSpan
                                text={retrieveErrorForPath(`slides[${slideIdx}].videoEnd`)}
                                customStyles={cn(
                                    checkAnyErrorForPath(`slides[${slideIdx}].videoEnd`) ? "flex" : "hidden",
                                )}
                            />
                        </InputWrap>
                        <InputWrap
                            customStyles={cn(
                                checkAnyErrorForPath(`slides[${slideIdx}].videoEnd`) ? "border-red-600 pr-2" : "pr-2",
                            )}>
                            <NumberInput
                                name={`end-seconds-${selectedSlide}`}
                                value={endSeconds.toString()}
                                handleChange={handleEndSecondsChange}
                                label={"Secunda Final"}
                                customStyles={
                                    ![undefined, null, ""].includes(endSeconds.toString()) ? "font-black" : "sr-only"
                                }
                            />
                            <ErrorSpan
                                text={retrieveErrorForPath(`slides[${slideIdx}].videoEnd`)}
                                customStyles={cn(
                                    checkAnyErrorForPath(`slides[${slideIdx}].videoEnd`) ? "flex" : "hidden",
                                )}
                            />
                        </InputWrap>
                    </FieldWrap>
                </Fragment>
            )}
            {opts && slide.video && validateTimes(false) && (
                <FieldWrap>
                    <YouTube videoId={slide.video} opts={opts} onStateChange={onPlayerStateChange} />
                </FieldWrap>
            )}
        </QuizWrap>
    );
}

VideoSlide.propTypes = {
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired,
    isInPreview: PropTypes.bool.isRequired,
    setSelectedSlide: PropTypes.func.isRequired,
    changeSlideTitle: PropTypes.func.isRequired,
    retrieveErrorForPath: PropTypes.func.isRequired,
    checkAnyErrorForPath: PropTypes.func.isRequired,
    selectedSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};
