import { checkAnyErrorForPath, cn, formatUnixTimestamp, retrieveErrorForPath } from "../../utils.js";
import BooleanInputBox from "../../components/forms/final-input-boxes/boolean-input-box.jsx";
import SelectInputBox from "../../components/forms/final-input-boxes/select-input-box.jsx";
import NumberInputBox from "../../components/forms/final-input-boxes/number-input-box.jsx";
import TextInputBox from "../../components/forms/final-input-boxes/text-input-box.jsx";
import PreviewTextBox from "../../components/quizzes/creation/preview-text-box.jsx";
import useWasteCollectionForm from "../../hooks/forms/use-waste-collection-form.js";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import FieldWrap from "../../components/forms/wraps/field-wrap.jsx";
import InputWrap from "../../components/forms/wraps/input-wrap.jsx";
import { FaImage, FaRotate, FaTrashCan } from "react-icons/fa6";
import { Button } from "../../components/schadcn-ui/button.jsx";
import ErrorSpan from "../../components/layout/error-span.jsx";
import Dropzone from "react-dropzone";
import { Fragment } from "react";

export default function WasteCollection() {
    const {
        errors,
        formData,
        counties,
        removePhoto,
        handleDZClick,
        actionsDisabled,
        renderWasteForm,
        handleImageUpload,
        saveWasteCollectionForm,
        handleAddressFieldChange,
        handleStandardFieldChange,
    } = useWasteCollectionForm();

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap>
                    <div>
                        <PreviewTextBox text={"Formular de preluare a deseurilor"} />
                    </div>

                    {Object.keys(formData).length > 0 && (
                        <Fragment>
                            <TextInputBox
                                name={"contactPersonName"}
                                label={"Denumire Persoana Contact"}
                                value={formData["contactPersonName"] || ""}
                                errors={errors}
                                disabled={actionsDisabled}
                                handleChange={handleStandardFieldChange}
                            />
                            <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                <TextInputBox
                                    name={"contactPersonEmail"}
                                    label={"Email Persoana Contact"}
                                    value={formData["contactPersonEmail"] || ""}
                                    errors={errors}
                                    disabled={actionsDisabled}
                                    handleChange={handleStandardFieldChange}
                                />
                                <TextInputBox
                                    name={"contactPersonPhone"}
                                    label={"Telefon Persoana Contact"}
                                    value={formData["contactPersonPhone"] || ""}
                                    errors={errors}
                                    disabled={actionsDisabled}
                                    handleChange={handleStandardFieldChange}
                                />
                            </div>
                            <TextInputBox
                                name={"dataOrientativaColectare"}
                                label={"Data orientativa de ridicare"}
                                value={formatUnixTimestamp(formData["dataOrientativaColectare"]) || ""}
                                disabled={true}
                                errors={errors}
                                handleChange={handleStandardFieldChange}
                            />

                            <BooleanInputBox
                                handleChange={handleStandardFieldChange}
                                errors={errors}
                                disabled={actionsDisabled}
                                name={"pickupAddressSameWithSchoolAddress"}
                                value={formData["pickupAddressSameWithSchoolAddress"]}
                                label={"Adresa de colectare corespunde cu adresa scolii?"}
                            />

                            {formData["pickupAddressSameWithSchoolAddress"] !== null && (
                                <Fragment>
                                    <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                        <SelectInputBox
                                            options={[...counties]}
                                            errors={errors}
                                            display={{ value: "value", keys: ["value"] }}
                                            name={"pickupAddress.judet"}
                                            label={"Judet"}
                                            emptyValueLabel={"Selectati judetul de livrare"}
                                            handleChange={(e) => handleAddressFieldChange(e)}
                                            value={formData?.["pickupAddress"]?.["judet"] || ""}
                                            disabled={
                                                !!formData["pickupAddressSameWithSchoolAddress"] || actionsDisabled
                                            }
                                        />
                                        <TextInputBox
                                            name={"pickupAddress.oras"}
                                            label={"Oras"}
                                            disabled={
                                                !!formData["pickupAddressSameWithSchoolAddress"] || actionsDisabled
                                            }
                                            value={formData["pickupAddress"]["oras"] || ""}
                                            errors={errors}
                                            handleChange={handleAddressFieldChange}
                                        />
                                    </div>
                                    <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                        <TextInputBox
                                            name={"pickupAddress.comuna"}
                                            label={"Comuna"}
                                            disabled={
                                                !!formData["pickupAddressSameWithSchoolAddress"] || actionsDisabled
                                            }
                                            value={formData["pickupAddress"]["comuna"] || ""}
                                            errors={errors}
                                            handleChange={handleAddressFieldChange}
                                        />
                                        <TextInputBox
                                            name={"pickupAddress.sat"}
                                            label={"Sat"}
                                            disabled={
                                                !!formData["pickupAddressSameWithSchoolAddress"] || actionsDisabled
                                            }
                                            value={formData["pickupAddress"]["sat"] || ""}
                                            errors={errors}
                                            handleChange={handleAddressFieldChange}
                                        />
                                    </div>
                                    <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                        <TextInputBox
                                            name={"pickupAddress.strada"}
                                            label={"Strada"}
                                            disabled={
                                                !!formData["pickupAddressSameWithSchoolAddress"] || actionsDisabled
                                            }
                                            value={formData["pickupAddress"]["strada"] || ""}
                                            errors={errors}
                                            handleChange={handleAddressFieldChange}
                                        />
                                        <TextInputBox
                                            name={"pickupAddress.numar"}
                                            label={"Numar"}
                                            disabled={
                                                !!formData["pickupAddressSameWithSchoolAddress"] || actionsDisabled
                                            }
                                            value={formData["pickupAddress"]["numar"] || ""}
                                            errors={errors}
                                            handleChange={handleAddressFieldChange}
                                        />
                                    </div>

                                    <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                        <TextInputBox
                                            name={"pickupAddress.bloc"}
                                            label={"Bloc"}
                                            disabled={
                                                !!formData["pickupAddressSameWithSchoolAddress"] || actionsDisabled
                                            }
                                            value={formData["pickupAddress"]["bloc"] || ""}
                                            errors={errors}
                                            handleChange={handleAddressFieldChange}
                                        />
                                        <TextInputBox
                                            name={"pickupAddress.scara"}
                                            label={"Scara"}
                                            disabled={
                                                !!formData["pickupAddressSameWithSchoolAddress"] || actionsDisabled
                                            }
                                            value={formData["pickupAddress"]["scara"] || ""}
                                            errors={errors}
                                            handleChange={handleAddressFieldChange}
                                        />
                                    </div>

                                    <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                        <TextInputBox
                                            name={"pickupAddress.etaj"}
                                            label={"Etaj"}
                                            disabled={
                                                !!formData["pickupAddressSameWithSchoolAddress"] || actionsDisabled
                                            }
                                            value={formData["pickupAddress"]["etaj"] || ""}
                                            errors={errors}
                                            handleChange={handleAddressFieldChange}
                                        />
                                        <TextInputBox
                                            name={"pickupAddress.apartament"}
                                            label={"Apartament"}
                                            disabled={
                                                !!formData["pickupAddressSameWithSchoolAddress"] || actionsDisabled
                                            }
                                            value={formData["pickupAddress"]["apartament"] || ""}
                                            errors={errors}
                                            handleChange={handleAddressFieldChange}
                                        />
                                    </div>

                                    <TextInputBox
                                        name={"pickupAddress.codPostal"}
                                        label={"Cod postal"}
                                        disabled={!!formData["pickupAddressSameWithSchoolAddress"] || actionsDisabled}
                                        value={formData["pickupAddress"]["codPostal"] || ""}
                                        errors={errors}
                                        handleChange={handleAddressFieldChange}
                                    />
                                </Fragment>
                            )}

                            <NumberInputBox
                                name={"deeeKg"}
                                label={"Kilograme de DEEE"}
                                value={formData["deeeKg"] || ""}
                                errors={errors}
                                disabled={actionsDisabled}
                                handleChange={handleStandardFieldChange}
                            />
                            <NumberInputBox
                                name={"dbaKg"}
                                label={"Kilograme de DBA"}
                                value={formData["dbaKg"] || ""}
                                errors={errors}
                                disabled={actionsDisabled}
                                handleChange={handleStandardFieldChange}
                            />
                            <NumberInputBox
                                name={"becuriSiNeoaneKg"}
                                label={"Kilograme de becuri si neoane"}
                                value={formData["becuriSiNeoaneKg"] || ""}
                                errors={errors}
                                disabled={actionsDisabled}
                                handleChange={handleStandardFieldChange}
                            />
                            <FieldWrap customStyles={"animate-fade"}>
                                <div className="flex h-full flex-1 items-center justify-center">
                                    <InputWrap
                                        customStyles={cn(
                                            checkAnyErrorForPath(`photos`, errors) ? "border-red-600" : "p-0",
                                        )}>
                                        <div
                                            onClick={handleDZClick}
                                            className={cn(
                                                "group relative flex h-full w-full flex-1 cursor-pointer flex-col items-center justify-center rounded-[5px] text-center text-magenta/80 transition duration-500 hover:bg-magenta/80 hover:text-white",
                                            )}>
                                            <Dropzone onDrop={handleImageUpload} disabled={actionsDisabled}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="dz-message needsclick;" {...getRootProps()}>
                                                        <input
                                                            multiple={true}
                                                            disabled={actionsDisabled}
                                                            id="question-image-upload"
                                                            {...getInputProps({ accept: "image/*" })}
                                                        />
                                                    </div>
                                                )}
                                            </Dropzone>
                                            {Array.isArray(formData["photos"]) && formData["photos"].length > 0 ? (
                                                <div className="flex flex-wrap">
                                                    {formData["photos"].map((photo, index) => (
                                                        <div key={index} className="relative m-2 h-32 w-32">
                                                            <img
                                                                src={photo}
                                                                alt={`Uploaded ${index}`}
                                                                className="h-full w-full cursor-default object-cover"
                                                            />
                                                            <button
                                                                disabled={actionsDisabled}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    removePhoto(index);
                                                                }}
                                                                className="absolute right-1 top-1 rounded-full bg-red-500 p-1 text-white">
                                                                <FaTrashCan />
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div className={"flex flex-col items-center space-x-4 p-4"}>
                                                    <FaImage style={{ fontSize: "2.3em" }} />
                                                    <p className="m-0 text-lg font-semibold text-gray-400 transition duration-500 group-hover:text-white">
                                                        Atasati poze cu deseurile
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        <ErrorSpan
                                            text={retrieveErrorForPath(`photos`, errors)}
                                            customStyles={cn(
                                                checkAnyErrorForPath(`photos`, errors) ? "flex" : "hidden",
                                            )}
                                        />
                                    </InputWrap>
                                </div>
                            </FieldWrap>
                            <BooleanInputBox
                                handleChange={handleStandardFieldChange}
                                errors={errors}
                                disabled={actionsDisabled}
                                name={"consent"}
                                value={formData["consent"]}
                                label={
                                    "Prin prezenta confirm că am citit Informarea cu privire la prelucrarea datelor mele cu caracter personal prezentata pe www.ecotic.ro/politica-de-confidentialitate-si-de-prelucrare-datelor-cu-caracter-personal"
                                }
                            />
                            <div
                                className={
                                    "flex w-full flex-col items-center justify-center space-y-4 p-2 sm:flex-row sm:space-x-4 sm:space-y-0"
                                }>
                                <Button
                                    className={"w-full sm:w-auto"}
                                    disabled={actionsDisabled}
                                    onClick={() => renderWasteForm()}>
                                    <FaRotate className={"mr-2"} />
                                    <span>Reincarcare</span>
                                </Button>
                                <Button
                                    className={"w-full sm:w-auto"}
                                    disabled={actionsDisabled}
                                    onClick={() => saveWasteCollectionForm()}>
                                    <span>Salvare</span>
                                </Button>
                            </div>
                        </Fragment>
                    )}
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    );
}
