import { QUESTION_SLIDE_COLORS } from "../../../../constants/question-slide-colors.js";
import PreviewTitle from "./preview-title.jsx";
import PreviewWrap from "./preview-wrap.jsx";
import { cn } from "../../../../utils.js";
import PropTypes from "prop-types";

export default function QuestionPreview({ selectSlide, selectedSlide, slide, checkAnyErrorForPath, slideIdx }) {
    return (
        <PreviewWrap
            selectSlide={selectSlide}
            selectedSlide={selectedSlide}
            slide={slide}
            checkAnyErrorForPath={checkAnyErrorForPath}
            slideIdx={slideIdx}>
            <PreviewTitle titlePlaceholder={"Intrebare"} title={slide.text} />
            <div className={"h-full"} />
            <div className={"grid h-full w-full grid-cols-2 gap-1 self-end p-1"}>
                {slide.options.map((option, optIdx) => {
                    if (optIdx < 6)
                        return (
                            <div
                                key={optIdx}
                                className={cn(
                                    option.correct
                                        ? QUESTION_SLIDE_COLORS[optIdx].completionStyles
                                        : "border-gray-400 bg-gray-200 text-white ring-gray-400",
                                    "h-[9px] grid-cols-1 self-center rounded-sm border",
                                )}
                            />
                        );
                })}
            </div>
        </PreviewWrap>
    );
}
QuestionPreview.propTypes = {
    checkAnyErrorForPath: PropTypes.func.isRequired,
    selectSlide: PropTypes.func.isRequired,
    selectedSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    slide: PropTypes.object.isRequired,
    slideIdx: PropTypes.number.isRequired,
};
