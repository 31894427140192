import { Button } from "../../schadcn-ui/button.jsx";
import PropTypes from "prop-types";

export default function ExportButton({ handleExport, disabled }) {
    return (
        <Button onClick={() => handleExport()} disabled={disabled} className={"w-full sm:w-auto"}>
            <span className={"pl-2"}>Export</span>
        </Button>
    );
}
ExportButton.propTypes = {
    handleExport: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};
