import { forwardRef, Fragment } from "react";
import { cn } from "../../../utils.js";
import PropTypes from "prop-types";

// eslint-disable-next-line react/display-name
const TextareaInput = forwardRef(
    (
        {
            name,
            value,
            handleChange,
            rows = 1,
            cols = 50,
            label,
            customStyles = "",
            inputStyles = "",
            disabled = false,
        },
        ref,
    ) => {
        return (
            <Fragment>
                <label
                    htmlFor={name}
                    className={cn(
                        customStyles,
                        disabled ? "opacity-50" : "",
                        "w-full text-center font-bold text-gray-400",
                    )}>
                    {label}
                </label>
                <textarea
                    name={name}
                    id={name}
                    value={value || ""}
                    placeholder={label}
                    onChange={handleChange}
                    rows={rows}
                    cols={cols}
                    ref={ref}
                    disabled={disabled}
                    className={cn(
                        disabled ? "opacity-50 disabled:cursor-not-allowed" : "",
                        "h-full w-full resize-none appearance-none rounded-xl p-2 text-center text-[16px] font-normal placeholder:font-bold focus:outline-none",
                        inputStyles,
                    )}
                />
            </Fragment>
        );
    },
);

TextareaInput.propTypes = {
    rows: PropTypes.number,
    cols: PropTypes.number,
    disabled: PropTypes.bool,
    inputStyles: PropTypes.string,
    customStyles: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default TextareaInput;
