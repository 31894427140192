import { toastOneError, toastOneWarn, updateFailureToast, updateSuccessToast } from "../../toast.js";
import { useFetchProvider, useRoutesProvider } from "../../providers/provider-hooks.jsx";
import { delay, findChangedPath, trimStringsFromAnywhere } from "../../utils.js";
import { Slide, toast } from "react-toastify";
import { useEffect, useState } from "react";
import _ from "lodash";
const DEFAULT_RECEIVERS = Object.freeze({ sendToTeachers: false, sendToPupils: false });

export default function useSendNotification() {
    const [receivers, setReceivers] = useState(JSON.parse(JSON.stringify(DEFAULT_RECEIVERS)));
    const [oldNotification, setOldNotification] = useState({});
    const [notification, setNotification] = useState({});
    const [errors, setErrors] = useState({});

    const { getNotificationsContacts, getDefaultBulkNotification, postBulkNotifications } = useFetchProvider();
    const { ROLES } = useRoutesProvider();

    async function cancelNotification() {
        if (!notification.subject && !notification.content) return toastOneWarn("Aceast mesaj este deja gol.");
        setNotification({});
        await renderBulkNotification();
    }

    async function renderBulkNotification(withToast = true) {
        let id;
        if (withToast) id = toast.loading(`Pagina se incarca...`, { transition: Slide });
        try {
            await delay(1000);
            setNotification(await getDefaultBulkNotification());
            if (withToast) return toast.dismiss(id);
        } catch (error) {
            withToast
                ? updateFailureToast(error.message || "Pagina nu s-a putut incarca.", id)
                : toastOneError(error.message || "Pagina nu s-a putut incarca.");
            console.error(error);
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
        const newNotification = { ...notification, [name]: value };
        setNotification(newNotification);
    }

    function handleReceiversChange(e) {
        const { name, value } = e.target;
        const newReceivers = { ...receivers, [name]: value };
        setReceivers(newReceivers);
    }

    async function sendNotification() {
        const id = toast.loading(`Mesajul se trimite...`, { transition: Slide });
        try {
            await delay(1000);
            console.log(receivers);
            if (!receivers.sendToTeachers && !receivers.sendToPupils)
                return updateFailureToast("Drept destinatari nu au fost bifati nici elevii, nici profesorii.", id);
            const rawUsers = await getNotificationsContacts();
            const notificationToBeSentTo = [];
            if (receivers.sendToTeachers) notificationToBeSentTo.push(ROLES.PROFESOR);
            if (receivers.sendToPupils) notificationToBeSentTo.push(ROLES.ELEV);
            notification.receiverIds = rawUsers
                .filter((user) => notificationToBeSentTo.includes(user.role))
                .map((user) => user["id"]);
            const trimmedData = trimStringsFromAnywhere(notification);
            await postBulkNotifications(trimmedData);
            setNotification({});
            setOldNotification({});
            setErrors({});
            setReceivers(JSON.parse(JSON.stringify(DEFAULT_RECEIVERS)));
            await renderBulkNotification(false);
            return updateSuccessToast("Mesajul a fost trimis", id);
        } catch (error) {
            console.log(error);
            if (error.message || error.errors) {
                if (error.message) updateFailureToast(error.message, id);
                if (error.errors && Object.keys(error.errors).length > 0) setErrors(error.errors);
                return;
            }
            updateFailureToast("Mesajul nu a putut fi trimis.", id);
        }
    }

    useEffect(() => {
        const changedPath = findChangedPath(notification, oldNotification);
        if (changedPath) {
            const errorPath = Object.keys(errors).find((errorKey) => errorKey === changedPath);
            if (errorPath) {
                const newErrors = JSON.parse(JSON.stringify(errors));
                delete newErrors[errorPath];
                setErrors(newErrors);
            }
            setOldNotification(_.cloneDeep(notification));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification, errors]);

    return {
        renderBulkNotification,
        sendNotification,
        cancelNotification,
        handleChange,
        errors,
        notification,
        receivers,
        handleReceiversChange,
    };
}
