import PropTypes from "prop-types";
import { Fragment } from "react";
import DoubleCheckAction from "../tables/double-check-action.jsx";

export default function AuthorizationAction({ actionsDisabled, authorizeAccount, row, openRejectDialog }) {
    return (
        <Fragment>
            {row["isEnabled"] === false && !row["rejectReason"] && (
                <Fragment>
                    <DoubleCheckAction
                        actionsDisabled={actionsDisabled}
                        buttonMessage={"Autorizare"}
                        spanMessage={"Sunteti de sigur ca doriti sa autorizati acest cont?"}
                        yesAction={() => authorizeAccount(row["userId"], true)}
                        buttonStyles={
                            "disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:no-underline text-green-700 hover:underline focus:outline-none"
                        }
                    />
                    <span>/</span>
                    <button
                        disabled={actionsDisabled}
                        onClick={() => openRejectDialog(row["userId"])}
                        className={
                            "text-red-700 hover:underline focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:no-underline"
                        }>
                        Respingere
                    </button>
                </Fragment>
            )}
            {(row["isEnabled"] === true || row["rejectReason"]) && <span>N/a</span>}
        </Fragment>
    );
}
AuthorizationAction.propTypes = {
    row: PropTypes.object.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
    authorizeAccount: PropTypes.func.isRequired,
    openRejectDialog: PropTypes.func.isRequired,
};
