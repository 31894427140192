import { Button } from "../../schadcn-ui/button.jsx";
import { FaFilterCircleXmark } from "react-icons/fa6";
import PropTypes from "prop-types";

export default function FiltersResetButton({ handleRefresh, disabled }) {
    return (
        <Button onClick={() => handleRefresh()} disabled={disabled} className={"w-full sm:w-auto"}>
            <FaFilterCircleXmark />
            <span className={"pl-2"}>Reset Filtre</span>
        </Button>
    );
}
FiltersResetButton.propTypes = {
    handleRefresh: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};
