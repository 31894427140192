import BodyCell from "../tables/body-cell.jsx";
import BodyRow from "../tables/body-row.jsx";
import PropTypes from "prop-types";

export default function TableBody({ tableData, currentEdition }) {
    return (
        <tbody className={"bg-light"}>
            {tableData.map((row, rowIdx) => {
                return (
                    <BodyRow key={`row-${rowIdx}`} customStyles={"hover:bg-lilac/30"}>
                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["numeInstitutieInvatamant"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["codSe"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["cui"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{currentEdition.nume || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["totalDeeeKg"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["totalDbaKg"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["totalBecuriSiNeoaneKg"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["totalKg"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["totalPointsPracticalActivity"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["totalPointsHomework"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["totalPointsDailyChallenge"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["countApprovedPracticalActivities"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["countApprovedHomework"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["countApprovedDailyChallenge"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["lessonTotalPoints"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["wasteCollectionFormPoints"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["sumTotalPoints"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["mainJudet"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["mainOras"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["mainComuna"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["mainSat"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["mainStrada"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["mainNumar"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["mainCodPostal"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["mainBloc"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["mainScara"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["mainEtaj"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["mainApartament"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["deliveryJudet"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["deliveryOras"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["deliveryComuna"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["deliverySat"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["deliveryStrada"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["deliveryNumar"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["deliveryCodPostal"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["deliveryBloc"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["deliveryScara"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["deliveryEtaj"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["deliveryApartament"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["telefon"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["numeReprezentatLegal"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["professorNume"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["professorEmail"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["professorTelefon"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["numarElevi"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["detineRecipiente"] === true
                                    ? "DA"
                                    : row["detineRecipiente"] === false
                                      ? "NU"
                                      : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["participareEditieAnterioara"] === true
                                    ? "DA"
                                    : row["participareEditieAnterioara"] === false
                                      ? "NU"
                                      : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["adresaLivrareAceeasiCuScoala"] === true
                                    ? "DA"
                                    : row["adresaLivrareAceeasiCuScoala"] === false
                                      ? "NU"
                                      : ""}
                            </div>
                        </BodyCell>
                    </BodyRow>
                );
            })}
        </tbody>
    );
}
TableBody.propTypes = {
    tableData: PropTypes.array.isRequired,
    currentEdition: PropTypes.object,
};
