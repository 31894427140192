import { useAuthProvider, useFetchProvider, useRoutesProvider } from "../../providers/provider-hooks.jsx";
import { delay, findChangedPath, trimStringsFromAnywhere } from "../../utils.js";
import { updateFailureToast } from "../../toast.js";
import { useNavigate } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import _ from "lodash";

const DEFAULT_FORM = Object.freeze({ signature: "", parentApproval: null });

export function useParentalApproval() {
    const navigate = useNavigate();
    const { getUserHomePage } = useRoutesProvider();
    const { patchParentalApproval } = useFetchProvider();
    const { getNewToken, refreshToken } = useAuthProvider();

    const [actionsDisabled, setActionsDisabled] = useState(false);
    const [oldParentalData, setOldParentalData] = useState(JSON.parse(JSON.stringify(DEFAULT_FORM)));
    const [parentalData, setParentalData] = useState(JSON.parse(JSON.stringify(DEFAULT_FORM)));
    const [errors, setErrors] = useState({});

    async function saveParentalData() {
        setActionsDisabled(true);
        const loadError = "Acordul parental nu s-a putut salva.";
        const id = toast.loading("Acordul parental se salveaza...", { transition: Slide });
        try {
            await delay(1000);
            if (!parentalData.signature)
                throw {
                    message: loadError,
                    errors: {
                        signature:
                            "Nu a fost completata semnatura parintelui. Asigurati-va ca dupa completare apasati bifa verde.",
                    },
                };
            const trimmedData = trimStringsFromAnywhere(parentalData);
            await patchParentalApproval(trimmedData);
            const newToken = await getNewToken(refreshToken);
            if (newToken) {
                await delay(500);
                console.log(newToken);
                const userInfo = jwtDecode(newToken.toString());
                console.log(userInfo);
                navigate(getUserHomePage(userInfo["role"]));
                toast.dismiss(id);
            } else updateFailureToast(loadError, id);
        } catch (error) {
            console.log(error);
            if (error.message || error.errors) {
                if (error.errors && Object.keys(error.errors).length > 0) setErrors(error.errors);
                if (error.message) updateFailureToast(error.message, id);
                return;
            }
            updateFailureToast(loadError, id);
        } finally {
            setActionsDisabled(false);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedData = { ...parentalData };
        updatedData[name] = value;
        setParentalData(updatedData);
    };

    useEffect(() => {
        const changedPath = findChangedPath(parentalData, oldParentalData);
        if (changedPath) {
            const errorPath = Object.keys(errors).find((errorKey) => errorKey === changedPath);
            if (errorPath) {
                const newErrors = JSON.parse(JSON.stringify(errors));
                delete newErrors[errorPath];
                setErrors(newErrors);
            }
            setOldParentalData(_.cloneDeep(parentalData));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentalData, errors]);

    return { parentalData, handleChange, errors, actionsDisabled, saveParentalData };
}
