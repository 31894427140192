import {
    UndoRedo,
    MDXEditor,
    CreateLink,
    linkPlugin,
    listsPlugin,
    ListsToggle,
    quotePlugin,
    tablePlugin,
    toolbarPlugin,
    headingsPlugin,
    linkDialogPlugin,
    diffSourcePlugin,
    frontmatterPlugin,
    markdownShortcutPlugin,
    BoldItalicUnderlineToggles,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import PropTypes from "prop-types";
import { cn } from "../../../utils.js";

export default function MarkdownEditor({ name, content, changeContent, componentKey, noLists = false }) {
    return (
        <MDXEditor
            name={name}
            markdown={content || ""}
            key={componentKey}
            onChange={changeContent}
            spellCheck={false}
            plugins={[
                headingsPlugin(),
                listsPlugin(),
                linkPlugin(),
                quotePlugin(),
                tablePlugin(),
                linkDialogPlugin(),
                markdownShortcutPlugin(),
                diffSourcePlugin({ diffMarkdown: "An older version", viewMode: "rich-text" }),
                frontmatterPlugin(),
                toolbarPlugin({
                    toolbarContents: () => (
                        <div
                            className={
                                "flex w-full items-center justify-around space-x-4 self-center overflow-x-auto rounded-md bg-white pb-[1.5px] pt-[3px]"
                            }>
                            <UndoRedo />
                            <CreateLink />
                            <BoldItalicUnderlineToggles />
                            {!noLists && <ListsToggle />}
                        </div>
                    ),
                }),
            ]}
            contentEditableClassName={cn(!content ? "" : "", "p-0 markdown p-0 rounded-md")}
        />
    );
}
MarkdownEditor.propTypes = {
    name: PropTypes.string,
    noLists: PropTypes.bool,
    content: PropTypes.string.isRequired,
    changeContent: PropTypes.func.isRequired,
    componentKey: PropTypes.string.isRequired,
};
