import { useAuthProvider, useRoutesProvider } from "../../../providers/provider-hooks.jsx";
import { CalendarRangePicker } from "../tables/calendar-range-picker.jsx";
import BooleanFilterInput from "../tables/boolean-filter-input.jsx";
import SearchInput from "../tables/search-input.jsx";
import SortButton from "../tables/sort-button.jsx";
import HeadCell from "../tables/head-cell.jsx";
import HeadRow from "../tables/head-row.jsx";
import { cn } from "../../../utils.js";
import PropTypes from "prop-types";

export default function TableHeaders({ actionsDisabled, params, changeParams }) {
    const { authState } = useAuthProvider();
    const { ROLES } = useRoutesProvider();

    return (
        <thead className={cn("sticky top-0 z-10 bg-light font-bold")}>
            <HeadRow>
                {[ROLES.DBEST, ROLES.ADMIN, ROLES.LOGISTIC].includes(authState.userInfo.role) && (
                    <HeadCell colSpan={1}>
                        <div className="flex items-center space-y-1 py-2">
                            <span className={"text-nowrap"}>Actiuni Administrator</span>
                        </div>
                    </HeadCell>
                )}

                {[ROLES.DBEST, ROLES.ADMIN, ROLES.LOGISTIC].includes(authState.userInfo.role) && (
                    <HeadCell colSpan={1}>
                        <div className="flex items-center space-y-1 py-2">
                            <span className={"text-nowrap"}>Actiuni Logistica</span>
                        </div>
                    </HeadCell>
                )}

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <span className={"text-nowrap"}>Poze Deseuri</span>
                    </div>
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <span className={"text-nowrap"}>Procese Verbale Comenzi DEEE</span>
                    </div>
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <span className={"text-nowrap"}>Procese Verbale Comenzi DBA</span>
                    </div>
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["editionId"] || ""}
                            name={"editionId"}
                        />
                        <span className={"text-nowrap"}>Editia</span>
                    </div>
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"timestamp"}
                        />
                        <span className={"text-nowrap"}>Data plasare comanda</span>
                    </div>
                    <CalendarRangePicker
                        handleChange={changeParams}
                        fromName={"timestampRange.start"}
                        toName={"timestampRange.end"}
                        from={params["timestampRange.start"] || ""}
                        to={params["timestampRange.end"] || ""}
                        disabled={actionsDisabled}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"validatedAt"}
                        />
                        <span className={"text-nowrap"}>Data validare</span>
                    </div>
                    <CalendarRangePicker
                        handleChange={changeParams}
                        fromName={"validatedAtRange.start"}
                        toName={"validatedAtRange.end"}
                        from={params["validatedAtRange.start"] || ""}
                        to={params["validatedAtRange.end"] || ""}
                        disabled={actionsDisabled}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"deeeOrderProcessedDate"}
                        />
                        <span className={"text-nowrap"}>Data procesare DEEE</span>
                    </div>
                    <CalendarRangePicker
                        handleChange={changeParams}
                        fromName={"deeeOrderProcessedDateRange.start"}
                        toName={"deeeOrderProcessedDateRange.end"}
                        from={params["deeeOrderProcessedDateRange.start"] || ""}
                        to={params["deeeOrderProcessedDateRange.end"] || ""}
                        disabled={actionsDisabled}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"dbaOrderProcessedDate"}
                        />
                        <span className={"text-nowrap"}>Data procesare DBA</span>
                    </div>
                    <CalendarRangePicker
                        handleChange={changeParams}
                        fromName={"dbaOrderProcessedDateRange.start"}
                        toName={"dbaOrderProcessedDateRange.end"}
                        from={params["dbaOrderProcessedDateRange.start"] || ""}
                        to={params["dbaOrderProcessedDateRange.end"] || ""}
                        disabled={actionsDisabled}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"dataOrientativaColectare"}
                        />
                        <span className={"text-nowrap"}>Data orientativa colectare</span>
                    </div>
                    <CalendarRangePicker
                        handleChange={changeParams}
                        fromName={"dataOrientativaColectareDateRange.start"}
                        toName={"dataOrientativaColectareDateRange.end"}
                        from={params["dataOrientativaColectareDateRange.start"] || ""}
                        to={params["dataOrientativaColectareDateRange.end"] || ""}
                        disabled={actionsDisabled}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"school.codSiiirUnitate"}
                        />
                        <span className={"text-nowrap"}>Cod SIIIR</span>
                    </div>
                    <SearchInput
                        placeholder={"Cod SIIIR..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"school.codSiiirUnitate"}
                        value={params["school.codSiiirUnitate"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"deeeOrderNumber"}
                        />
                        <span className={"text-nowrap"}>Cod comanda DEEE</span>
                    </div>
                    <SearchInput
                        placeholder={"Cod comanda DEEE..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"deeeOrderNumber"}
                        value={params["deeeOrderNumber"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"dbaOrderNumber"}
                        />
                        <span className={"text-nowrap"}>Cod comanda DBA</span>
                    </div>
                    <SearchInput
                        placeholder={"Cod comanda DBA..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"dbaOrderNumber"}
                        value={params["dbaOrderNumber"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"school.numeInstitutieInvatamant"}
                        />
                        <span className={"text-nowrap"}>Denumire institutie invatamant</span>
                    </div>
                    <SearchInput
                        placeholder={"Denumire institutie invatamant..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"school.numeInstitutieInvatamant"}
                        value={params["school.numeInstitutieInvatamant"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"school.codSeNumeric"}
                        />
                        <span className={"text-nowrap"}>Cod SE</span>
                    </div>
                    <SearchInput
                        placeholder={"Cod SE..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"school.codSe"}
                        value={params["school.codSe"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"school.cui"}
                        />
                        <span className={"text-nowrap"}>CUI</span>
                    </div>
                    <SearchInput
                        placeholder={"CUI..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"school.cui"}
                        value={params["school.cui"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"school.professor.nume"}
                        />
                        <span className={"text-nowrap"}>Nume profesor</span>
                    </div>
                    <SearchInput
                        placeholder={"Nume profesor..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"school.professor.nume"}
                        value={params["school.professor.nume"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"school.professor.email"}
                        />
                        <span className={"text-nowrap"}>Email profesor</span>
                    </div>
                    <SearchInput
                        placeholder={"Email profesor..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"school.professor.email"}
                        value={params["school.professor.email"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"school.professor.telefon"}
                        />
                        <span className={"text-nowrap"}>Telefon profesor</span>
                    </div>
                    <SearchInput
                        placeholder={"Telefon profesor..."}
                        disabled={actionsDisabled}
                        handleChange={changeParams}
                        name={"school.professor.telefon"}
                        value={params["school.professor.telefon"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"pickupAddress.judet"}
                        />
                        <span className={"text-nowrap"}>Judet adresa colectare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Judet adresa colectare..."}
                        name={`pickupAddress.judet`}
                        value={params[`pickupAddress.judet`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"pickupAddress.oras"}
                        />
                        <span className={"text-nowrap"}>Oras adresa colectare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        name={`pickupAddress.oras`}
                        disabled={actionsDisabled}
                        placeholder={"Oras adresa colectare..."}
                        value={params[`pickupAddress.oras`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"pickupAddress.comuna"}
                        />
                        <span className={"text-nowrap"}>Comuna adresa colectare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Comuna adresa colectare..."}
                        name={`pickupAddress.comuna`}
                        value={params[`pickupAddress.comuna`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"pickupAddress.sat"}
                        />
                        <span className={"text-nowrap"}>Sat adresa colectare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Sat adresa colectare..."}
                        name={`pickupAddress.sat`}
                        value={params[`pickupAddress.sat`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"pickupAddress.strada"}
                        />
                        <span className={"text-nowrap"}>Strada adresa colectare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Strada adresa colectare..."}
                        name={`pickupAddress.strada`}
                        value={params[`pickupAddress.strada`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"pickupAddress.numar"}
                        />
                        <span className={"text-nowrap"}>Numar adresa colectare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Numar adresa colectare..."}
                        name={`pickupAddress.numar`}
                        value={params[`pickupAddress.numar`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"pickupAddress.bloc"}
                        />
                        <span className={"text-nowrap"}>Bloc adresa colectare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Bloc adresa colectare..."}
                        name={`pickupAddress.bloc`}
                        value={params[`pickupAddress.bloc`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"pickupAddress.scara"}
                        />
                        <span className={"text-nowrap"}>Scara bloc adresa colectare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Scara bloc adresa colectare..."}
                        name={`pickupAddress.scara`}
                        value={params[`pickupAddress.scara`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"pickupAddress.etaj"}
                        />
                        <span className={"text-nowrap"}>Etaj bloc adresa colectare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Etaj bloc adresa colectare..."}
                        name={`pickupAddress.etaj`}
                        value={params[`pickupAddress.etaj`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"pickupAddress.apartament"}
                        />
                        <span className={"text-nowrap"}>Apartament adresa colectare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Apartament adresa colectare..."}
                        name={`pickupAddress.apartament`}
                        value={params[`pickupAddress.apartament`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"pickupAddress.codPostal"}
                        />
                        <span className={"text-nowrap"}>Cod postal adresa colectare</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        placeholder={"Cod postal adresa colectare..."}
                        name={`pickupAddress.codPostal`}
                        value={params[`pickupAddress.codPostal`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"contactPersonName"}
                        />
                        <span className={"text-nowrap"}>Nume contact</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        name={"contactPersonName"}
                        disabled={actionsDisabled}
                        placeholder={"Nume contact..."}
                        value={params["contactPersonName"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"contactPersonPhone"}
                        />
                        <span className={"text-nowrap"}>Telefon contact</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        name={"contactPersonPhone"}
                        disabled={actionsDisabled}
                        placeholder={"Telefon contact..."}
                        value={params["contactPersonPhone"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"contactPersonEmail"}
                        />
                        <span className={"text-nowrap"}>Email contact</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        name={"contactPersonEmail"}
                        disabled={actionsDisabled}
                        placeholder={"Email contact..."}
                        value={params["contactPersonEmail"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"deeeKg"}
                        />
                        <span className={"text-nowrap"}>Kilograme DEEE estimate</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        name={`deeeKg`}
                        disabled={actionsDisabled}
                        placeholder={"Kilograme DEEE estimate..."}
                        value={params["deeeKg"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"dbaKg"}
                        />
                        <span className={"text-nowrap"}>Kilograme DBA estimate</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        name={`dbaKg`}
                        disabled={actionsDisabled}
                        placeholder={"Kilograme DBA estimate..."}
                        value={params["dbaKg"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"becuriSiNeoaneKg"}
                        />
                        <span className={"text-nowrap"}>Kilograme BECURI si NEOANE estimate</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        name={`becuriSiNeoaneKg`}
                        disabled={actionsDisabled}
                        placeholder={"Kilograme BECURI si NEOANE estimate..."}
                        value={params["becuriSiNeoaneKg"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"realDeeeKg"}
                        />
                        <span className={"text-nowrap"}>Kilograme DEEE finale</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        name={`realDeeeKg`}
                        disabled={actionsDisabled}
                        placeholder={"Kilograme DEEE finale..."}
                        value={params["realDeeeKg"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"realDbaKg"}
                        />
                        <span className={"text-nowrap"}>Kilograme DBA finale</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        name={`realDbaKg`}
                        disabled={actionsDisabled}
                        placeholder={"Kilograme DBA finale..."}
                        value={params["realDbaKg"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"realBecuriSiNeoaneKg"}
                        />
                        <span className={"text-nowrap"}>Kilograme BECURI si NEOANE finale</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        name={`realBecuriSiNeoaneKg`}
                        disabled={actionsDisabled}
                        placeholder={"Kilograme BECURI si NEOANE finale..."}
                        value={params["realBecuriSiNeoaneKg"] || ""}
                        type={"number"}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"isValidated"}
                        />
                        <span className={"text-nowrap"}>Validat</span>
                    </div>
                    <BooleanFilterInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        name={`isValidated`}
                        value={params[`isValidated`] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"rejectReason"}
                        />
                        <span className={"text-nowrap"}>Motiv respingere (daca este cazul)</span>
                    </div>
                    <SearchInput
                        handleChange={changeParams}
                        name={"rejectReason"}
                        disabled={true}
                        placeholder={"Motiv respingere..."}
                        value={params["rejectReason"] || ""}
                    />
                </HeadCell>

                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton
                            handleChange={changeParams}
                            disabled={actionsDisabled}
                            value={params["sort"] || ""}
                            name={"isProcessed"}
                        />
                        <span className={"text-nowrap"}>Comanda transmisa</span>
                    </div>
                    <BooleanFilterInput
                        handleChange={changeParams}
                        disabled={actionsDisabled}
                        name={`isProcessed`}
                        value={params[`isProcessed`] || ""}
                    />
                </HeadCell>
            </HeadRow>
        </thead>
    );
}
TableHeaders.propTypes = {
    actionsDisabled: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    changeParams: PropTypes.func.isRequired,
};
