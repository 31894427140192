import TextInputBox from "../../forms/final-input-boxes/text-input-box.jsx";
import { Fragment, forwardRef, useEffect, useState } from "react";
import { Button } from "../../schadcn-ui/button.jsx";
import { FaXmark } from "react-icons/fa6";
import PropTypes from "prop-types";
import { toastOneWarn } from "../../../toast.js";

const EmailDialog = forwardRef(
    (
        {
            email,
            saveEmail,
            changeEmail,
            actionsDisabled,
            emailChangeErrors,
            closeEmailChangeDialog,
            isEmailChangeDialogOpened,
        },
        ref,
    ) => {
        const [confirmation, setConfirmation] = useState("");

        useEffect(() => {
            if (!isEmailChangeDialogOpened) setConfirmation("");
        }, [isEmailChangeDialogOpened]);

        return (
            <Fragment>
                {isEmailChangeDialogOpened && (
                    <div className="fixed left-0 top-0 z-50 flex h-screen w-screen animate-fade cursor-default items-center justify-center bg-black/50 backdrop-blur-sm animate-duration-200">
                        <div
                            ref={ref}
                            className="relative flex max-h-[80vh] min-w-[40vw] max-w-[80vw] flex-col overflow-hidden rounded-lg bg-light p-4 shadow-lg">
                            <div className={"relative flex justify-end pb-4"}>
                                <button onClick={() => closeEmailChangeDialog()} className="">
                                    <FaXmark />
                                </button>
                            </div>
                            <div className="relative flex h-full max-h-[80vh] w-full min-w-[40vw] max-w-[80vw] select-text flex-col overflow-hidden">
                                <TextInputBox
                                    value={email}
                                    name="newWmail"
                                    handleChange={changeEmail}
                                    disabled={actionsDisabled}
                                    errors={emailChangeErrors}
                                    label={`Noul email al utilizatorului`}
                                />
                                <TextInputBox
                                    name="confirmation"
                                    value={confirmation}
                                    disabled={actionsDisabled}
                                    label={`Pentru confirmare tastati in aceasta caseta cuvantul: CONFIRM`}
                                    handleChange={(e) => setConfirmation(e.target.value)}
                                />
                                <div className="flex flex-row justify-between space-x-4 px-2 pt-4">
                                    <Button
                                        className={"w-full"}
                                        disabled={actionsDisabled}
                                        onClick={() => closeEmailChangeDialog()}>
                                        Anulare
                                    </Button>
                                    <Button
                                        className="w-full"
                                        disabled={actionsDisabled}
                                        onClick={async () => {
                                            if (confirmation === "CONFIRM") {
                                                await saveEmail();
                                            } else {
                                                toastOneWarn(
                                                    "Pentru a schimba emailul, va rugam sa completati campul de confirmare cuvantul: CONFIRM",
                                                );
                                            }
                                        }}>
                                        Salvare
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    },
);
EmailDialog.displayName = "EmailDialog";
EmailDialog.propTypes = {
    email: PropTypes.string.isRequired,
    saveEmail: PropTypes.func.isRequired,
    changeEmail: PropTypes.func.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
    emailChangeErrors: PropTypes.object.isRequired,
    closeEmailChangeDialog: PropTypes.func.isRequired,
    isEmailChangeDialogOpened: PropTypes.bool.isRequired,
};

export default EmailDialog;
