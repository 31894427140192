import BooleanInputBox from "../../components/forms/final-input-boxes/boolean-input-box.jsx";
import TextInputBox from "../../components/forms/final-input-boxes/text-input-box.jsx";
import useSendNotification from "../../hooks/communication/use-send-notification.js";
import MarkdownEditor from "../../components/forms/raw-inputs/markdown-editor.jsx";
import { checkAnyErrorForPath, cn, retrieveErrorForPath } from "../../utils.js";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import useTextAreaResize from "../../hooks/use-text-area-resize.js";
import FieldWrap from "../../components/forms/wraps/field-wrap.jsx";
import InputWrap from "../../components/forms/wraps/input-wrap.jsx";
import ErrorSpan from "../../components/layout/error-span.jsx";
import { Button } from "../../components/schadcn-ui/button.jsx";
import { Fragment, useEffect } from "react";

export default function SendNotification() {
    const { ref, resizeTextarea } = useTextAreaResize();
    const {
        renderBulkNotification,
        sendNotification,
        cancelNotification,
        handleChange,
        errors,
        notification,
        receivers,
        handleReceiversChange,
    } = useSendNotification();

    useEffect(() => {
        renderBulkNotification().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        resizeTextarea(ref);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification.subject]);

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap>
                    {Object.keys(notification).length > 0 && (
                        <Fragment>
                            <BooleanInputBox
                                name={"sendToTeachers"}
                                value={!!receivers["sendToTeachers"]}
                                handleChange={handleReceiversChange}
                                label={"Notificare destinata profesorilor?"}
                            />
                            <BooleanInputBox
                                name={"sendToPupils"}
                                value={!!receivers["sendToPupils"]}
                                handleChange={handleReceiversChange}
                                label={"Notificare destinata elevilor?"}
                            />
                            <TextInputBox
                                handleChange={handleChange}
                                name={"subject"}
                                label={"Subiectul Notificarii (Obligatoriu)"}
                                value={notification.subject || ""}
                                errors={errors}
                            />
                            <FieldWrap>
                                <InputWrap
                                    customStyles={cn(
                                        checkAnyErrorForPath(`content`, errors) ? "border-red-600" : "",
                                        "px-4 pb-4",
                                    )}>
                                    <span className={"w-full text-center font-bold text-gray-400"}>
                                        Continutul Notificarii (Obligatoriu)
                                    </span>
                                    <div className={"markdown max-h-96 overflow-y-scroll pr-4"}>
                                        <MarkdownEditor
                                            name={"content"}
                                            changeContent={(text) =>
                                                handleChange({
                                                    target: {
                                                        name: "content",
                                                        value: text,
                                                    },
                                                })
                                            }
                                            content={notification.content || ""}
                                            componentKey={"content"}
                                        />
                                    </div>
                                    <ErrorSpan
                                        text={retrieveErrorForPath(`content`, errors)}
                                        customStyles={cn(checkAnyErrorForPath(`content`, errors) ? "flex" : "hidden")}
                                    />
                                </InputWrap>
                            </FieldWrap>

                            <FieldWrap>
                                <div
                                    className={
                                        "flex w-full flex-col space-y-4 sm:flex-row sm:justify-end sm:space-x-4 sm:space-y-0"
                                    }>
                                    <Button variant="destructive" size={"lg"} onClick={() => cancelNotification()}>
                                        Anulare mesaj
                                    </Button>
                                    <Button size={"lg"} onClick={() => sendNotification()}>
                                        Trimitere mesaj
                                    </Button>
                                </div>
                            </FieldWrap>
                        </Fragment>
                    )}
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    );
}
