import { forwardRef } from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import PropTypes from "prop-types";
import { cn } from "../../utils.js";

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverAnchor = PopoverPrimitive.Anchor;

const PopoverContent = forwardRef(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
    <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
            ref={ref}
            align={align}
            sideOffset={sideOffset}
            className={cn("z-40 w-72 rounded-md border border-dark bg-light p-4 shadow-md outline-none", className)}
            {...props}
        />
    </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;
PopoverContent.propTypes = {
    className: PropTypes.string,
    align: PropTypes.string,
    sideOffset: PropTypes.number,
};

export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor };
