import { useFetchProvider } from "../../providers/provider-hooks.jsx";
import { updateFailureToast } from "../../toast.js";
import { Slide, toast } from "react-toastify";
import FileDownload from "js-file-download";
import { delay } from "../../utils.js";

export const APPLICATION_REPORTS = Object.freeze({
    ORDERS: "/api/waste-collection-forms/report",
    NOTIFICATIONS: "/api/notifications/report",
    ACTIVITY: "/api/lessons/answers/report",
    SCHOOLS: "/api/schools/report",
    FINAL: "/api/schools/status-report",
    ACCOUNT_AUTHORIZATION: "/api/users/school-requests/report",
});

export function useTableExports(table, params, setActionsDisabled) {
    const { getExcelReport } = useFetchProvider();

    async function exportTable() {
        setActionsDisabled(true);
        const standardError = "Am intampinat o problema. Incercati sa regenerati tabelul mai tarziu.";
        const toastId = toast.loading("Raportul Excel se genereaza. Va rugam sa asteptati.", { transition: Slide });
        try {
            await delay(1000);

            const filteredParams = Object.entries(params)
                .filter(([key, value]) => key !== "page" && value !== undefined && value !== null && value !== "")
                .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                .join("&");

            const path = `${table}${filteredParams.length ? `?${filteredParams}` : ""}`;

            console.log("Table:", table);
            console.log("Params:", params);
            console.log("Generated Path:", path);

            const responseFile = await getExcelReport(path);
            console.log(responseFile);
            if (responseFile && responseFile.data) {
                const contentDisposition = responseFile.headers.get("Content-Disposition");
                const fileName = contentDisposition.split("filename=")[1].replaceAll(`"`, "");

                if (responseFile.data.size) {
                    FileDownload(responseFile.data, fileName, responseFile.data.type);
                } else return updateFailureToast("Fisierul generat este gol.", toastId);
            } else return updateFailureToast("Fisierul generat este corupt.", toastId);
            toast.dismiss(toastId);
        } catch (error) {
            console.log(error);
            if (error.message) return updateFailureToast(error.message, toastId);
            updateFailureToast(standardError, toastId);
        } finally {
            setActionsDisabled(false);
        }
    }

    return { exportTable };
}
