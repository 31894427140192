import { delay, findChangedPath, trimStringsFromAnywhere } from "../../utils.js";
import { APPLICATION_REPORTS, useTableExports } from "./use-table-exports.js";
import { useFetchProvider } from "../../providers/provider-hooks.jsx";
import useTableParams from "./use-table-params.js";
import { useEffect, useRef, useState } from "react";
import { updateFailureToast } from "../../toast.js";
import { useEditions } from "../use-editions.js";
import { Slide, toast } from "react-toastify";
import _ from "lodash";

export default function useSchoolsReport() {
    const [isFormCorrectionDialogOpened, setIsFormCorrectionDialogOpened] = useState(false);
    const [correctionFormErrors, setCorrectionFormErrors] = useState({});
    const [actionsDisabled, setActionsDisabled] = useState(false);
    const [oldDialogFormData, setOldDialogFormData] = useState({});
    const [dialogFormData, setDialogFormData] = useState({});
    const [tableData, setTableData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [counties, setCounties] = useState([]);

    const printRef = useRef(null);
    const editableFormDialogRef = useRef(null);

    const { params, changeParams, setParams } = useTableParams(renderSchoolReport, { page: 0 });
    const { exportTable } = useTableExports(APPLICATION_REPORTS.SCHOOLS, params, setActionsDisabled);
    const { editions, currentEdition, loadEditions } = useEditions(params, setParams);
    const { getSchoolsReport, getSchoolById, patchSchool, getCounties } = useFetchProvider();

    function handleSimpleCorrectionFieldChange(e) {
        const { name, value } = e.target;
        const updatedData = { ...dialogFormData };
        updatedData[name] = value;
        setDialogFormData(updatedData);
    }

    function handleComplexCorrectionFieldChange(e) {
        const { name, value } = e.target;
        const updatedData = { ...dialogFormData };
        updatedData[name.split(".")[0]][name.split(".")[1]] = value;
        setDialogFormData(updatedData);
    }

    function closeFormDialog() {
        setIsFormCorrectionDialogOpened(false);
        setOldDialogFormData({});
        setDialogFormData({});
    }

    async function openDialogForm(id) {
        setActionsDisabled(true);
        let errorMessage = "Formularul nu s-a putut deschide.";
        const toastId = toast.loading("Incarcam formularul scolii...", { transition: Slide });

        try {
            await delay(1000);
            const dialogData = await getSchoolById(id);
            setDialogFormData(dialogData);
            setOldDialogFormData(dialogData);
            setIsFormCorrectionDialogOpened(true);
            toast.dismiss(toastId);
        } catch (error) {
            updateFailureToast(error.message || errorMessage, toastId);
        } finally {
            setActionsDisabled(false);
        }
    }

    async function saveFormCorrections() {
        setActionsDisabled(true);
        let errorMessage = "Formularul nu s-a putut salva.";
        const toastId = toast.loading("Salvam corectarile aplicate formularului...", { transition: Slide });
        try {
            await delay(1000);
            const trimmedData = trimStringsFromAnywhere(dialogFormData);
            await patchSchool(trimmedData);
            toast.dismiss(toastId);
            closeFormDialog();
            await renderSchoolReport(params);
        } catch (error) {
            console.error(error);
            if (error.message || error.errors) {
                if (error.errors && Object.keys(error.errors).length > 0) setCorrectionFormErrors(error.errors);
                if (error.message) updateFailureToast(error.message, toastId);
                return;
            }
            updateFailureToast(errorMessage, toastId);
        } finally {
            setActionsDisabled(false);
        }
    }

    async function renderSchoolReport(freshParams) {
        setActionsDisabled(true);
        let errorMessage = "Tabelul nu s-a putut incarca";
        const id = toast.loading("Tabelul se incarca...", { transition: Slide });

        try {
            await delay(1000);
            const data = await getSchoolsReport(freshParams || params);
            setTableData(data.content);
            setPagination({
                totalPages: data.totalPages,
                totalElements: data.totalElements,
                ...data["pageable"],
                first: data.first,
                last: data.last,
            });
            toast.dismiss(id);
        } catch (error) {
            console.error(error);
            updateFailureToast(error.message || errorMessage, id);
        } finally {
            setActionsDisabled(false);
        }
    }

    async function resetFilters() {
        setParams({ page: params.page, editionId: params.editionId });
        await renderSchoolReport({ page: params.page, editionId: params.editionId });
    }

    async function populateCounties() {
        try {
            const newCounties = await getCounties();
            if (newCounties.length > 0) {
                setCounties(newCounties.map((county) => ({ value: county })));
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        populateCounties().then(() =>
            loadEditions().then((res) => renderSchoolReport(res ? { ...params, ...res } : { ...params }).then()),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const changedPath = findChangedPath(dialogFormData, oldDialogFormData);
        if (changedPath) {
            const errorPath = Object.keys(correctionFormErrors).find((errorKey) => errorKey === changedPath);
            if (errorPath) {
                const newErrors = JSON.parse(JSON.stringify(correctionFormErrors));
                delete newErrors[errorPath];
                setCorrectionFormErrors(newErrors);
            }
            setOldDialogFormData(_.cloneDeep(dialogFormData));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogFormData, correctionFormErrors]);

    return {
        saveFormCorrections,
        openDialogForm,
        counties,
        handleSimpleCorrectionFieldChange,
        dialogFormData,
        tableData,
        printRef,
        actionsDisabled,
        resetFilters,
        isFormCorrectionDialogOpened,
        editableFormDialogRef,
        params,
        changeParams,
        renderSchoolReport,
        pagination,
        currentEdition,
        editions,
        exportTable,
        closeFormDialog,
        handleComplexCorrectionFieldChange,
        correctionFormErrors,
    };
}
