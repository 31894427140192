import { useContext } from "react";
import { FetchProviderContext } from "./fetch-provider.jsx";
import { AuthProviderContext } from "./auth-provider.jsx";
import { RoutesProviderContext } from "./routes-provider.jsx";
import { InboxProviderContext } from "./inbox-provider.jsx";

export const useFetchProvider = () => {
    const context = useContext(FetchProviderContext);
    if (!context) throw new Error("Error: useFetchProvider must be used within the FetchProvider.");
    return context;
};

export const useAuthProvider = () => {
    const context = useContext(AuthProviderContext);
    if (!context) throw new Error("useAuthProvider must be used within the AuthProvider");
    return context;
};

export const useRoutesProvider = () => {
    const context = useContext(RoutesProviderContext);
    if (!context) throw new Error("useRoutesProvider must be used within the RoutesProvider");
    return context;
};

export const useInboxProvider = () => {
    const context = useContext(InboxProviderContext);
    if (!context) throw new Error("useInboxProvider must be used within the InboxProvider");
    return context;
};
