import PropTypes from "prop-types";
import { cn } from "../../../utils.js";

export default function HeadCell({ customStyles, colSpan, children }) {
    return (
        <th className={cn("min-w-[150px] px-4 pb-2 text-left align-bottom", customStyles)} colSpan={colSpan}>
            {children}
        </th>
    );
}
HeadCell.propTypes = {
    customStyles: PropTypes.string,
    colSpan: PropTypes.number.isRequired,
    children: PropTypes.node,
};
