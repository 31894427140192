import PropTypes from "prop-types";
import { cn } from "../../../utils.js";

export default function BodyRow({ customStyles, children }) {
    return <tr className={cn("border-t border-dark/40 transition-colors", customStyles)}>{children}</tr>;
}
BodyRow.propTypes = {
    customStyles: PropTypes.string,
    children: PropTypes.node,
};
