import { useAuthProvider, useRoutesProvider } from "../../../providers/provider-hooks.jsx";
import { Button } from "../../schadcn-ui/button.jsx";
import { FaRotate } from "react-icons/fa6";
import PropTypes from "prop-types";

export default function ListingActions({ refresh, newQuizzButtonName, newQuizAction }) {
    const { authState } = useAuthProvider();
    const { ROLES } = useRoutesProvider();

    return (
        <div
            className={
                "flex w-full flex-col items-center space-y-4 sm:flex-row sm:justify-end sm:space-x-4 sm:space-y-0"
            }>
            <Button className={"w-full sm:w-auto"} onClick={refresh}>
                <FaRotate className={"mr-2"} />
                <span>Reincarcare</span>
            </Button>
            {[ROLES.DBEST, ROLES.ADMIN].includes(authState.userInfo.role) && (
                <Button className={"w-full sm:w-auto"} onClick={newQuizAction}>
                    <span>{newQuizzButtonName}</span>
                </Button>
            )}
        </div>
    );
}
ListingActions.propTypes = {
    refresh: PropTypes.func.isRequired,
    newQuizAction: PropTypes.func.isRequired,
    newQuizzButtonName: PropTypes.string.isRequired,
};
