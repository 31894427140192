import useTextAreaResize from "../../../../hooks/use-text-area-resize.js";
import TextareaInput from "../../../forms/raw-inputs/textarea-input.jsx";
import AnswerChoices from "./question-slide/answer-choices.jsx";
import FieldWrap from "../../../forms/wraps/field-wrap.jsx";
import InputWrap from "../../../forms/wraps/input-wrap.jsx";
import ImageUpload from "./question-slide/image-upload.jsx";
import ErrorSpan from "../../../layout/error-span.jsx";
import { Button } from "../../../schadcn-ui/button.jsx";
import PreviewTextBox from "../preview-text-box.jsx";
import { useEffect, useMemo } from "react";
import QuizWrap from "../quiz-wrap.jsx";
import { cn } from "../../../../utils.js";
import PropTypes from "prop-types";

export default function QuestionSlide({
    formData,
    selectedSlide,
    isInPreview,
    checkAnyErrorForPath,
    changeSlideTitle,
    removeOptionsFromSlide,
    changeOptionText,
    retrieveErrorForPath,
    changeSlideImage,
    addOptionsToSlide,
    changeOptionImage,
    removeOptionImage,
    markCorrectOption,
}) {
    const slide = useMemo(() => formData.slides.find((slide) => slide.id === selectedSlide), [selectedSlide, formData]);
    const slideIdx = useMemo(
        () => formData.slides.findIndex((slide) => slide.id === selectedSlide),
        [selectedSlide, formData],
    );
    const { ref, resizeTextarea } = useTextAreaResize();

    useEffect(() => {
        resizeTextarea(ref);
    }, [slide.text]);

    return (
        <QuizWrap>
            <FieldWrap>
                <InputWrap
                    customStyles={cn(
                        checkAnyErrorForPath(`slides[${slideIdx}].text`) ? "border-red-600 pr-2" : "pr-2",
                    )}>
                    {!isInPreview && (
                        <TextareaInput
                            ref={ref}
                            value={slide.text || ""}
                            name={`text-${selectedSlide}`}
                            label={"Textul intrebarii (Obligatoriu)"}
                            customStyles={slide.text ? "font-black" : "sr-only"}
                            handleChange={(e) => changeSlideTitle(e.target.value)}
                        />
                    )}
                    {isInPreview && <PreviewTextBox text={slide.text} />}
                    <ErrorSpan
                        text={retrieveErrorForPath(`slides[${slideIdx}].text`)}
                        customStyles={cn(checkAnyErrorForPath(`slides[${slideIdx}].text`) ? "flex" : "hidden")}
                    />
                </InputWrap>
            </FieldWrap>

            <FieldWrap customStyles={"animate-fade"}>
                <div className="flex h-full flex-1 items-center justify-center">
                    <InputWrap
                        customStyles={cn(
                            checkAnyErrorForPath(`slides[${slideIdx}].image`) ? "border-red-600" : "",
                            "bg-white max-w-[400px] max-h-[400px] flex items-center justify-center h-full",
                            "px-0",
                        )}>
                        {!isInPreview && (
                            <ImageUpload
                                slide={slide}
                                slideIdx={slideIdx}
                                changeSlideImage={changeSlideImage}
                                checkAnyErrorForPath={checkAnyErrorForPath}
                            />
                        )}
                        {isInPreview && slide.image && (
                            <img
                                src={slide.image}
                                alt="Imagine incarcata"
                                className={cn(
                                    checkAnyErrorForPath(`slides[${slideIdx}].image`)
                                        ? "rounded-t-[8.1px]"
                                        : "rounded-[8.1px]",
                                    "flex aspect-[16/9] object-contain",
                                )}
                            />
                        )}
                        <ErrorSpan
                            text={retrieveErrorForPath(`slides[${slideIdx}].image`)}
                            customStyles={cn(checkAnyErrorForPath(`slides[${slideIdx}].image`) ? "flex" : "hidden")}
                        />
                    </InputWrap>
                </div>
            </FieldWrap>

            <FieldWrap>
                <AnswerChoices
                    slideIdx={slideIdx}
                    options={slide.options}
                    isInPreview={isInPreview}
                    selectedSlide={selectedSlide}
                    changeOptionText={changeOptionText}
                    changeOptionImage={changeOptionImage}
                    removeOptionImage={removeOptionImage}
                    markCorrectOption={markCorrectOption}
                    retrieveErrorForPath={retrieveErrorForPath}
                    checkAnyErrorForPath={checkAnyErrorForPath}
                />
            </FieldWrap>

            {!isInPreview && (
                <FieldWrap customStyles={"flex flex-col items-center gap-4"}>
                    {slide.options.length > 2 && (
                        <Button onClick={() => removeOptionsFromSlide(slide.id)}>Elimina ultima varianta</Button>
                    )}
                    <Button onClick={() => addOptionsToSlide(slide.id)}>Adauga o variante</Button>
                </FieldWrap>
            )}
        </QuizWrap>
    );
}

QuestionSlide.propTypes = {
    formData: PropTypes.object.isRequired,
    isInPreview: PropTypes.bool.isRequired,
    changeSlideImage: PropTypes.func.isRequired,
    changeSlideTitle: PropTypes.func.isRequired,
    changeOptionText: PropTypes.func.isRequired,
    addOptionsToSlide: PropTypes.func.isRequired,
    changeOptionImage: PropTypes.func.isRequired,
    removeOptionImage: PropTypes.func.isRequired,
    markCorrectOption: PropTypes.func.isRequired,
    checkAnyErrorForPath: PropTypes.func.isRequired,
    retrieveErrorForPath: PropTypes.func.isRequired,
    removeOptionsFromSlide: PropTypes.func.isRequired,
    selectedSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};
