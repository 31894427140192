import { Fragment, useEffect, useRef, useState } from "react";
import { Button } from "../../schadcn-ui/button.jsx";
import { FaXmark } from "react-icons/fa6";
import PropTypes from "prop-types";
import { cn } from "../../../utils.js";

export default function DoubleCheckAction({ spanMessage, buttonMessage, buttonStyles, yesAction, actionsDisabled }) {
    const dialogRef = useRef(null);
    const [isDialogVisible, setIsDialogVisible] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) =>
            dialogRef.current && !dialogRef.current.contains(event.target) ? setIsDialogVisible(false) : null;
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Fragment>
            <button disabled={actionsDisabled} onClick={() => setIsDialogVisible(true)} className={cn(buttonStyles)}>
                {buttonMessage}
            </button>
            {isDialogVisible && (
                <div className="fixed left-0 top-0 z-50 flex h-screen w-screen animate-fade cursor-default items-center justify-center bg-black/50 backdrop-blur-sm animate-duration-200">
                    <div
                        ref={dialogRef}
                        className="relative flex max-h-[80vh] min-w-[40vw] max-w-[80vw] flex-col overflow-hidden rounded-lg bg-light p-4 shadow-lg">
                        <div className={"relative flex justify-end pb-4"}>
                            <button disabled={actionsDisabled} onClick={() => setIsDialogVisible(false)}>
                                <FaXmark />
                            </button>
                        </div>
                        <div
                            className={
                                "relative flex h-full max-h-[80vh] w-full min-w-[40vw] max-w-[80vw] select-text flex-col overflow-hidden"
                            }>
                            <span className={"p-2 text-large font-bold"}>{spanMessage}</span>
                            <div className={"flex flex-row justify-between space-x-4 px-2 pt-4"}>
                                <Button
                                    variant={"destructive"}
                                    className={"w-full"}
                                    disabled={actionsDisabled}
                                    onClick={() => setIsDialogVisible(false)}>
                                    NU
                                </Button>
                                <Button
                                    className={"w-full"}
                                    disabled={actionsDisabled}
                                    onClick={async () => {
                                        setIsDialogVisible(false);
                                        await yesAction();
                                    }}>
                                    Da
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
}
DoubleCheckAction.propTypes = {
    spanMessage: PropTypes.string.isRequired,
    buttonMessage: PropTypes.string.isRequired,
    buttonStyles: PropTypes.string,
    yesAction: PropTypes.func.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
};
