import * as SelectPrimitive from "@radix-ui/react-select";
import PropTypes from "prop-types";
import { cn } from "../../utils.js";
import { forwardRef } from "react";
import { CaretSortIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = forwardRef(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Trigger
        ref={ref}
        className={cn(
            "flex h-[40px] w-full items-center justify-between whitespace-nowrap rounded-md border border-dark bg-light px-3 py-2 text-small shadow-sm placeholder:font-bold placeholder:text-dark/80 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1",
            className,
        )}
        {...props}>
        <div className={"flex w-full justify-center text-center"}>{children}</div>
        <SelectPrimitive.Icon asChild>
            <CaretSortIcon className="h-4 w-4 opacity-50" />
        </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;
SelectTrigger.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

const SelectScrollUpButton = forwardRef(({ className, ...props }, ref) => (
    <SelectPrimitive.ScrollUpButton
        ref={ref}
        className={cn("flex cursor-default items-center justify-center py-1", className)}
        {...props}>
        <ChevronUpIcon />
    </SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;
SelectScrollUpButton.propTypes = {
    className: PropTypes.string,
};

const SelectScrollDownButton = forwardRef(({ className, ...props }, ref) => (
    <SelectPrimitive.ScrollDownButton
        ref={ref}
        className={cn("flex cursor-default items-center justify-center py-1", className)}
        {...props}>
        <ChevronDownIcon />
    </SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;
SelectScrollDownButton.propTypes = {
    className: PropTypes.string,
};

const SelectContent = forwardRef(({ className, children, position = "popper", ...props }, ref) => (
    <SelectPrimitive.Portal>
        <SelectPrimitive.Content
            ref={ref}
            className={cn(
                "relative z-40 max-h-96 min-w-[8rem] overflow-hidden rounded-md border border-dark bg-light shadow-md",
                position === "popper" &&
                    "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
                className,
            )}
            position={position}
            {...props}>
            <SelectScrollUpButton />
            <SelectPrimitive.Viewport
                className={cn(
                    "p-1",
                    position === "popper" &&
                        "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]",
                )}>
                {children}
            </SelectPrimitive.Viewport>
            <SelectScrollDownButton />
        </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;
SelectContent.propTypes = {
    className: PropTypes.string,
    position: PropTypes.string,
    children: PropTypes.node,
};

const SelectLabel = forwardRef(({ className, ...props }, ref) => (
    <SelectPrimitive.Label ref={ref} className={cn("text-center text-[16px] font-bold", className)} {...props} />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;
SelectLabel.propTypes = {
    className: PropTypes.string,
};

const SelectItem = forwardRef(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Item
        ref={ref}
        className={cn(
            "relative flex h-auto w-full max-w-[80vw] cursor-pointer select-none items-center text-wrap rounded-sm py-1.5 pl-2 pr-8 text-small outline-none focus:bg-lilac/30",
            className,
        )}
        {...props}>
        <span className="absolute right-2 flex h-3.5 w-3.5 items-center justify-center">
            <SelectPrimitive.ItemIndicator>
                <CheckIcon className="h-4 w-4" />
            </SelectPrimitive.ItemIndicator>
        </span>
        <SelectPrimitive.ItemText className={"h-auto text-wrap"}>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;
SelectItem.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

const SelectSeparator = forwardRef(({ className, ...props }, ref) => (
    <SelectPrimitive.Separator ref={ref} className={cn("-mx-1 my-1 h-px bg-dark/30", className)} {...props} />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;
SelectSeparator.propTypes = {
    className: PropTypes.string,
};

export {
    Select,
    SelectGroup,
    SelectValue,
    SelectTrigger,
    SelectContent,
    SelectLabel,
    SelectItem,
    SelectSeparator,
    SelectScrollUpButton,
    SelectScrollDownButton,
};
