import FiltersResetButton from "../../components/reports/tables/filters-reset-button.jsx";
import { useBigSchoolsReport } from "../../hooks/reports/use-big-schools-report.js";
import TableHeaders from "../../components/reports/big-schools/table-headers.jsx";
import ReportDetails from "../../components/reports/tables/report-details.jsx";
import TableBody from "../../components/reports/big-schools/table-body.jsx";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import Pagination from "../../components/reports/tables/pagination.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import { Button } from "../../components/schadcn-ui/button.jsx";
import { useNavigate } from "react-router-dom";
import SizeSelector from "../../components/reports/tables/size-selector.jsx";

export default function BigSchools() {
    const { tableData, printRef, resetFilters, pagination, actionsDisabled, params, changeParams } =
        useBigSchoolsReport();

    const navigate = useNavigate();

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap withoutNav={true} withoutSideMenu={true}>
                <BgContentWrap customStyles={"pr-0 rounded-none overflow-hidden"}>
                    <div
                        className={
                            "flex w-full flex-col space-y-4 pb-4 lg:flex-row lg:items-end lg:justify-end lg:space-x-4 lg:space-y-0"
                        }>
                        <ReportDetails pagination={pagination} />
                        <SizeSelector actionsDisabled={actionsDisabled} changeParams={changeParams} params={params} />
                        <FiltersResetButton handleRefresh={resetFilters} disabled={actionsDisabled} />
                        <Button
                            onClick={() => navigate("/")}
                            disabled={actionsDisabled}
                            className={"h-auto w-full sm:w-auto md:h-[36px]"}>
                            <span className={"text-wrap pl-2 md:text-nowrap"}>
                                Apasati aici pentru a redeschide formularul scolii dvs.
                            </span>
                        </Button>
                    </div>
                    <div className="relative flex-1 overflow-auto pr-4">
                        <div className={"absolute left-0 top-0 w-full"}>
                            <table ref={printRef} className="relative h-full w-full">
                                <TableHeaders
                                    actionsDisabled={actionsDisabled}
                                    params={params}
                                    changeParams={changeParams}
                                />
                                <TableBody tableData={tableData} />
                            </table>
                        </div>
                    </div>
                    <Pagination
                        pagination={pagination}
                        changeParams={changeParams}
                        actionsDisabled={actionsDisabled}
                        tableData={tableData}
                    />
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    );
}
