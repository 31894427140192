import { Fragment } from "react";
import { Button } from "../../schadcn-ui/button.jsx";
import { FaAngleLeft, FaAngleRight, FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";
import PropTypes from "prop-types";

export default function Pagination({ tableData, pagination, changeParams, actionsDisabled }) {
    return (
        <div className={"flex min-h-[52px] w-full items-center justify-center space-x-4 pt-4"}>
            {Array.isArray(tableData) && tableData.length > 0 && (
                <Fragment>
                    <Button
                        variant={"ghost"}
                        disabled={pagination.first || actionsDisabled}
                        onClick={() => changeParams({ target: { name: "page", value: 0 } }, "pagination", true)}>
                        <FaAnglesLeft />
                    </Button>
                    <Button
                        variant={"ghost"}
                        disabled={pagination.first || actionsDisabled}
                        onClick={() =>
                            changeParams(
                                {
                                    target: {
                                        name: "page",
                                        value: pagination["pageNumber"] - 1,
                                    },
                                },
                                "pagination",
                                true,
                            )
                        }>
                        <FaAngleLeft />
                    </Button>
                    <span>Pagina {Object.keys(pagination).length > 0 ? pagination["pageNumber"] * 1 + 1 : ""}</span>
                    <Button
                        variant={"ghost"}
                        disabled={pagination.last || actionsDisabled}
                        onClick={() =>
                            changeParams(
                                {
                                    target: {
                                        name: "page",
                                        value: pagination["pageNumber"] + 1,
                                    },
                                },
                                "pagination",
                                true,
                            )
                        }>
                        <FaAngleRight />
                    </Button>
                    <Button
                        variant={"ghost"}
                        disabled={pagination.last || actionsDisabled}
                        onClick={() =>
                            changeParams(
                                {
                                    target: {
                                        name: "page",
                                        value: pagination.totalPages - 1,
                                    },
                                },
                                "pagination",
                                true,
                            )
                        }>
                        <FaAnglesRight />
                    </Button>
                </Fragment>
            )}
        </div>
    );
}
Pagination.propTypes = {
    tableData: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    changeParams: PropTypes.func.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
};
