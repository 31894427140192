import { useState } from "react";
import { useFetchProvider } from "../providers/provider-hooks.jsx";

export function useEditions(params, setParams) {
    const [currentEdition, setCurrentEdition] = useState(null);
    const [editions, setEditions] = useState([]);
    const { getEditions } = useFetchProvider();

    async function loadEditions() {
        try {
            const newEditions = await getEditions();
            if (Array.isArray(newEditions) && newEditions.length > 0) {
                setEditions(newEditions);
                const newCurrentEdition = newEditions.find((edition) => edition["isCurrentEdition"] === true);
                console.log(newCurrentEdition);
                if (newCurrentEdition) {
                    setCurrentEdition(newCurrentEdition);
                    setParams({ ...params, editionId: newCurrentEdition["id"] });
                    return { editionId: newCurrentEdition["id"] };
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    return { editions, currentEdition, loadEditions };
}
