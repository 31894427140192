import PropTypes from "prop-types";
import { cn } from "../../../utils.js";

export default function PreviewTextBox({ text, customStyles }) {
    return (
        <div
            className={cn(
                "flex h-full min-h-[50px] w-full items-center justify-center p-2 text-xl font-bold text-gray-600",
                customStyles,
            )}>
            <span className={"markdown text-center"}>{text || ""}</span>
        </div>
    );
}
PreviewTextBox.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    customStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};
