import { APPLICATION_REPORTS, useTableExports } from "./use-table-exports.js";
import { useFetchProvider } from "../../providers/provider-hooks.jsx";
import useTableParams from "./use-table-params.js";
import { updateFailureToast } from "../../toast.js";
import { useEffect, useRef, useState } from "react";
import { Slide, toast } from "react-toastify";
import { delay } from "../../utils.js";
import { useEditions } from "../use-editions.js";

export function useFinalReport() {
    const [actionsDisabled, setActionsDisabled] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [pagination, setPagination] = useState({});

    const { params, changeParams, setParams } = useTableParams(renderFinalReport, { page: 0 });
    const { editions, currentEdition, loadEditions } = useEditions(params, setParams);
    const { exportTable } = useTableExports(APPLICATION_REPORTS.FINAL, params, setActionsDisabled);
    const printRef = useRef(null);
    const { getFinalReport } = useFetchProvider();

    async function renderFinalReport(freshParams) {
        setActionsDisabled(true);
        let errorMessage = "Tabelul nu s-a putut incarca";
        const id = toast.loading("Tabelul se incarca...", { transition: Slide });

        try {
            await delay(1000);
            const data = await getFinalReport(freshParams || params);
            setTableData(data.content);
            setPagination({
                totalPages: data.totalPages,
                totalElements: data.totalElements,
                ...data["pageable"],
                first: data.first,
                last: data.last,
            });
            toast.dismiss(id);
        } catch (error) {
            console.error(error);
            updateFailureToast(error.message || errorMessage, id);
        } finally {
            setActionsDisabled(false);
        }
    }

    async function resetFilters() {
        setParams({ page: params.page, editionId: params.editionId });
        await renderFinalReport({ page: params.page, editionId: params.editionId });
    }

    useEffect(() => {
        loadEditions().then((res) => renderFinalReport(res ? { ...params, ...res } : { ...params }).then());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        params,
        changeParams,
        resetFilters,
        exportTable,
        tableData,
        printRef,
        actionsDisabled,
        renderFinalReport,
        pagination,
        currentEdition,
        editions,
    };
}
