import PropTypes from "prop-types";
import { cn } from "../../utils.js";

export default function BasePageWrap({ customStyles, children }) {
    return (
        <div
            className={cn(
                "relative flex max-h-screen min-h-screen min-w-screen max-w-screen flex-col bg-light font-roboto-mono text-dark",
                customStyles,
            )}>
            {children}
        </div>
    );
}
BasePageWrap.propTypes = {
    customStyles: PropTypes.string,
    children: PropTypes.node.isRequired,
};
