import useNotificationsSent from "../../hooks/communication/use-notifications-sent.js";
import MarkdownPreview from "../../components/forms/raw-inputs/preview-markdown.jsx";
import PreviewTextBox from "../../components/quizzes/creation/preview-text-box.jsx";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import Pagination from "../../components/reports/tables/pagination.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import { Button } from "../../components/schadcn-ui/button.jsx";
import { cn, formatUnixTimestamp } from "../../utils.js";
import { FaXmark } from "react-icons/fa6";

export default function NotificationsSent() {
    const {
        setOpenedNotification,
        openDetailedNotificationStatus,
        openedNotification,
        pagination,
        changeParams,
        setNotificationDetails,
        notificationDetails,
        openedNotificationRef,
        resendNotification,
        sentNotifications,
        readNotification,
        actionsDisabled,
    } = useNotificationsSent();

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap>
                    <div className={"flex flex-col"}>
                        <div>
                            <PreviewTextBox text={`Notificari trimise`} />
                        </div>
                        <div className="space-y-4 overflow-x-hidden p-2">
                            {Array.isArray(sentNotifications) &&
                                sentNotifications.length > 0 &&
                                sentNotifications.map((notification, notificationIdx) => (
                                    <div
                                        key={notification["id"].toString()}
                                        style={{ animationDelay: `${notificationIdx * 100}ms` }}
                                        className={cn(
                                            notification["status"].split("/")[0] === "0"
                                                ? "border-red-500"
                                                : notification["status"].split("/")[0] !==
                                                    notification["status"].split("/")[1]
                                                  ? "border-orange-500"
                                                  : "border-green-500",
                                            "flex animate-fade flex-col rounded-lg border-2 bg-white p-4 shadow-md",
                                        )}>
                                        <h2 className="text-xl font-semibold">{notification["subject"]}</h2>
                                        <span className="mt-2 text-sm text-gray-500">
                                            Data trimitere: {formatUnixTimestamp(notification["sendAt"].toString())}
                                        </span>
                                        <span
                                            className={cn(
                                                notification["status"].split("/")[0] === "0"
                                                    ? "text-red-500"
                                                    : notification["status"].split("/")[0] !==
                                                        notification["status"].split("/")[1]
                                                      ? "text-orange-500"
                                                      : "text-green-500",
                                                "mt-2 text-sm",
                                            )}>
                                            Status: {notification["status"]} persoane au citit notificarea
                                        </span>
                                        <div className={"flex w-full flex-col items-end sm:flex-row sm:justify-end"}>
                                            {notification["status"] &&
                                                notification["status"].split("/")[0] !==
                                                    notification["status"].split("/")[1] && (
                                                    <Button
                                                        variant={"link"}
                                                        onClick={() => resendNotification(notification["id"])}>
                                                        Trimitere Reminder
                                                    </Button>
                                                )}
                                            <div className={"flex"}>
                                                <Button
                                                    variant={"link"}
                                                    onClick={() => openDetailedNotificationStatus(notification["id"])}>
                                                    Detalii
                                                </Button>
                                                <Button
                                                    variant={"link"}
                                                    onClick={() => readNotification(notification["id"])}>
                                                    Deschide
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>

                    <Pagination
                        pagination={pagination}
                        changeParams={changeParams}
                        actionsDisabled={actionsDisabled}
                        tableData={sentNotifications}
                    />

                    {openedNotification && (
                        <div className="fixed left-0 top-0 z-50 flex h-screen w-screen animate-fade cursor-default items-center justify-center bg-black/50 backdrop-blur-sm animate-duration-200">
                            <div
                                ref={openedNotificationRef}
                                className="relative flex max-h-[80vh] min-w-[40vw] max-w-[80vw] flex-col overflow-y-hidden rounded-lg bg-white p-4 shadow-lg">
                                <div className={"relative flex justify-end pb-4"}>
                                    <button onClick={() => setOpenedNotification("")} className="">
                                        <FaXmark />
                                    </button>
                                </div>
                                <div
                                    className={
                                        "relative flex h-full max-h-[80vh] w-full min-w-[40vw] max-w-[80vw] select-text overflow-y-scroll pr-4"
                                    }>
                                    <MarkdownPreview text={openedNotification} />
                                </div>
                            </div>
                        </div>
                    )}
                    {Array.isArray(notificationDetails) && notificationDetails.length > 0 && (
                        <div className="fixed left-0 top-0 z-50 flex h-screen w-screen animate-fade cursor-default items-center justify-center bg-black/50 backdrop-blur-sm animate-duration-200">
                            <div
                                ref={openedNotificationRef}
                                className="relative flex max-h-[80vh] min-w-[40vw] max-w-[80vw] flex-col overflow-y-hidden rounded-lg bg-white p-4 shadow-lg">
                                <div className={"relative flex justify-end pb-4"}>
                                    <button onClick={() => setNotificationDetails([])} className="">
                                        <FaXmark />
                                    </button>
                                </div>
                                <div className={"relative flex flex-col space-y-4 overflow-y-scroll pr-4"}>
                                    {notificationDetails.map((detail, idx) => (
                                        <div
                                            key={idx}
                                            className={cn(
                                                !detail["isRead"] ? "bg-red-300" : "bg-green-300",
                                                "rounded-lg p-4 shadow-md",
                                            )}>
                                            <h3 className={"text-md"}>
                                                SE Destinatar:{" "}
                                                <span className={"font-semibold"}>{detail["codSe"] || ""}</span>
                                            </h3>
                                            <h4 className={"text-md text-gray-600"}>
                                                Institutie invatamant:{" "}
                                                <span className={"font-semibold"}>
                                                    {detail["numeInstitutieInvatamant"] || ""}
                                                </span>
                                            </h4>
                                            {detail["receiverNume"] && (
                                                <h4 className={"text-md text-gray-600"}>
                                                    Denumire destinatar:{" "}
                                                    <span className={"font-semibold"}>
                                                        {detail["receiverNume"] || ""}
                                                    </span>
                                                </h4>
                                            )}
                                            <h4 className={"text-md text-gray-600"}>
                                                Tip destinatar:{" "}
                                                <span className={"font-semibold"}>{detail["receiverRole"] || ""}</span>
                                            </h4>

                                            <p className="text-md text-gray-600">
                                                Status Mesaj:{" "}
                                                <span className={"font-semibold"}>
                                                    {detail["isRead"] ? "CITIT" : "NECITIT"}
                                                </span>
                                            </p>
                                            <p className="text-md text-gray-600">
                                                Citit la data:{" "}
                                                <span className={"font-semibold"}>
                                                    {detail["readAt"]
                                                        ? formatUnixTimestamp(detail["readAt"].toString())
                                                        : "N/a"}
                                                </span>
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    );
}
