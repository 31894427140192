import PropTypes from "prop-types";
import { cn } from "../../../utils.js";

export default function BooleanFilterInput({ disabled, name, value, handleChange, type = "boolean" }) {
    return (
        <div className={"relative flex flex-col"}>
            <div className={"flex flex-row flex-nowrap justify-start space-x-2 pb-1"}>
                <button
                    disabled={disabled}
                    onClick={() =>
                        handleChange({ target: { name: name, value: value === "true" ? null : "true" } }, type, true)
                    }
                    className={cn(
                        value === "true" ? "font-semibold ring-2 ring-dark" : "font-normal",
                        "rounded-full px-2 focus:outline-none disabled:cursor-not-allowed",
                    )}>
                    DA
                </button>
                <span>/</span>
                <button
                    disabled={disabled}
                    onClick={() =>
                        handleChange({ target: { name: name, value: value === "false" ? null : "false" } }, type, true)
                    }
                    className={cn(
                        value === "false" ? "font-semibold ring-2 ring-dark" : "font-normal",
                        "rounded-full px-2 focus:outline-none disabled:cursor-not-allowed",
                    )}>
                    NU
                </button>
            </div>
            <div className={"h-[1px] w-full rounded-full bg-dark"} />
        </div>
    );
}
BooleanFilterInput.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
};
